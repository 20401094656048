import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-work-cls-renderer',
  template: `&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-slide-toggle
                  class="example-margin"
                  [color]="color"
                  [checked]="checked"
                  [disabled]="disabled">
              </mat-slide-toggle>
          `,
    styleUrls: ['./common.component.css']
})
export class SlideToggleRendererComponent {

  private params: any;
  private imageSource: string;
  private value: any;
  dropDownTarget: string;

  agInit(params: any): void {
      this.params = params;  
      //this.value  = params.value;      
      this.value = this.getClsName(params.value);      
  }

  refresh(params: any): boolean {
    //this.value = params.value;
    this.value = this.getClsName(params.value);
    console.log(`this.value: ${this.value}`);
    this.params.context.componentParent.methodFromParent(`${this.value};${this.params.fieldNm};${this.params.node.id};`) 
    
    alert("click delete row"+ `${this.value};${this.params.fieldNm};${this.params.node.id};`);

    return true;  
  }


  private getClsName(value): string{
    let workClsName : string;

    if(value == "0"){
      workClsName = "조회";
    }else if(value == "1"){
      workClsName = "신규";
    }else if(value == "2"){
      workClsName = "수정";
    }else if(value == "3"){
      workClsName = "삭제";
    }
    return workClsName;
  }

  getDeleteRow(){
    alert("click delete row"+ `${this.value};${this.params.fieldNm};${this.params.node.id};`);
  }

  private getClsCd(value): string{
    let workClsCd : string;

    if(value == "조회"){
      workClsCd = "0";
    }else if(value == "신규"){
      workClsCd = "1";
    }else if(value == "수정"){
      workClsCd = "2";
    }else if(value == "삭제"){
      workClsCd = "3";
    }
    return workClsCd;
  }


  public valueLoaded(): string {
    return this.value;
  }  
}
