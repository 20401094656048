import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Home
import { HomeComponent } from '../home/home.component';
import { NotFoundComponent } from "../not-found/not-found.component";


const routes: Routes = [

  { path: "", component: HomeComponent, data: { title: "Home" } },
  { path: "home", redirectTo: "/", pathMatch: "full" },
  //{ path: "**", component: NotFoundComponent, data: { title: "Page Not Found" } }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
  ]
})
export class RoutingModule { }
