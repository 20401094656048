import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { CommonService } from './common.service';

@Injectable()
export class CrtfdHstService implements CommonService {
  myAppUrl: string = "";

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }

  getAllValues() : Observable<HttpResponse<Object[]>> {
    let params = new HttpParams();
    params = null
    
    return this._http.get<Object[]>(this.myAppUrl + 'api/TblCrtfdHst/Get/all',{
       params, observe: "response"
    });
  }

  getValuesByParam(param) : Observable<Object[]> {
    let params = new HttpParams();
    params = params.append('param', param);  

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblCrtfdHst/Get', param);
  }

saveValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblCrtfdHst/Save', body, {
      headers: headerOptions
    });
  }

  deleteValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblCrtfdHst/Delete', body, {
      headers: headerOptions
    });
  }

  getCrtfdKeyByHwKey(param) : Observable<Object[]> {
    let params = new HttpParams();
    params = params.append('param', param);

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblCrtfdHst/CrtfdKey/HwKey', param);
  } 

  getDescriptByCrtfdKey(param) {
    let params = new HttpParams();
    params = params.append('param', param);

    return this._http.post(this.myAppUrl + 'api/TblCrtfdHst/Dec/CrtfdKey', param,{
      responseType: 'text'
    });
  } 

  getSequenceValue() {
    let params = new HttpParams();
    params = null
    
    return this._http.get(this.myAppUrl + 'api/TblCrtfdHst/Get/Seq',{
       responseType: 'text'
    });
  }


  // TBL_CrtfdHst_LOG
  getAllValuesLog() : Observable<HttpResponse<Object[]>> {
    let params = new HttpParams();
    params = null
    
    return this._http.get<Object[]>(this.myAppUrl + 'api/TblCrtfdHst/Get/all/log',{
       params, observe: "response"
    });
  }

  getValuesByParamLog(param) : Observable<Object[]> {
    let params = new HttpParams();
    params = params.append('param', param);  

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblCrtfdHst/Get/log', param);
  }
}