import { Component, QueryList, ViewChildren, ViewContainerRef } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { FormControl, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';

// import { ProjectComponent } from '../../governor/project/project.component';
 
@Component({
  templateUrl: './datepicker.component.html'
})
export class DatepickerComponent implements ICellRendererAngularComp {

  // declarations
  public params: any;
  private loadValue: string;
  cellName: string;

  date: Date;
  myForm = new FormGroup({
    myDateYMD: new FormControl(null)
  });
  
  IsOpened: boolean = false;

  agInit(params: any): void {
    this.cellName = params['cellName'];
    this.params = params;

    this.loadValue = this.params.value;
    //console.log(this.loadValue);

    this.date = new Date(this.loadValue);
  }
  
  // called when the cell is refreshed
  refresh(params: any): boolean {
    // this.params = params;
    // // console.log('DatepickerComponent params ', this.params.value);          
    // // this.loadValue = this.params.value;
    // this.date = this.params['sDate'];
    // this.loadValue = this.date;
    return true;
  }

  public valueLoaded(): string {
    return this.loadValue;
  }
  
  onValueChange(value: Date): void {
    this.date = value;

    var _date = this.date;
    if(!isNullOrUndefined(_date) && this.IsOpened) {
      console.log('datepicker onValueChange : '+this.date);
      // alert(this.params.node.id);
      this.IsOpened = false;
      this.invokeParentMethod();
    }
  }

  onShown(event) {
    this.IsOpened = true;
  }

  onHide(event) {
    this.IsOpened = false;
  }

  invokeParentMethod() {
    this.params.context.componentParent.methodFromParent(`${this.date};${this.cellName};${this.params.node.id}`)
  }
}