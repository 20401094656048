import { Component } from '@angular/core';
import { FabAnimations } from '../style/fab/fab.animations';
import { CertificationComponent } from './certification/certification.component';
import { CertificationExceptComponent } from './certification-except/certificationExcept.component';
import { IllegalUserComponent } from './illegal-user/illegalUser.component';
import { Router, NavigationEnd, Event  } from '@angular/router';
import { ContractComponent } from './contract/contract.component';
import { SiteCrtfdRegComponent } from './sitecrtfdreg/sitecrtfdreg.component';
import { CrtfdHstComponent } from './crtfdhst/crtfdhst.component';

@Component({
    selector: 'app-governor',
    templateUrl: './governor.component.html',
    styleUrls: ['./governor.component.scss'],
    animations: FabAnimations
})
/** governor component*/
export class GovernorComponent {
    endUrl: string;
    endUrl_kr: string;

  constructor(private router: Router
    , private _contractComp: ContractComponent
    , private _certificationComp: CertificationComponent
    , private _certificationExceptComp: CertificationExceptComponent
    , private _illegalUserComp: IllegalUserComponent
    , private _sitecrtfdregComp: SiteCrtfdRegComponent
    , private _crtfdHstComp: CrtfdHstComponent) {
      this.router.events.subscribe((event: Event) => {
        if(event instanceof NavigationEnd ) {
          var url = event.url + '';
          var urlName = url.substring(url.lastIndexOf('/')+1)
          this.endUrl = urlName;
          // console.log(urlName);

          switch(this.endUrl) {
            case "contract" :
              this.endUrl_kr = '　계약 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "certification" :
              this.endUrl_kr = '　인증 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "certification-except" :
              this.endUrl_kr = '　SITE 인증 허용 대수　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "version":
              this.endUrl_kr = '　버전 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "illegal-user":
                this.endUrl_kr = '　불법사용자 등록　';
                document.getElementById("headerSearchBtn").hidden = false;
                break;
            case "sitecrtfdreg":
                this.endUrl_kr = '　SITE 라이선스 등록　';
                document.getElementById("headerSearchBtn").hidden = false;
                break;
            case "crtfdhst":
                this.endUrl_kr = '　인증 내역　';
                document.getElementById("headerSearchBtn").hidden = false;
                break;
            default :
              this.endUrl_kr = this.endUrl;
              break;
          }
        }
      });
    }

    btnSearch = { icon: 'search', title: '조회', func: 'search()' }
    btnAdd = { icon: 'playlist_add', title: '추가', func: 'add()' }
    btnSave = { icon: 'save', title: '저장', func: 'save()' }
    btnDelete = { icon: 'delete_forever', title: '삭제', func: 'delete()' }

    buttons = [];
    fabTogglerState = 'inactive';

    showItems() {
      this.fabTogglerState = 'active';

      if(this.endUrl === 'contract') {
        this.buttons.push(this.btnSearch);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnAdd);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
      else if(this.endUrl === 'certification') {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
      else if(this.endUrl === 'certification-except') {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
      else if (this.endUrl === 'version') {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
      else if (this.endUrl === 'illegal-user') {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
      else if (this.endUrl === 'sitecrtfdreg') {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
      else if (this.endUrl === 'crtfdhst') {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
      else {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        if (sessionStorage.getItem("SiteGroup") == "A") this.buttons.push(this.btnDelete);
      }
    }

    hideItems() {
      this.fabTogglerState = 'inactive';
      this.buttons = [];
    }

    onToggleFab() {
      this.buttons.length ? this.hideItems() : this.showItems();
    }

    execFunc(name) {
      if(name === 'add()') {
        this.add();
        this.hideItems();
      } else if(name === 'search()') {
        this.search(name);
        this.hideItems();
      } else if(name === 'save()') {
        this.save(name);
        this.hideItems();
      } else if(name === 'delete()') {
        this.delete(name);
        this.hideItems();
      } else {

      }
    }

    add() {
      if (this.endUrl === 'certification') {
        this._certificationComp.onAddRow();
      } else if (this.endUrl === 'certification-except') {
        this._certificationExceptComp.onAddRow();
      } else if (this.endUrl === 'contract') {
        this._contractComp.onAddRow();
      } else if (this.endUrl === 'illegal-user') {
        this._illegalUserComp.onAddRow();
      } else if (this.endUrl === 'sitecrtfdreg') {
        this._sitecrtfdregComp.onAddRow();
      } else if (this.endUrl === 'crtfdhst') {
        this._crtfdHstComp.onAddRow();
      }
    }

    search(name) {
      // alert('search : '+name);
      if (this.endUrl === 'certification') {
        this._certificationComp.onSearchBoxVisible();
      } else if (this.endUrl === 'certification-except') {
        this._certificationExceptComp.onSearchBoxVisible();
      } else if (this.endUrl === 'contract') {
        this._contractComp.onSearchBoxVisible();
      } else if (this.endUrl === 'illegal-user') {
        this._illegalUserComp.onSearchBoxVisible();
      } else if (this.endUrl === 'sitecrtfdreg') {
        this._sitecrtfdregComp.onSearchBoxVisible();
      } else if (this.endUrl === 'crtfdhst') {
        this._crtfdHstComp.onSearchBoxVisible();
      }

    }

    save(name) {
      if (this.endUrl === 'certification') {
        this._certificationComp.saveValues();
      } else if (this.endUrl === 'certification-except') {
        this._certificationExceptComp.saveValues();
      } else if (this.endUrl === 'contract') {
        this._contractComp.saveValues();
      } else if (this.endUrl === 'illegal-user') {
        this._illegalUserComp.saveValues();
      } else if (this.endUrl === 'sitecrtfdreg') {
        this._sitecrtfdregComp.saveValues();
      } else if (this.endUrl === 'crtfdhst') {
        this._crtfdHstComp.saveValues();
      }

    }

    delete(name) {
      if (this.endUrl === 'certification') {
        this._certificationComp.deleteValues();
      } else if (this.endUrl === 'certification-except') {
        this._certificationExceptComp.deleteValues();
      } else if (this.endUrl === 'contract') {
        this._contractComp.deleteValues();
      } else if (this.endUrl === 'illegal-user') {
        this._illegalUserComp.deleteValues();
      } else if (this.endUrl === 'sitecrtfdreg') {
        this._sitecrtfdregComp.deleteValues();
      } else if (this.endUrl === 'crtfdhst') {
        this._crtfdHstComp.deleteValues();
      }

    }
}
