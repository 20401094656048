import { Component, Inject, NgModule,OnInit, ViewChild, Input, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PopUpDialogLoginComponent } from '../../common/popup/pop-up-dialog-login.component';
import { GridOptions } from "ag-grid-community";
import { AgGridNg2 } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';
import { isNullOrUndefined } from 'util';

@Component({
  templateUrl: './pop-up-dialog-join-completed.component.html',  
  styleUrls: ['./pop-up-dialog.css']
})
export class PopUpDialogJoinCompletedComponent{

  static matDial: MatDialog = null;

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogJoinCompletedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient, private ngZone: NgZone, public dialog: MatDialog, private _userService: UserService) {
      PopUpDialogJoinCompletedComponent.matDial = dialog;
    }
  
  public openUserManageDialog() {
    this.dialogRef.close();
    const dialogRef = PopUpDialogJoinCompletedComponent.matDial.open(PopUpDialogLoginComponent, {
      width: '430px', height : '469px',
      // data: this.params.value
    });
    
  }
  
}