import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-work-cls-renderer',
  // template: `<mat-checkbox [(ngModel)]="checked" (change)="checkBoxChange($event)" ></mat-checkbox>`,
  template: `<mat-checkbox [(ngModel)]="checked" (click)="onClicked()"></mat-checkbox>`,
    styleUrls: ['./common.component.css']
})
export class CheckBoxRendererComponent implements INoRowsOverlayAngularComp {
  private params: any;
  private checked = true;
  private value: any;
  dropDownTarget: string;

  agInit(params: any): void {
    this.params = params;     
    this.value  = params.value;            

    console.log("CheckBox " + this.params + " " + this.value);

    this.setCheckBox(params.value);      
  }

  refresh(params: any): boolean {
    //this.value = this.getCheckBox();
    //console.log(`refresh: ${this.value};${this.params.fieldNm};${this.params.node.id};`);
    //this.params.context.componentParent.methodFromParent(`${this.value};${this.params.fieldNm};${this.params.node.id};`) 
    
    //alert("click delete row"+ `${this.value};${this.params.fieldNm};${this.params.node.id};`);

    return true;  
  }

  onClicked(){
    if(this.params.popUpTarget == 'policymap' && !confirm("활성화 상태를 변경하시겠습니까?\n현재 : "+ (this.value == 'E' ? '활성화' : '비활성화') +" -> 변경 후 : " + (this.value == 'E' ? '비활성화' : '활성화') )) {
      this.checked = !this.checked; // 체크 취소
    } else {
      if(this.value == 'E'){
        this.value = "D";
      }else{
        this.value = "E";
      }

      this.params.context.componentParent.methodFromParent(`${this.value};${this.params.fieldNm};${this.params.node.id};`);
    }
  }

  // checkBoxChange(event){
  //   if(this.params.popUpTarget == 'policymap' && !confirm("활성화 상태를 변경하시겠습니까?\n현재 : "+ (this.value == 'E' ? '활성화' : '비활성화') +" -> 변경 후 : " + (this.value == 'E' ? '비활성화' : '활성화') )) {
  //     // this.checked = this.checked; // 체크 취소
  //     this.setCheckBox(this.value); // value 값 그대로 체크 상태 유지
  //   } else {
  //     // var checked = event.checked;
  //     this.checked = !this.checked;

  //     if(this.value == 'E'){
  //       this.value = "D";
  //     }else{
  //       this.value = "E";
  //     }

  //     // policymap 이 아니면 바로 변경
  //     this.params.context.componentParent.methodFromParent(`${this.value};${this.params.fieldNm};${this.params.node.id};`);
  //   }   
  // }

  private setCheckBox(value){

    if(value == "E"){
      this.checked = true;
    }else if(value == "D"){
      this.checked = false;
    }
  }

  public valueLoaded(): string {
    return this.value;
  }
}
