import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridNg2 } from 'ag-grid-angular';
import { Environment, GridOptions} from "ag-grid-community";
import { FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ChildMessageRendererComponent} from '../../common/child-message-renderer.component';
import { ParamsRendererComponent} from '../../common/params-renderer.component';
import { CellEditChangeRendererComponent} from '../../common/cell-edit-change-renderer.component';
import { PopUpRendererComponent} from '../../common/pop-up-renderer.component';
import { DropDownListRendererComponent} from '../../common/drop-down-list-renderer.component';
import { WorkClsRendererComponent } from '../../common/work-cls-renderer.component';
import { SlideToggleRendererComponent } from '../../common/slide-toggle-renderer.component';
import { CheckBoxRendererComponent } from '../../common/check-box-renderer.component';
import { PassWordRendererComponent } from '../../common/pass-word-renderer.component';
import { CustomLoadingOverlayComponent} from '../../common/custom-loading-overlay.component';
import { CustomNoRowsOverlayComponent} from '../../common/custom-no-rows-overlay.component';


import { ChromedriverService } from '../../services/Chromedriver.service';
import { DatePipe, formatDate } from '@angular/common'
import { isNullOrUndefined } from 'util';

import { MatInput, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../common/date.adapter';
import { ChromedriverData, IllegalUserData } from '../../models/model.component';

import {AppComponent} from '../../app.component';
import { stringify } from 'querystring';

export interface stat {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-governor-certification',
    templateUrl: './chromedriver.component.html',
    styleUrls: ['./chromedriver.component.scss'],
    // datapicker date format setting
    providers: [ {provide: DateAdapter, useClass: AppDateAdapter},
                 {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
               ]
})

export class ChromedriverComponent {
  @ViewChild('agGrid') agGrid: AgGridNg2;
  @ViewChild('selectedFromPicker', { read: MatInput }) selectedFromPicker: MatInput;
  @ViewChild('selectedToPicker', { read: MatInput }) selectedToPicker: MatInput;

  //검색 조건 영역 변수
  public rowHeight : any;

  // [app.component.ts] 로그인 사용자의 SITE_ID 데이터
  public loginUserSiteId: String;

    // 저장 및 삭제 영역
  static chromedriverComp;  // 전역 변수로 선언하여 외부 Component에서도 동일한 Object를 사용하도록 함.
  public execList: IllegalUserData[];

  public gridOptions: GridOptions;
  private getRowHeight;

  private gridApi;
  private gridColumnApi;

  title = 'app';

  search: any = {};

  columnDefs = [
    { headerName: '', field: '', sortable: false, filter: false, editable:false, checkboxSelection: true, width: 48, headerCheckboxSelection: false, resizable: false },
    { headerName: '존재', field: 'isExists', sortable: true, filter:false, editable: false, width: 57, resiable: false,
      cellRenderer: function(params) {
        switch (params.node.data.isExists) {
          case true :
            return "<i class='material-icons' style='color:#447ed4; font-size: 20px; font-weight: bold;'>check</i>";
          default :
            return "<i class='material-icons' style='color:#d66451; font-size: 20px; font-weight: bold;'>close</i>";
        }
      }
    },
    { headerName: '파일명', field: 'fileName', sortable: true, filter: true, editable: false, width: 300, resizable: true },
    { headerName: '파일UUID', field: 'fileUuid', sortable: true, filter: true, editable: false, width: 390, resizable: true, hide: false },
    { headerName: '파일크기', field: 'fileSize', sortable: true, filter: true, editable: false, width: 120, resizable: true, hide: false },
    { headerName: '다운로드', field: 'downloadCnt', sortable: true, filter: true, editable: false, width: 100, resizable: true, hide: false },
    { headerName: '다운', field: 'download', sortable: false, filter: false, editable: false, width: 57, resizable: false,
      cellRenderer: function(params) {
        switch (params.node.data.fileName) {
          case "" :
            return "";
          default :
            return "<i class='material-icons' style='color:#a5a5a5; font-size: 20px; font-weight: bold; cursor: pointer'>arrow_downward</i>";
        }
      }},
      { headerName: '링크', field: 'link', sortable: false, filter: false, editable: false, width: 57, resizable: false,
      cellRenderer: function(params) {
        switch (params.node.data.fileName) {
          case "" :
            return "";
          default :
            return "<i class='material-icons' style='color:#a5a5a5; font-size: 20px; font-weight: bold; cursor: pointer' click='copyToClipboard($event)'>link</i>";
        }
      }},
    { headerName: '등록일', field: 'regDt', sortable: true, filter: true, editable: false, width: 300, resizable: true, hide: false, valueFormatter: this.dateFormat }
  ];

  frameworkComponents = {
    CellEditChangeRendererComponent:CellEditChangeRendererComponent,
    PopUpRendererComponent : PopUpRendererComponent,
    DropDownListRendererComponent:DropDownListRendererComponent,
    ChildMessageRendererComponent: ChildMessageRendererComponent,
    WorkClsRendererComponent : WorkClsRendererComponent,
    SlideToggleRendererComponent: SlideToggleRendererComponent,
    CheckBoxRendererComponent : CheckBoxRendererComponent,
    PassWordRendererComponent : PassWordRendererComponent,
    CustomLoadingOverlayComponent : CustomLoadingOverlayComponent,
    CustomNoRowsOverlayComponent : CustomNoRowsOverlayComponent
  };

  // loaing overlay
  loadingOverlayComponent = "CustomLoadingOverlayComponent";
  noRowsOverlayComponent = "CustomNoRowsOverlayComponent";

  defaultColDef = {
    editable: true,
    resizable: true,
    cellClass: "align-center"
  };

  rowSelection = "multiple";
  rowData: any;

  // overlayNoRowsTemplate =
  // "<span style=\"padding: 100px; border: 4px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>";

  constructor(public http: HttpClient, private _chromedriverService: ChromedriverService, public datepipe: DatePipe) {
    ChromedriverComponent.chromedriverComp = this;  // governorComponent 의 AddRow, delete, save 제어를 위해

    this.gridOptions = <GridOptions>{
      context: {componentParent: this},
      pagination: true,
      paginationAutoPageSize: true
      };
    //this.gridOptions.rowData = createRowData();
    //this.onAddRow();
    this.gridOptions.columnDefs = this.columnDefs;
    // row height size setting
    this.getRowHeight = function(params) { return 46; };

  }

  ngOnInit() {
    // Grid 데이터 가져오기
    this.getAllValues();

    this.setDefaultSearchItems();
    //this.onSearchBoxVisible();
    document.getElementById('searchBtn').textContent = "search";
    document.getElementById('searchBtn').style.color = "#2a80b9";
    document.getElementById('searchBtn').style.display = "block";
    document.getElementById('searchSpot').innerHTML = "";
    document.getElementById('searchSpot').append(document.getElementById("_MFF_SearchBox"));
  }

  /************************************************************
   *   버튼 및 grid event 영역
   ************************************************************/
  // 검색조건 초기화
  onClear(){
    this.search.fileName = '';

  }

  // grid load event
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.hideOverlay();
  }

  // grid edit 발생 시 check box = true 를 하기위한 evnet 감지 methods
  onCellValueChanged(event) {
    if(!isNullOrUndefined(event.newValue) && event.newValue != event.oldValue) {
      var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
      rowNode.setSelected(true);

      // console.log(rowNode.data.cls);
      // 1은 Insert를 의미하기 때문에 Insert가 아닌데 Cell의 Value가 변화하는 경우는 Update로 간주
      if(rowNode.data.cls != 1) {
        rowNode.setDataValue('cls', 2); // Column Key와 Value임
      }
    }
  }

  cellClicked(event) {
    var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);

    if (event.column.colId == 'download') {
      this._chromedriverService.downloadChromedriver(event.data.fileUuid);
    }
    else if (event.column.colId == 'link') {
      this._chromedriverService.linkChromedriver(event.data.fileUuid);

      //navigator.clipboard.writeText(event.data.fileName)


    }
  }

  // cell renderer 에서 발생한 event 처리 결과를 받는 call back methods
  public methodFromParent(cell) {
    var cellValue = `${cell}`;
    let popUpRtnValue        = cellValue.split(';')[0];
    let fieldNm       = cellValue.split(';')[1];
    let rowIndex  = cellValue.split(';')[2];

    // console.log('methodFromParent bef : '+  popUpRtnValue+' '+fieldNm + ' ' + rowIndex);

    if(!isNullOrUndefined(popUpRtnValue) && fieldNm == "cls") {
      var rowNode = this.agGrid.api.getRowNode(rowIndex);
      rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
      rowNode.setSelected(true);

      var res = this.gridApi.updateRowData({ remove:[rowNode.data] });
      return;
    }

    if(!isNullOrUndefined(popUpRtnValue)) {
        this.setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue);
    }
  }

  // cell renderer 에서 받은 data 를 현재 grid 에 set
  setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue) {
    var rowNode = this.agGrid.api.getRowNode(rowIndex);
    rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
    rowNode.setSelected(true);

    if(fieldNm == "cls"){
      var selectedData = this.gridApi.getSelectedRows();
      // console.log('methodFromParent selectedData : '+  selectedData);
      var res = ChromedriverComponent.chromedriverComp.gridApi.updateRowData({ remove:selectedData });
    }
  }

  // Web Browser의 F5(새로고침) 기능과 동일
  refresh(): void {
    window.location.reload();
  }

  /************************************************************
   *   조회, 저장, 삭제 영역
   ************************************************************/
  // 전체 조회
  getAllValues() {
    this._chromedriverService.getAllValues().subscribe(
      data => this.rowData = data.body
    );
  }

    // pop up 조회 버튼 클릭
  public onSearch(search) : void{
    ChromedriverComponent.chromedriverComp.gridApi.showLoadingOverlay();
    if(isNullOrUndefined(search)) {
      var param: ChromedriverData = {
        FileName: "",
        FileUuid: "",
        //IsExists: false,
        Cls: 0
      };
    } else {
      var param: ChromedriverData = {
        FileName: search.fileName,
        FileUuid: "",
        //IsExists: false,
        Cls: 0
        // ContractId: this.search.searchContractId,
      };
    }

    this._chromedriverService.getValuesByParam(param).subscribe(
      data => this.rowData = data
    )

   setTimeout(() => {
    ChromedriverComponent.chromedriverComp.gridApi.hideOverlay();
     }, 1000);
  }

  getSelectedRows(_cls): ChromedriverData[] {
    var _chromedriverDataList: ChromedriverData[] = new Array<ChromedriverData>();
    var selectedData = ChromedriverComponent.chromedriverComp.gridApi.getSelectedRows();
    selectedData.forEach(function(row, index) {
      if(_cls != 3) {
        var chromedriverData: ChromedriverData = {
          FileName: row.fileName,
          FileUuid: row.fileUuid,
          Cls: row.cls
        };

        _chromedriverDataList.push(chromedriverData);
      } else {
        var chromedriverData: ChromedriverData = {
          FileName: row.fileName,
          FileUuid: row.fileUuid,
          Cls: _cls
        };

        _chromedriverDataList.push(chromedriverData);
      }
    });

    return _chromedriverDataList;
  }

  uploadChromedriver() {
    ChromedriverComponent.chromedriverComp.gridApi.showLoadingOverlay();
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = _ => {
      let files = Array.from(input.files);

      var file = "";
      files.forEach(element => { file += (element.name + "\n")});
      file.substring(0, file.length - 2);

      if(!confirm("아래 파일을 업로드하시겠습니까?\n\n" + file)) return;

      this._chromedriverService.uploadChromedriver(files).subscribe(
        data => {
          ChromedriverComponent.chromedriverComp.gridApi.hideOverlay();
          ChromedriverComponent.chromedriverComp.onSearch(this.search);
        }
      );
    };
    input.click();

    ChromedriverComponent.chromedriverComp.gridApi.hideOverlay();
    this.getAllValues();
  }

  deleteValues() {
    var paramList = ChromedriverComponent.chromedriverComp.getSelectedRows(3);

    this._chromedriverService.deleteValuesByParams(paramList).subscribe(
      data => {
        data; // 미사용
        // row data refresh
        //this.refresh();
      }
    );
    setTimeout(() => {
      ChromedriverComponent.chromedriverComp.gridApi.hideOverlay();
      ChromedriverComponent.chromedriverComp.onSearch(this.search)
    }, 1000);
  }

  setDefaultSearchItems() {
    this.search.fileName = "";
    // this.getAllValues();
  }

  onSearchBoxVisible() {
    if (document.getElementById('_MFF_SearchBox').hidden == true) {
      document.getElementById('searchBtn').textContent = "clear";
      document.getElementById('searchBtn').style.color = "#717171";
      document.getElementById('searchSpot').hidden = false;
      document.getElementById('_MFF_SearchBox').hidden = false;
    } else {
      document.getElementById('searchBtn').textContent = "search";
      document.getElementById('searchBtn').style.color = "#2a80b9";
      document.getElementById('searchSpot').hidden = true;
      document.getElementById('_MFF_SearchBox').hidden = true;
    }
  }

  dateFormat(dateParams) {
    if (dateParams.value == null) return "";

    var date = new Date(dateParams.value);
    var dateString = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0") + " " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + ":" + date.getSeconds().toString().padStart(2, "0")

    return dateString;
  }
} // end class
