
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdvancedComponent } from "./advanced.component";
import { ChromedriverComponent } from './chromedriver/chromedriver.component';
import { EdgedriverComponent } from './edgedriver/edgedriver.component';

const AdvancedRoutes: Routes = [
    {
    path: 'advanced',
    component: AdvancedComponent ,
        children: [
          { path: 'chromedriver', component: ChromedriverComponent, data: { title: "Advanced | Chromedriver        " } },
          { path: 'edgedriver', component: EdgedriverComponent, data: { title: "Advanced | Edgedriver        " } },
        ]
    }
];

@NgModule({
    imports: [
      RouterModule.forChild(AdvancedRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
    ]
})
export class AdvancedRoutingModule { }
