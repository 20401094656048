// Module
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule, MatCardModule, MatTableModule, MatGridListModule,MatTabsModule, MatSidenavModule,
         MatToolbarModule, MatIconModule, MatButtonModule, MatListModule, MatMenuModule, MatDatepickerModule,
         MatFormFieldModule, MatInputModule, MatNativeDateModule, MatSlideToggleModule, MatCheckboxModule,
         MatProgressSpinnerModule, MatOptionModule, MatSelectModule
        } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';

// Component
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { RoutingModule } from './routing/routing.module';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { StatisticsDemoComponent } from './control/statistics-demo.component';

//Home
import { HomeComponent } from './home/home.component';
import { AdvancedModule } from './advanced/advanced.module';
import { GovernorModule } from './governor/governor.module';
import { ConfigModule } from './config/config.module';

//import { NotFoundComponent } from './not-found/not-found.component';
import { CertificationService } from './services/Certification.service';
import { CertificationExceptService } from './services/CertificationExcept.service';
import { IllegalUserService } from './services/IllegalUser.service';
import { ContractService } from './services/Contract.service';
import { SiteCrtfdRegService } from './services/siteCrtfdReg.service';
import { CrtfdHstService } from './services/crtfdHst.service';
import { UserService } from './services/user.service';
import { EncryptService } from './services/encrypt.service';
import { ChromedriverService } from './services/Chromedriver.service';
import { EdgedriverService } from './services/Edgedriver.service';

// common
import { ChildMessageRendererComponent } from './common/child-message-renderer.component';
import { ParamsRendererComponent } from './common/params-renderer.component';
import { CellEditChangeRendererComponent } from './common/cell-edit-change-renderer.component';
import { CellEditDatepickerRendererComponent } from './common/cell-edit-datepicker-renderer.component';
import { PopUpRendererComponent} from './common/pop-up-renderer.component';
import { DropDownListRendererComponent } from './common/drop-down-list-renderer.component';

// ngx-bootstarp
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PopUpDialogUserComponent } from './common/popup/pop-up-dialog-user.component';
import { PopUpDialogDatepickerComponent } from './common/popup/pop-up-dialog-datepicker.component';
import { PopUpDialogLoginComponent } from './common/popup/pop-up-dialog-login.component';
import { PopUpDialogJoinComponent } from './common/popup/pop-up-dialog-join.component';
import { PopUpDialogJoinCompletedComponent } from './common/popup/pop-up-dialog-join-completed.component';
import { PopUpDialogContractComponent } from './common/popup/pop-up-dialog-contract.component';
import { PopUpDialogIdchkComponent } from './common/popup/pop-up-dialog-idchk.component';
import { PopUpDialogPwdChgComponent } from './common/popup/pop-up-dialog-pwdChg.component';
import { PopUpDialogPwdResetComponent } from './common/popup/pop-up-dialog-pwdReset.component';
import { PopUpDialogPassWdChgComponent } from './common/popup/pop-up-dialog-passWdChg.component';
import { PopUpDialogUserInfoChgComponent } from './common/popup/pop-up-dialog-userInfoChg.component';
import { WorkClsRendererComponent } from './common/work-cls-renderer.component';
import { SlideToggleRendererComponent } from './common/slide-toggle-renderer.component';
import { CheckBoxRendererComponent } from './common/check-box-renderer.component';
import { PassWordRendererComponent } from './common/pass-word-renderer.component';
import { CustomLoadingOverlayComponent } from './common/custom-loading-overlay.component';
import { CustomNoRowsOverlayComponent } from './common/custom-no-rows-overlay.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    //Home
    HomeComponent,
    //NotFoundComponent,
    /////////////
    HeaderComponent,
    StatisticsDemoComponent,
    SidenavListComponent,
    // common
    ChildMessageRendererComponent,
    ParamsRendererComponent,
    CellEditChangeRendererComponent,
    CellEditDatepickerRendererComponent,
    PopUpRendererComponent,
    PopUpDialogUserComponent,
    DropDownListRendererComponent,
    PopUpDialogDatepickerComponent,
    PopUpDialogLoginComponent,
    PopUpDialogJoinComponent,
    PopUpDialogJoinCompletedComponent,
    PopUpDialogContractComponent,
    PopUpDialogIdchkComponent,
    PopUpDialogPwdChgComponent,
    PopUpDialogPwdResetComponent,
    PopUpDialogPassWdChgComponent,
    PopUpDialogUserInfoChgComponent,
    WorkClsRendererComponent,
    SlideToggleRendererComponent,
    CheckBoxRendererComponent,
    PassWordRendererComponent,
    CustomLoadingOverlayComponent,
    CustomNoRowsOverlayComponent
  ],
  imports: [
    AdvancedModule,
    GovernorModule,
    ConfigModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    RoutingModule,
    ChartsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    MatExpansionModule, MatCardModule, MatTableModule, MatGridListModule,MatTabsModule, MatSidenavModule, MatToolbarModule, MatIconModule,
    MatButtonModule, MatListModule, MatMenuModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule,
    MatSlideToggleModule, MatCheckboxModule, MatProgressSpinnerModule, MatOptionModule, MatSelectModule,
    //AgGridModule.withComponents([])
    AgGridModule.withComponents([ChildMessageRendererComponent, ParamsRendererComponent, CellEditChangeRendererComponent, CellEditDatepickerRendererComponent,
      PopUpRendererComponent, DropDownListRendererComponent,WorkClsRendererComponent,
      SlideToggleRendererComponent, CheckBoxRendererComponent, PassWordRendererComponent
      ])
    , BsDatepickerModule.forRoot()
  ],
  entryComponents: [PopUpDialogUserComponent,
                    PopUpDialogDatepickerComponent, CustomLoadingOverlayComponent,
          CustomNoRowsOverlayComponent, PopUpDialogLoginComponent, PopUpDialogJoinComponent, PopUpDialogJoinCompletedComponent,
          PopUpDialogContractComponent, PopUpDialogIdchkComponent, PopUpDialogPwdChgComponent, PopUpDialogPwdResetComponent, PopUpDialogPassWdChgComponent, PopUpDialogUserInfoChgComponent],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    CertificationService, ContractService, SiteCrtfdRegService, CrtfdHstService, UserService, CertificationExceptService, IllegalUserService, BsDatepickerConfig, EncryptService, ChromedriverService, EdgedriverService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}
