import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule, MatCardModule, MatTableModule, MatGridListModule, MatTabsModule, MatSidenavModule, MatToolbarModule, MatIconModule,
  MatButtonModule, MatListModule, MatMenuModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgGridModule } from 'ag-grid-angular';;

import { ConfigRoutingModule } from "./config-routing.module";

import { ConfigComponent } from "./config.component";
import { CertificationComponent } from './certification/certification.component';
import { OpensourceComponent } from './opensource/opensource.component';

// FAB Style
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Datepicker
import { DatepickerComponent } from '../style/datepicker/datepicker.component';

import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

// ngx-bootstarp
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

//20190312_PROJECT화면 검색조건 추가
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MatSelectModule } from '@angular/material';

@NgModule({
  imports: [
    ConfigRoutingModule,
    MatCardModule,
    FlexLayoutModule,
    CommonModule,
    AgGridModule.withComponents([
      DatepickerComponent
    ]),
    MatExpansionModule, MatCardModule, MatTableModule, MatGridListModule,MatTabsModule, MatSidenavModule, MatToolbarModule,
    MatIconModule, MatButtonModule, MatListModule, MatMenuModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule
    , BrowserAnimationsModule
    , SatDatepickerModule, SatNativeDateModule
    , FormsModule, ReactiveFormsModule
    , BsDatepickerModule.forRoot()
    ,SelectDropDownModule //20190312_PROJECT화면 검색조건 추가
    ,MatSelectModule      //20190312_PROJECT화면 검색조건 추가
  ],
  declarations: [
    ConfigComponent,
    CertificationComponent,
    OpensourceComponent,
  ],
  entryComponents: [

  ],
  providers: [BsDatepickerConfig, DatePipe,  CertificationComponent,  OpensourceComponent]
})
export class ConfigModule {

}
