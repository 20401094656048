import { Component, Inject, NgModule,OnInit, ViewChild } from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material';
import {GridOptions} from "ag-grid-community";
import { AgGridNg2 } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { CertificationService } from '../services/Certification.service';
import { PopUpDialogUserComponent } from './popup/pop-up-dialog-user.component';
import { isNullOrUndefined } from 'util';

import { PopUpDialogDatepickerComponent } from './popup/pop-up-dialog-datepicker.component';

@Component({
  selector: 'app-pop-up-renderer',
  template: `
            <div  (click)="openDialog()" style="cursor:pointer">
              <i class="material-icons">launch</i>&nbsp;{{valueLoaded()}}
            </div>
          `,
  styles: ['./pop-up-renderer.component.scss']
})
export class PopUpRendererComponent implements ICellRendererAngularComp {

  private params: any;
  private loadValue: string;
  private popUpTarget: string;

  // pop up 에서 넘겨질 변수 선언
  private _robotId: string;
  private _robotName: string;
  private _userId: string;
  private _result : string[];

  static matDial: MatDialog = null;

  constructor(public dialog: MatDialog) {
    PopUpRendererComponent.matDial = dialog;
  }

  // called on init
  agInit(params: any): void {
       this.params = params;
       this.loadValue = this.params.value;
       this.popUpTarget = this.params.popUpTarget;
  }

// called when the cell is refreshed
  refresh(params: any): boolean {
      this.params = params;
      console.log('PopUpRendererComponent refresh params ', this.params);
      this.loadValue = this.params.value;
      this.popUpTarget = this.params.popUpTarget;
      return true;
  }

  public valueLoaded(): string {
      return this.loadValue;
  }

  public openDialog() : void {
    // robot pop up 호출
    if (this.popUpTarget == 'robot') {
      // const dialogRef = PopUpRendererComponent.matDial.open(PopUpDialogRobotComponent, {
      //   width: '500px', height: '500px',
      //   data: { _robotId: this.loadValue }
      // });

      // dialogRef.afterClosed().subscribe(result => {
      //   if (!isNullOrUndefined(result)) {
      //     this.setRobotResult(result);
      //     this.params.context.componentParent.methodFromParent(`${result[0]};${this.params.fieldNm};${this.params.node.id};${result[1]};`)
      //   }
      // });
    }
    else if (this.popUpTarget == 'datepicker') {
      // console.log('popupTarget : '+this.params.value);
      const dialogRef = PopUpRendererComponent.matDial.open(PopUpDialogDatepickerComponent, {
        data: this.params.value
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!isNullOrUndefined(result)) {
          // console.log(`${result[0]};${this.params.cellName};${this.params.node.id}`);
          this.params.context.componentParent.methodFromParent(`${result[0]};${this.params.fieldNm};${this.params.node.id}`);
        }
      });
    }
    else if (this.popUpTarget == 'project') {
      // const dialogRef = PopUpRendererComponent.matDial.open(PopUpDialogProjectComponent, {
      //   width: '578px', height: '653px',
      //   data: { _projectId: this.loadValue }
      // });

      // dialogRef.afterClosed().subscribe(result => {
      //   if (!isNullOrUndefined(result)) {
      //     this.setRobotResult(result);
      //     this.params.context.componentParent.methodFromParent(`${result[0]};${this.params.fieldNm};${this.params.node.id};${result[1]};`)
      //   }
      // });
    }
    else if (this.popUpTarget == 'user') {
      const dialogRef = PopUpRendererComponent.matDial.open(PopUpDialogUserComponent, {
        width: '500px', height: '500px',
        data: { _userId: this.loadValue }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!isNullOrUndefined(result)) {
          this.setResult(result);
          this.params.context.componentParent.methodFromParent(`${result[0]};${this.params.fieldNm};${this.params.node.id};${result[1]};`)
        }
      });
    }

    // 중복
    // else if (this.popUpTarget == 'project') {
    //   const dialogRef = PopUpRendererComponent.matDial.open(PopUpDialogProjectComponent, {
    //     width: '600px', height: '700px',
    //     data: { _userId: this.loadValue }
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!isNullOrUndefined(result)) {
    //       this.setResult(result);
    //       this.params.context.componentParent.methodFromParent(`${result[0]};${this.params.fieldNm};${this.params.node.id};${result[1]};`)
    //     }
    //   });
    // }

  }

  // pop-up-dialog-robot 선택 결과
  setRobotResult(result : any){
    this._result = result;
    console.log(`Dialog result: ${this._result[0]}`);
    this.loadValue = result[0];
  }

  // pop-up-dialog-user 선택 결과
  setResult(result: any) {
    this._result = result;
    console.log(`Dialog result: ${this._result[0]}`);
    this.loadValue = result[0];
  }


}

function createRowData() {
  var rowData = [];
  for (var i = 0; i < 1; i++) {
    rowData.push({
      row: "Row " + i,
      value: i,
      currency: i + Number(Math.random().toFixed(2))
    });
  }
  return rowData;
}
