import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";
import { isNullOrUndefined } from 'util';
import { ValueSansProvider } from '@angular/core/src/di/provider';
import { ValueService } from 'ag-grid-community';
import { ParamsRendererComponent } from './params-renderer.component';

export interface Value {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-drop-down-list-renderer',
  templateUrl: './drop-down-list-renderer.component.html',
  styleUrls: ['./common.component.css']
})

export class DropDownListRendererComponent implements INoRowsOverlayAngularComp {

  values: Value[];
  meta_cls: string;

  private params: any;
  private imageSource: string;
  private value: any;
  dropDownTarget: string;
  private selected: string;
  
  agInit(params: any): void {
    
    this.params = params;  
    this.values = params.values;

    // console.log(this.params);
    // console.log(this.values);    

    this.value = params.value; //DB에 들어있는 값
    this.selected = params.value; //선택되는 값(초기값)

    if(params.popUpTarget == "meta") {
      this.setMetaTypeSelect(params.data.cls);
    }
  }

  selectOption(param: any): boolean {
    
    if(this.params.popUpTarget == "robot" && param == 'I') {
      alert("'초기상태'는 선택할 수 없습니다.");
      return true;
    }

    if(param == "승인대기") {
      alert("승인대기는 선택할 수 없습니다.")
      return true;
    }
    
    // console.log(this.value);
    // console.log(param);    

    if(this.value != param) {
      this.params.context.componentParent.setSelectedRowForValue(param, this.params.node.id, this.getPopUpTarget(this.params.popUpTarget));
      return true; 
    } 

    this.value = param;
    return true;  
  }

  getPopUpTarget(target: any): string {
    var result = "";
    switch(target) {
      case "cert":
        result = "status";
        break;
      case "meta":
        result = "metaType";
        break;
      case "robot":
        result = "status";
        break;
      case "contract":
         result = "crtfdOnwerDiv";
         break;        
      case "productCls":
         result = "productCls";
         break;                 
      case "crtfdPolicy":
         result = "crtfdPolicy";
         break;                 
      case "crtfdStatus":
         result = "crtfdStatus";
         break;                 
      case "excptOption":
         result = "excptOption";
         break;                 
      default:
        result = "no";
        break;
    }
    return result;
  }

  selectDisable(value: any): boolean {
    if(value == "승인대기") {
      return true;
    }

    if(this.params.popUpTarget == "robot" && value == "I") {
      return true;
    } 

    return false;
  }

  setMetaTypeSelect(_cls) {
    if(_cls == "0") {
      this.values.length = 0;
      switch(this.value) {
        case "STRING" :
        this.values = [{value: 'STRING', viewValue: 'STRING'}];
        break;

        case "NUMBER" :
        this.values = [{value: 'NUMBER', viewValue: 'NUMBER'}];
        break;

        case "PASSWORD" :
        this.values = [{value: 'PASSWORD', viewValue: 'PASSWORD'}];
        break;

        case "BOOLEAN" :
        this.values = [{value: 'BOOLEAN', viewValue: 'BOOLEAN'}];
        break;

        default :
        this.values = [
          {value: 'STRING', viewValue: 'STRING'},
          {value: 'NUMBER', viewValue: 'NUMBER'},
          {value: 'PASSWORD', viewValue: 'PASSWORD'},
          {value: 'BOOLEAN', viewValue: 'BOOLEAN'}
        ];
        break;
      }
    } else {
      this.values = [
        {value: 'STRING', viewValue: 'STRING'},
        {value: 'NUMBER', viewValue: 'NUMBER'},
        {value: 'PASSWORD', viewValue: 'PASSWORD'},
        {value: 'BOOLEAN', viewValue: 'BOOLEAN'}
      ];
    }
  }
}
