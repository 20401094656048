import { Component, Inject, NgModule,OnInit, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormBuilder, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import {GridOptions} from "ag-grid-community";
import { AgGridNg2 } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { ContractService} from '../../services/Contract.service';
import { ContractData } from '../../models/model.component';
import { isNullOrUndefined } from 'util';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-pop-up-dialog-contract',
  templateUrl: './pop-up-dialog-contract.html',
  styleUrls: ['./pop-up-dialog.css']
})
export class PopUpDialogContractComponent{

  @ViewChild('agGrid') agGrid: AgGridNg2;

  contractValidations: FormGroup;
  //contractNumber = new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]);

  private params: any;
  public gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;

  public checkId : boolean;
  public contractId : string;
  public message : string;
  public productSeq : string;
  public productKey : string;
  public productCls : string;

  public envType : string;
  rowData : any;

  columnDefs = [
    { headerName: '', field: '', sortable: false, filter: false, editable: false, resizable: false, checkboxSelection: true, width: 40, hide: true },
    // { headerName: '계약번호', field: 'contractId', sortable: true, filter: true, hide: true},
    { headerName: 'ProductKey', field: 'productKey', sortable: true, filter: true, hide: true}
  ];

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient, private _contractService: ContractService, private _formBuilder: FormBuilder) {

      this.gridOptions = <GridOptions>{
        context: {
            componentParent: this
                  }
        };
        this.productSeq = data.toString()  // contract ui 에서 넘어온 제품구분(product cls) 01 : studio, 02: robot
        this.gridOptions.rowData = createRowData();
        this.gridOptions.columnDefs = this.columnDefs;
    }

  ngOnInit() {
    this.checkId = false;
  }

  get f() { return this.contractValidations.controls; }

  // 전체 조회
  getAllValues() {
    this._contractService.getAllValues().subscribe(
      data => this.rowData = data.body
    )
  }

  //pop up 창 닫기
  onNoClick(): void {
    this.dialogRef.close();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  // pop up 확인 버튼 클릭
  onClick() {
    // var strContractId = new String(this.contractId);
    var strProductKey = new String(this.productKey);
    var strProductCls = new String(this.productCls);
    var strProductSeq = new String(this.productSeq);
    var strAuthFeature = new String(this.envType);

    if(strProductKey == '' || strProductKey == 'undefined') {
      document.getElementById("inputAlert").textContent = "ProductKey를 검색해주세요.";
      document.getElementById("inputAlert").style.color = "red";
      document.getElementById("inputAlert").style.fontSize = "10.5";
      document.getElementById("inputAlert").style.display = "block";
      return;
    }

    if(strProductKey.length != 19) {
      this.productKey = "";
      document.getElementById("inputAlert").textContent = "제품 키의 자릿 수가 불일치 합니다. \nProductKey를 다시 검색해주세요. ";
      document.getElementById("inputAlert").style.color = "red";
      document.getElementById("inputAlert").style.fontSize = "10.5";
      document.getElementById("inputAlert").style.display = "block";
      return;
    }

    if(strAuthFeature == "NIK") {
      strAuthFeature = "A";
    }
    else if (strAuthFeature == "NOK") {
      strAuthFeature = "B";
    }
    else if (strAuthFeature == "NNT") {
      strAuthFeature = "C";
    }

    //PRODUCT_KEY 중복체크 로직 추가
    var param: ContractData = {
      ContractId: "",
      CrtfdOnwer: "",
      CrtfdOnwerDiv: "",
      CrtfdOnwerInfo: "",
      CrtfdStDt: "",
      CrtfdExpDt: "",
      CrtfdMinVersion: "",
      CrtfdVersion: "",
      CrtfdPolicy: "",
      ProductSeq: "",
      ProductCls: "",
      ProductKey: strProductKey as string,
      CrtfdAllowCnt: "",
      CrtfdAuthInfo: "",
      CrtfdAuthFeature: "",
      ContractDesc: "",
      Cls: 0,
      SysFlag: ""
    };

    this._contractService.CheckDuplicationProductKey(param).subscribe(
      data => {
        var contractId = document.getElementById("contractId").textContent;
        console.log("버튼 눌림. contract id : " + contractId);
        /* data 결과 값
         * 중복 데이터 존재 : true
         * 중복 데이터 미존재 : false
         */
        var duplicateResult;
        duplicateResult = data.toString();
        // console.log("중복체크 결과 : " + duplicateResult);

        if(duplicateResult == "true") {
          //중복 케이스
          alert("이미 사용 중인 Product Key 입니다. \n다른 Product Key를 검색해주세요.");
          console.log("이미 사용중");
        } else if (duplicateResult == "false") {
          //미중복 케이스, AG-GRID에 Mapping처리
          let result : String[] = [contractId, strProductCls, strProductKey, strProductSeq, strAuthFeature];
          this.dialogRef.close(result);
        } else {
          //예외 케이스
          alert("중복 체크 중 오류 발생, 다시 시도 해주세요.");
          this.dialogRef.close();
        }
      }
    );

  }

    // 검색 버튼 클릭
  public onSearch() : void{

    // var strContractId = new String(this.contractId);
    // strContractId = strContractId.trim();
    var strProductCls = new String(this.productCls);
    var strProductSeq = new String(this.productSeq);
    var strAuthFeature = new String(this.envType);
    var strProductKey = new String(this.productKey);

    if(this.envType == '' || isNullOrUndefined(this.envType)) {
      document.getElementById("inputAlert").textContent = "인증환경을 선택해주세요.";
      document.getElementById("inputAlert").style.display = "block";
      document.getElementById("inputboxAlert").focus();
      return;
    } else {
      document.getElementById("inputAlert").style.display = "none";
    }

    var param: ContractData = {
      ContractId: "", //sequenct 데이터, 임시로 해당 컬럼에 넣어서 전달
      CrtfdOnwer: "",
      CrtfdOnwerDiv: "",
      CrtfdOnwerInfo: "",
      CrtfdStDt: "",
      CrtfdExpDt: "",
      CrtfdMinVersion: "",
      CrtfdVersion: "",
      CrtfdPolicy: "",
      ProductSeq: strProductSeq as string,
      ProductCls: "",
      ProductKey:"",
      CrtfdAllowCnt: "",
      CrtfdAuthInfo: this.envType as string,
      CrtfdAuthFeature: strAuthFeature as string,
      ContractDesc: "",
      Cls: 0,
      SysFlag: ""
    };

    if (document.getElementById("contractId").textContent == '') {
      this._contractService.getContractIdSequenceValue().subscribe(
        data => {
          document.getElementById("contractId").textContent = data;
        }
      );
    }

    this._contractService.getProductKeyByContractId(param).subscribe(
      data => {
        var resData = data.toString();

        if (data == "") {
          document.getElementById("inputAlert").textContent = "다시 검색해주세요.";
          document.getElementById("inputAlert").style.color = "red";
          document.getElementById("inputAlert").style.fontSize = "10.5";
          document.getElementById("inputAlert").style.display = "block";
          document.getElementById("productKey").style.display = "none";
        }
        else {
          this.productKey = resData;
          document.getElementById("productKey").textContent = this.productKey;
          document.getElementById("productKey").style.color = "blue";
          document.getElementById("productKey").style.fontSize = "12";
          document.getElementById("productKey").style.display = "block";
          document.getElementById("inputAlert").style.display = "none";
        }
      }
    );
  }
} // end class

// 화면 open 시 신규 row 1개 추가 - 너무 없어 보여서
function createRowData() {
  var rowData = [];
  for (var i = 0; i < 1; i++) {
    rowData.push({
      row: "Row " + i,
      value: i,
      currency: i + Number(Math.random().toFixed(2))
    });
  }
  return rowData;
}
