import { Component, NgModule, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-work-cls-renderer',
  template: `
          <button mat-raised-button [disabled]="isDisabled" color="warn" (click)="getDeleteRow()">  
             <i class="material-icons" id="removeRow" *ngIf="this.value == 1" style="margin: 0px 0px 3px -1px; color:#ca0000; cursor:pointer">delete_outline</i>
          </button>
          `,
    styleUrls: ['./common.component.css']
})
export class WorkClsRendererComponent implements INoRowsOverlayAngularComp {

  private params: any;
  private value: any;
  isDisabled = false;
  selectImage = "delete";

  agInit(params: any): void {
      this.params = params;  
      this.value  = params.value;      
      //console.log(`params.value: ${params.value}  ${this.params.fieldNm}`);      
      
      if(this.value == 1){
        this.selectImage = "delete";
        this.isDisabled = false;
      }else{
        this.selectImage = "";
        this.isDisabled = true;
      }
  }

  refresh(params: any): boolean {
    this.value = params.value;
    //console.log(`this.value: ${this.value}`);
    //this.params.context.componentParent.methodFromParent(`${this.value};${this.params.fieldNm};${this.params.node.id};`) 
    
    //alert("click delete row"+ `${this.value};${this.params.fieldNm};${this.params.node.id};`);

    return true;  
  }

  getDeleteRow(){

    this.selectImage = "delete_outline";
    //console.log(`check value: ${this.value};${this.params.fieldNm};${this.params.node.id};`);
    this.params.context.componentParent.methodFromParent(`${this.value};${this.params.fieldNm};${this.params.node.id};`)        
    //alert("click delete row"+ `${this.value};${this.params.fieldNm};${this.params.node.id};`);
  }

  public valueLoaded(): string {
    return this.value;
  }  


  // private getClsName(value): string{
  //   let workClsName : string;

  //   if(value == "0"){
  //     workClsName = "조회";
  //   }else if(value == "1"){
  //     workClsName = "신규";
  //   }else if(value == "2"){
  //     workClsName = "수정";
  //   }else if(value == "3"){
  //     workClsName = "삭제";
  //   }
  //   return workClsName;
  // }


  // private getClsCd(value): string{
  //   let workClsCd : string;

  //   if(value == "조회"){
  //     workClsCd = "0";
  //   }else if(value == "신규"){
  //     workClsCd = "1";
  //   }else if(value == "수정"){
  //     workClsCd = "2";
  //   }else if(value == "삭제"){
  //     workClsCd = "3";
  //   }
  //   return workClsCd;
  // }


}
