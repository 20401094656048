import { Component, Inject, NgModule,OnInit, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {GridOptions} from "ag-grid-community";
import { AgGridNg2 } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-pop-up-dialog-idchk',
  templateUrl: './pop-up-dialog-idchk.html',  
  styleUrls: ['./pop-up-dialog.css']
})
export class PopUpDialogIdchkComponent{

  @ViewChild('agGrid') agGrid: AgGridNg2;

  private params: any;
  public gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;

  public checkId : boolean;
  public userId : string;
  public message : string;
  public succId : string;

  rowData : any;
  
  columnDefs = [
    { headerName: '', field: '', sortable: false, filter: false, editable: false, resizable: false, checkboxSelection: true, width: 40, hide: true },
    { headerName: 'USER ID', field: 'userId', sortable: true, filter: true, hide: true}
  ];

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogIdchkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient, private _userService: UserService) {

      this.gridOptions = <GridOptions>{
        context: {
            componentParent: this
                  }
        };
        this.gridOptions.rowData = createRowData();        
        this.gridOptions.columnDefs = this.columnDefs;  
    }

  ngOnInit() {
    this.checkId = false;
    
    //this.getAllValues();

  }

  // 전체 조회   
  getAllValues() {
    this._userService.getAllValues().subscribe(
      data => this.rowData = data.body
    )
  }

  //pop up 창 닫기
  onNoClick(): void {
    this.dialogRef.close();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  // pop up 확인 버튼 클릭
  onClick() {
    //const selectedNodes = this.agGrid.api.getSelectedNodes();
    //const selectedData = selectedNodes.map(node => node.data);   
    //let result : string = selectedData.map(node => node.userId).toString();

    var strUserId = new String(this.userId);
    strUserId = strUserId.trim();

    if(this.checkId == false) {
      document.getElementById("inputIdcheck").textContent = "아이디 중복체크해주세요.";
      document.getElementById("inputIdcheck").style.color = "red";
      document.getElementById("inputIdcheck").style.display = "block";
      document.getElementById("inputboxIdcheck").focus();
      return;
    } else {
      document.getElementById("inputIdcheck").style.display = "none";
    }
    
    if(strUserId == '' || strUserId == 'undefined') {
      document.getElementById("inputIdcheck").textContent = "아이디를 입력해주세요.";
      document.getElementById("inputIdcheck").style.color = "red";
      document.getElementById("inputIdcheck").style.display = "block";
      document.getElementById("inputboxIdcheck").focus();
      return;
    } else {
      document.getElementById("inputIdcheck").style.display = "none";
    }
    
    if(this.succId != strUserId) {
      document.getElementById("inputIdcheck").textContent = "중복체크한 아이디값과 현재 아이디값이 다릅니다.";
      document.getElementById("inputIdcheck").style.color = "red";
      document.getElementById("inputIdcheck").style.display = "block";
      document.getElementById("inputboxIdcheck").focus();
      this.onSearch();
      return;
    } else {
      document.getElementById("inputIdcheck").style.display = "none";
    }

    console.log("user popup result : " + strUserId);
    this.dialogRef.close(strUserId);
    document.getElementById("inputId").style.display = "none";
  }  

    // 검색 버튼 클릭
  public onSearch() : void{

    var strUserId = new String(this.userId);
    strUserId = strUserId.trim();

    if(strUserId == '' || strUserId == 'undefined') {
      document.getElementById("inputIdcheck").textContent = "아이디를 입력해주세요.";
      document.getElementById("inputIdcheck").style.color = "red";
      document.getElementById("inputIdcheck").style.display = "block";
      document.getElementById("inputboxIdcheck").focus();
      return;
    } else {
      document.getElementById("inputIdcheck").style.display = "none";
    }

    var param: UserData = {
      UserId : strUserId as string,
      Status: "",
      UserType: "",
      UserName: "",
      Password: "",
      EncryptData: "",
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: "",
      Cls: 0,
      SysFlag: "",
    };

    this._userService.getUserIdDuplicateCheck(param).subscribe(
      //data => this.rowData = data
      (data: Boolean) => {
        if(data == true) {
          document.getElementById("inputIdcheck").textContent = "사용가능한 ID입니다.";
          document.getElementById("inputIdcheck").style.color = "green";
          document.getElementById("inputIdcheck").style.display = "block";
          this.succId =  strUserId as string;
          this.checkId = true;
        } else {
          document.getElementById("inputIdcheck").textContent = "이미 사용 중인 ID입니다.";
          document.getElementById("inputIdcheck").style.color = "red";
          document.getElementById("inputIdcheck").style.display = "block";
          document.getElementById("inputboxIdcheck").focus();
          this.checkId = false;
        }
      }
    );
  }

  public checkNumber(event)
  {
   var objEv = event.srcElement;
   var num ="{}[]()<>?_|~`!@#$%^&*-+\"'\\/ ";    //입력을 막을 특수문자 기재.
   event.returnValue = true;
    
   for (var i=0;i<objEv.value.length;i++)
   {
     if(-1 != num.indexOf(objEv.value.charAt(i))) {
      event.returnValue = false;  
    }  
   }
   if (!event.returnValue)
   {
      document.getElementById("inputIdcheck").textContent = "특수문자는 입력하실 수 없습니다.";
      document.getElementById("inputIdcheck").style.color = "red";
      document.getElementById("inputIdcheck").style.display = "block";
      objEv.value="";
   } else {
    document.getElementById("inputIdcheck").style.display = "none";
  }
  } 
}

// 화면 open 시 신규 row 1개 추가 - 너무 없어 보여서
function createRowData() {
  var rowData = [];
  for (var i = 0; i < 1; i++) {
    rowData.push({
      row: "Row " + i,
      value: i,
      currency: i + Number(Math.random().toFixed(2))
    });
  }
  return rowData;
}