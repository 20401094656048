import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { CommonService } from './common.service';

@Injectable()
export class ContractService implements CommonService {
  myAppUrl: string = "";

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }

  getAllValues() : Observable<HttpResponse<Object[]>> {
    let params = new HttpParams();
    params = null

    return this._http.get<Object[]>(this.myAppUrl + 'api/TblContract/Get/all',{
       params, observe: "response"
    });
  }

  getValueById(ContractId : string) : Observable<HttpResponse<Object[]>>{
    let params = new HttpParams();
    params = null;

    return this._http.get<Object[]>(this.myAppUrl + 'api/TblContract/Get/'+ContractId, {
      params, observe: "response"
    });
  }

  getValuesByParam(param) : Observable<Object[]> {

    let params = new HttpParams();
    params = params.append('param', param);

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblContract/Get', param);
  }

  getValuesByContractId(param) : Observable<Object[]> {

    let params = new HttpParams();
    params = params.append('param', param);

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblContract/ContractId', param);
  }

  getProductKeyByContractId(param) {

    let params = new HttpParams();
    params = params.append('param', param);

    return this._http.post(this.myAppUrl + 'api/TblContract/GenerateProductKey', param,{
      responseType: 'text'
    });
  }

  CheckDuplicationProductKey(param) {
    let params = new HttpParams();
    params = params.append('param', param);

    return this._http.post(this.myAppUrl + 'api/TblContract/Duplicate/Check', param,{
      responseType: 'text'
    });
  }

  CheckDuplicationContractId(param) {
    let params = new HttpParams();
    params = params.append('param', param);

    return this._http.post(this.myAppUrl + 'api/TblContract/Duplicate/ContractId', param,{
      responseType: 'text'
    });
  }

  refreshCertFlagInCrtfdHst(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblContract/Refresh', body, {
      headers: headerOptions
    });
  }

  saveValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblContract/Save', body, {
      headers: headerOptions
    });
  }

  deleteValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblContract/Delete', body, {
      headers: headerOptions
    });
  }

  getSequenceValue() {
    let params = new HttpParams();
    params = null

    return this._http.get(this.myAppUrl + 'api/TblContract/Get/Seq',{
       responseType: 'text'
    });
  }

  getContractIdSequenceValue() {
    let params = new HttpParams();
    params = null

    return this._http.get(this.myAppUrl + 'api/TblContract/Get/ConSeq',{
       responseType: 'text'
    });
  }
}
