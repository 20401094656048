import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridOptions} from "ag-grid-community";
import { ChildMessageRendererComponent} from '../../common/child-message-renderer.component';
import { CellEditChangeRendererComponent} from '../../common/cell-edit-change-renderer.component';
import { PopUpRendererComponent} from '../../common/pop-up-renderer.component';
import { DropDownListRendererComponent} from '../../common/drop-down-list-renderer.component';
import { WorkClsRendererComponent } from '../../common/work-cls-renderer.component';
import { SlideToggleRendererComponent } from '../../common/slide-toggle-renderer.component';
import { CheckBoxRendererComponent } from '../../common/check-box-renderer.component';
import { PassWordRendererComponent } from '../../common/pass-word-renderer.component';
import { CustomLoadingOverlayComponent} from '../../common/custom-loading-overlay.component';
import { CustomNoRowsOverlayComponent} from '../../common/custom-no-rows-overlay.component';

import { CrtfdHstService } from '../../services/crtfdHst.service';
import { isNullOrUndefined } from 'util';

import { MatInput, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../common/date.adapter';
import { CrtfdHstData } from '../../models/model.component';

// import {AppComponent} from '../../app.component';

export interface stat {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-crtfdhst',
    templateUrl: './crtfdhst.component.html',
    styleUrls: ['./crtfdhst.component.scss'],
    // datapicker date format setting
    providers: [ {provide: DateAdapter, useClass: AppDateAdapter},
                 {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
               ]
})

export class CrtfdHstComponent {
  @ViewChild('agGrid') agGrid: AgGridNg2;
  @ViewChild('selectedFromPicker', { read: MatInput }) selectedFromPicker: MatInput;
  @ViewChild('selectedToPicker', { read: MatInput }) selectedToPicker: MatInput;

  //검색 조건 영역 변수
  public rowHeight : any;

  // [app.component.ts] 로그인 사용자의 CrtfdHst_ID 데이터
  // public loginUserSiteId: String;

    // 저장 및 삭제 영역
  static CrtfdHstComp;  // 전역 변수로 선언하여 외부 Component에서도 동일한 Object를 사용하도록 함.
  public execList: CrtfdHstData[];

  public gridOptions: GridOptions;
  private getRowHeight;

  private gridApi;
  private gridColumnApi;

  status: stat[] = [
    {value: '0', viewValue: '인증키 발송 대기'},
    {value: '1', viewValue: '인증키 메일발송'},
    {value: '2', viewValue: '인증사용중'},
    {value: '3', viewValue: '부정사용으로 인한 인증제외'},
    {value: '5', viewValue: '인증키 발송 대기 (폐쇄망)'}
  ];

  cls: stat[] = [
    {value: '01', viewValue: 'STUDIO'},
    {value: '02', viewValue: 'ROBOT'}
  ];

  title = 'app';

  search: any = {};

  columnDefs = [
    { headerName: '', field: '', sortable: true, filter: false, editable:true, checkboxSelection: true, width: 48, headerCheckboxSelection: false, resizable: false },
    { headerName: '사용', field: 'sysFlag', sortable: true, editable: false, width: 57, resizable: true,
      cellRenderer: function(params) {
        switch (params.node.data.sysFlag) {
          case "A" :
            return "<i class='material-icons' style='color:#447ed4; font-size: 20px; font-weight: bold; cursor: pointer'>check</i>";
          case "D" :
            return "<i class='material-icons' style='color:#d66451; font-size: 20px; font-weight: bold; cursor: pointer'>close</i>";
          default :
            return "<i class='material-icons' style='color:#a5a5a5; font-size: 20px; font-weight: bold; cursor: pointer'>remove</i>";
        }
      }
    },
    { headerName: '계약주체', field: 'crtfdOnwer', sortable: true, filter: true, editable: false, width: 98, resizable: true },
    { headerName: '제품키', field: 'productKey', sortable: true, filter: true, editable: false, width: 200, resizable: true },
    { headerName: '일련번호', field: 'contractSeq', sortable: true, filter: true, editable: false, width: 130, resizable: true },
    { headerName: '인증발급시간', field: 'crtfdDt', sortable: true, filter: true, editable: false, width: 170, resizable: true, valueFormatter: this.dateFormat },
    { headerName: '개인키', field: 'hwKey', sortable: true, filter: true, editable: true, width: 150, resizable: true },
    { headerName: '발급된 인증키', field: 'crtfdKey', sortable: true, filter: true, editable: true, width: 150, resizable: true },
    { headerName: '인증상태', field: 'crtfdStatus', sortable: true, filter: true, editable: false, width: 210, resizable: true,
      cellRenderer: "DropDownListRendererComponent",
      cellRendererParams : {values: this.status, popUpTarget: 'crtfdStatus'}
    },
    { headerName: '인증생성시간', field: 'crtfdCrtDt', sortable: true, filter: true, editable: false, width: 200, resizable: true },
    { headerName: '인증받을 이메일', field: 'email', sortable: true, filter: true, editable: true, width: 200, resizable: true },
    { headerName: '제품구분', field: 'productCls', sortable: true, filter: true, editable: false, width: 100, resizable: true,
      cellRenderer: function(params) {
        //console.log(params);
        switch (params.node.data.productCls) {
          case "01" :
            return "Studio";
          case "02" :
            return "Robot";
          default :
            return "";
        }
      }
    },
    { headerName: '설치된 버전', field: 'instlCrtfdVersion', sortable: true, filter: true, editable: true, width: 130, resizable: true },
    { headerName: 'MAC Address', field: 'macAddress', sortable: true, filter: true, editable: true, width: 150, resizable: true },
    { headerName: '디스크 Volume', field: 'diskVolume', sortable: true, filter: true, editable: true, width: 150, resizable: true },
    { headerName: 'BIOS 시리얼번호', field: 'biosSeq', sortable: true, filter: true, editable: true, width: 170, resizable: true },
    { headerName: 'Local IP', field: 'localIp', sortable: true, filter: true, editable: true, width: 150, resizable: true },
    { headerName: '컴퓨터 이름', field: 'compName', sortable: true, filter: true, editable: true, width: 170, resizable: true },
    { headerName: '컴퓨터 ID', field: 'compId', sortable: true, filter: true, editable: true, width: 200, resizable: true },
    { headerName: '시스템생성일시', field: 'sysCreDt', sortable: true, filter: true, editable: false, width: 170, resizable: true, valueFormatter: this.dateFormat },
    { headerName: '시스템변경일시', field: 'sysUpdDt', sortable: true, filter: true, editable: false, width: 170, resizable: true, valueFormatter: this.dateFormat },
    { headerName: '활성화여부', field: 'sysFlag', hide: true },
    { headerName: '', field: 'cls', sortable: true, filter: true, editable:false, width: 68, resizable: false,
    cellRenderer: "WorkClsRendererComponent",
    cellEditor : 'agSelectCellEditor',
    cellRendererParams : {
            fieldNm: 'cls',
            values: ['조회','신규','수정','삭제'],
            cellRenderer: "WorkClsRendererComponent"
            }
    }
  ];

  frameworkComponents = {
    CellEditChangeRendererComponent:CellEditChangeRendererComponent,
    PopUpRendererComponent : PopUpRendererComponent,
    DropDownListRendererComponent:DropDownListRendererComponent,
    ChildMessageRendererComponent: ChildMessageRendererComponent,
    WorkClsRendererComponent : WorkClsRendererComponent,
    SlideToggleRendererComponent: SlideToggleRendererComponent,
    CheckBoxRendererComponent : CheckBoxRendererComponent,
    PassWordRendererComponent : PassWordRendererComponent,
    CustomLoadingOverlayComponent : CustomLoadingOverlayComponent,
    CustomNoRowsOverlayComponent : CustomNoRowsOverlayComponent
  };

  // loaing overlay
  loadingOverlayComponent = "CustomLoadingOverlayComponent";
  noRowsOverlayComponent = "CustomNoRowsOverlayComponent";

  defaultColDef = {
    editable: true,
    resizable: true,
    cellClass: "align-center"
  };

  rowSelection = "multiple";
  rowData: any;

  // overlayNoRowsTemplate =
  // "<span style=\"padding: 100px; border: 4px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>";

  constructor(public http: HttpClient, private _crtfdHstService: CrtfdHstService) {
    CrtfdHstComponent.CrtfdHstComp = this;  // managerComponent 의 AddRow, delete, save 제어를 위해

    this.gridOptions = <GridOptions>{
      context: {componentParent: this},
      pagination: true,
      paginationAutoPageSize: true
      };
    //this.gridOptions.rowData = createRowData();
    //this.onAddRow();
    this.gridOptions.columnDefs = this.columnDefs;
    // row height size setting
    this.getRowHeight = function(params) { return 46; };

  }

  ngOnInit() {
    // Grid 데이터 가져오기
    this.getAllValues();

    this.setDefaultSearchItems();
    //this.onSearchBoxVisible();
    document.getElementById('searchBtn').textContent = "search";
    document.getElementById('searchBtn').style.color = "#2a80b9";
    document.getElementById('searchBtn').style.display = "block";
    document.getElementById('searchSpot').innerHTML = "";
    document.getElementById('searchSpot').append(document.getElementById("_MFF_SearchBox"));

  }

  cellClick(event) {
    if (event.column.colId == 'sysFlag')
    {
      var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
      rowNode.setSelected(true);
      switch (event.value)
      {
        case "A":
          rowNode.setDataValue('sysFlag', "D");
          console.log(rowNode);
          break;
        case "D":
          rowNode.setDataValue('sysFlag', "A");
          console.log(rowNode);
          break;
        default:
          break;
      }
    }
  }

  /************************************************************
   *   버튼 및 grid event 영역
   ************************************************************/
  // 검색조건 초기화
  onClear(){
    this.search.searchProductKey = '';
  }

  // grid load event
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    //params.api.sizeColumnsToFit();
    this.gridApi.hideOverlay();

    // console.log('================this.onGridReady : ');
    // this.onInitPage();
  }

  onInitPage() {
    // var param: SiteCrtfdRegData = {
    //   SiteId : AppComponent.appComp.loginUserSiteId,
    //   SiteName: "",
    //   Cls: 0,
    //   SysFlag: "",
    // };

    // this._siteCrtfdRegService.getValuesByParam(param).subscribe(
    //   data => this.rowData = data
    // )
  }


  // grid edit 발생 시 check box = true 를 하기위한 evnet 감지 methods
  onCellValueChanged(event) {
    if(!isNullOrUndefined(event.newValue) && event.newValue != event.oldValue) {
      var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
      rowNode.setSelected(true);

      // 화면에서 데이터 INSERT 하는 경우 인증키 데이터 활용하여 자동 매핑 기능 구현
      // if(event.colDef.field == 'crtfdKey' && rowNode.data.crtfdKey.length > 100 && window.confirm("인증키 데이터 자동 매핑 기능 사용하시겠습니까?")) {
      //   this.CrtfdKeyDataInsert(rowNode.data.crtfdKey, event.rowIndex);
      // }

      // 화면에서 데이터 INSERT 하는 경우 개인키 데이터 활용하여 자동 매핑 기능 구현
      if(event.colDef.field == 'hwKey' && rowNode.data.hwKey.length > 100 && window.confirm("개인키 데이터 자동 매핑 및 인증키 생성하시겠습니까?")) {
        this.HwKeyDataInsert(rowNode.data.hwKey, rowNode.data.productKey, rowNode.data.email, event.rowIndex);
      }

      // 1은 Insert를 의미하기 때문에 Insert가 아닌데 Cell의 Value가 변화하는 경우는 Update로 간주
      if(rowNode.data.cls != 1) {
        rowNode.setDataValue('cls', 2); // Column Key와 Value임
      }
    }
  }

  //Grid 최하단에 신규 row 추가
  onAddRow() {
    CrtfdHstComponent.CrtfdHstComp.gridApi.showLoadingOverlay();
    /*
     * service를 통한 http 호출 함수는 async로 동작함. (일반 http.get(), post() 등은 확인 못해봄)
     * 따라서 순차처리가 필요하면 아래와 같이 subscribe 영역에 Logic을 구현해야함.
     */
    let id : string;

    this._crtfdHstService.getSequenceValue().subscribe(
      //data => id
      data => {
        var today = new Date();
        // var newItem = {
        //    siteId: data
        //   ,siteName: '{필수값} '
        //   ,cls: 1 };
        var newItem = {
          productKey: ""
          ,contractSeq: data
          ,crtfdKey: ""
          ,crtfdStatus: ""
          ,crtfdCrtDt: ""
          ,email: ""
          ,productCls: ""
          ,instlCrtfdVersion: ""
          ,macAddress: ""
          ,diskVolume: ""
          ,biosSeq: ""
          ,localIp: ""
          ,compName: ""
          ,compId: ""
          ,crtfdOnwer: ""
          ,cls: 1 };
        var res = CrtfdHstComponent.CrtfdHstComp.gridApi.updateRowData({ add: [newItem], addIndex: 0 });


      }
    );

    setTimeout(() => {
      CrtfdHstComponent.CrtfdHstComp.gridApi.hideOverlay();
     }, 500);
    //console.log("data : " + id);
  }


  // cell renderer 에서 발생한 event 처리 결과를 받는 call back methods
  public methodFromParent(cell) {
    var cellValue = `${cell}`;
    let popUpRtnValue        = cellValue.split(';')[0];
    let fieldNm       = cellValue.split(';')[1];
    let rowIndex  = cellValue.split(';')[2];

    // console.log('methodFromParent bef : '+  popUpRtnValue+' '+fieldNm + ' ' + rowIndex);

    if(!isNullOrUndefined(popUpRtnValue) && fieldNm == "cls") {
      var rowNode = this.agGrid.api.getRowNode(rowIndex);
      rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
      rowNode.setSelected(true);

      var res = this.gridApi.updateRowData({ remove:[rowNode.data] });
      return;
    }

    if(!isNullOrUndefined(popUpRtnValue)) {
        this.setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue);
    }
  }

  // cell renderer 에서 받은 data 를 현재 grid 에 set
  setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue) {
    var rowNode = this.agGrid.api.getRowNode(rowIndex);
    rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
    rowNode.setSelected(true);

    if(fieldNm == "cls"){
      var selectedData = this.gridApi.getSelectedRows();
      // console.log('methodFromParent selectedData : '+  selectedData);
      var res = CrtfdHstComponent.CrtfdHstComp.gridApi.updateRowData({ remove:selectedData });
    }
  }

  // Web Browser의 F5(새로고침) 기능과 동일
  refresh(): void {
    window.location.reload();
  }

  /************************************************************
   *   조회, 저장, 삭제 영역
   ************************************************************/
  // 전체 조회
  getAllValues() {
    this._crtfdHstService.getAllValues().subscribe(
      data => {
        this.rowData = data.body
      }
    )
  }

    // pop up 조회 버튼 클릭
  public onSearch(search) : void{
    CrtfdHstComponent.CrtfdHstComp.gridApi.showLoadingOverlay();
    if(isNullOrUndefined(search)) {
      var param: CrtfdHstData = {
        ProductKey: "",
        ContractSeq: "",
        CrtfdDt: "",
        HwKey: "",
        CrtfdKey: "",
        CrtfdStatus: "",
        CrtfdCrtDt: "",
        Email: "",
        ProductCls: "",
        InstlCrtfdVersion: "",
        MacAddress: "",
        DiskVolume: "",
        BiosSeq: "",
        LocalIp: "",
        CompName: "",
        CompId: "",
        Cls: 0,
        SysFlag: "",
        ResultMsg: "",
        CrtfdOnwer: ""
      };
    } else {
      var param: CrtfdHstData = {
        ProductKey: this.search.searchProductKey,
        ContractSeq: "",
        CrtfdDt: "",
        HwKey: "",
        CrtfdKey: "",
        CrtfdStatus: "",
        CrtfdCrtDt: "",
        Email: "",
        ProductCls: "",
        InstlCrtfdVersion: "",
        MacAddress: "",
        DiskVolume: "",
        BiosSeq: "",
        LocalIp: "",
        CompName: "",
        CompId: "",
        Cls: 0,
        SysFlag: "",
        ResultMsg: "",
        CrtfdOnwer: ""
      };
    }

    this._crtfdHstService.getValuesByParam(param).subscribe(
      data => this.rowData = data
    )

   setTimeout(() => {
    CrtfdHstComponent.CrtfdHstComp.gridApi.hideOverlay();
     }, 1000);

  }

  getSelectedRows(_cls): CrtfdHstData[] {
    var _crtfdHstList: CrtfdHstData[] = new Array<CrtfdHstData>();
    var selectedData = CrtfdHstComponent.CrtfdHstComp.gridApi.getSelectedRows();
    selectedData.forEach(function(row, index) {

      if(_cls != 3) {
        var crtfdHstData: CrtfdHstData = {
          ProductKey: row.productKey,
          ContractSeq: row.contractSeq,
          CrtfdDt: row.crtfdDt,
          HwKey: row.hwKey,
          CrtfdKey: row.crtfdKey,
          CrtfdStatus: row.crtfdStatus,
          CrtfdCrtDt: row.crtfdCrtDt,
          Email: row.email,
          ProductCls: row.productCls,
          InstlCrtfdVersion: row.instlCrtfdVersion,
          MacAddress: row.macAddress,
          DiskVolume: row.diskVolume,
          BiosSeq: row.biosSeq,
          LocalIp: row.localIp,
          CompName: row.compName,
          CompId: row.compId,
          Cls: row.cls,
          SysFlag: row.sysFlag,
          ResultMsg: row.ResultMsg,
          CrtfdOnwer: row.crtfdOnwer
        };

        _crtfdHstList.push(crtfdHstData);
      } else {
        var crtfdHstData: CrtfdHstData = {
          ProductKey: row.productKey,
          ContractSeq: row.contractSeq,
          CrtfdDt: row.crtfdDt,
          HwKey: row.hwKey,
          CrtfdKey: row.crtfdKey,
          CrtfdStatus: row.crtfdStatus,
          CrtfdCrtDt: row.crtfdCrtDt,
          Email: row.email,
          ProductCls: row.productCls,
          InstlCrtfdVersion: row.instlCrtfdVersion,
          MacAddress: row.macAddress,
          DiskVolume: row.diskVolume,
          BiosSeq: row.biosSeq,
          LocalIp: row.localIp,
          CompName: row.compName,
          CompId: row.compId,
          Cls: _cls,
          SysFlag: row.sysFlag,
          ResultMsg: row.ResultMsg,
          CrtfdOnwer: row.crtfdOnwer
        };

        _crtfdHstList.push(crtfdHstData);
      }
    });

    return _crtfdHstList;
  }

  saveValues() {
    var paramList = CrtfdHstComponent.CrtfdHstComp.getSelectedRows(1);
    CrtfdHstComponent.CrtfdHstComp.gridApi.showLoadingOverlay();

    this._crtfdHstService.saveValuesByParams(paramList).subscribe(
      data => {
        if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
          //SAVE Query Fail
          // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
          alert("저장 처리 중 오류가 발생했습니다.");
        } else {
          //SAVE Query Success
          data;
          //this.refresh();
        }
      }
    );
    setTimeout(() => {
      CrtfdHstComponent.CrtfdHstComp.gridApi.hideOverlay();
      // console.log("save->siteName: " + this.search.siteName);
      CrtfdHstComponent.CrtfdHstComp.onSearch(this.search)
    }, 1000);
  }

  deleteValues() {
    var paramList = CrtfdHstComponent.CrtfdHstComp.getSelectedRows(3);

    this._crtfdHstService.deleteValuesByParams(paramList).subscribe(
      data => {
        data; // 미사용
        // row data refresh
        //this.refresh();
      }
    );
    setTimeout(() => {
      CrtfdHstComponent.CrtfdHstComp.gridApi.hideOverlay();
      // console.log("save->siteName: " + this.search.siteName);
      CrtfdHstComponent.CrtfdHstComp.onSearch(this.search)
    }, 1000);
  }

  setDefaultSearchItems() {
    this.search.searchProductKey = "";
    // this.getAllValues();
  }

  setSelectedRowForValue(date, rowIndex, fieldNm) {
    var rowNode = this.agGrid.api.getRowNode(rowIndex);
    rowNode.setDataValue(fieldNm, date); // Column Key와 Value임
    rowNode.setSelected(true);
  }

  onSearchBoxVisible() {
    if (document.getElementById('_MFF_SearchBox').hidden == true) {
      document.getElementById('searchBtn').textContent = "clear";
      document.getElementById('searchBtn').style.color = "#717171";
      document.getElementById('searchSpot').hidden = false;
      document.getElementById('_MFF_SearchBox').hidden = false;
    } else {
      document.getElementById('searchBtn').textContent = "search";
      document.getElementById('searchBtn').style.color = "#2a80b9";
      document.getElementById('searchSpot').hidden = true;
      document.getElementById('_MFF_SearchBox').hidden = true;
    }
  }

  HwKeyDataInsert(DesData, productKey, email, idx) {
    var param: CrtfdHstData = {
      ProductKey: productKey,
      ContractSeq: "",
      CrtfdDt: "",
      HwKey: DesData,
      CrtfdKey: "",
      CrtfdStatus: "0",
      CrtfdCrtDt: "",
      Email: email,
      ProductCls: "",
      InstlCrtfdVersion: "",
      MacAddress: "",
      DiskVolume: "",
      BiosSeq: "",
      LocalIp: "",
      CompName: "",
      CompId: "",
      Cls: 1,
      SysFlag: "",
      ResultMsg: "",
      CrtfdOnwer: ""
    };

    var rowNode = this.agGrid.api.getDisplayedRowAtIndex(idx);

    this._crtfdHstService.getCrtfdKeyByHwKey(param).subscribe(
      data => {
        console.log("getCrtfdKeyByHwKey")
        console.log(data);
        param = {
          ProductKey: JSON.parse(JSON.stringify(data)).productKey,
          ContractSeq: JSON.parse(JSON.stringify(data)).contractSeq,
          CrtfdDt: JSON.parse(JSON.stringify(data)).crtfdDt,
          HwKey: JSON.parse(JSON.stringify(data)).hwKey,
          CrtfdKey: JSON.parse(JSON.stringify(data)).crtfdKey,
          CrtfdStatus: JSON.parse(JSON.stringify(data)).crtfdStatus,
          CrtfdCrtDt: JSON.parse(JSON.stringify(data)).crtfdCrtDt,
          Email: JSON.parse(JSON.stringify(data)).email,
          ProductCls: JSON.parse(JSON.stringify(data)).productCls,
          InstlCrtfdVersion: JSON.parse(JSON.stringify(data)).instlCrtfdVersion,
          MacAddress: JSON.parse(JSON.stringify(data)).macAddress,
          DiskVolume: JSON.parse(JSON.stringify(data)).diskVolume,
          BiosSeq: JSON.parse(JSON.stringify(data)).biosSeq,
          LocalIp: JSON.parse(JSON.stringify(data)).localIp,
          CompName: JSON.parse(JSON.stringify(data)).compName,
          CompId: JSON.parse(JSON.stringify(data)).compId,
          Cls: 1,
          SysFlag: "",
          ResultMsg: JSON.parse(JSON.stringify(data)).resultMsg,
          CrtfdOnwer: JSON.parse(JSON.stringify(data)).crtfdOnwer
        };

        var currentTime = new Date();
        var DateTime_Format = currentTime.getFullYear() + "-"
                              + (((currentTime.getMonth() +1).toString().length == 1) ? "0" + (currentTime.getMonth() + 1) : (currentTime.getMonth() + 1)) + "-"
                              + ((currentTime.getDate().toString().length == 1) ? "0" + currentTime.getDate() : currentTime.getDate()) + " "
                              + ((currentTime.getHours().toString().length == 1) ? "0" + currentTime.getHours() : currentTime.getHours()) + ":"
                              + ((currentTime.getMinutes().toString().length == 1) ? "0" + currentTime.getMinutes() : currentTime.getMinutes()) + ":"
                              + ((currentTime.getSeconds().toString().length == 1) ? "0" + currentTime.getSeconds() : currentTime.getSeconds());

        // 인증발급시간 (현재시간)
        param.CrtfdDt = DateTime_Format;
        if (param.ResultMsg && param.ResultMsg != "성공")
        {
          alert(param.ResultMsg);
        };

        rowNode.setDataValue('productKey', param.ProductKey);
        rowNode.setDataValue('productCls', param.ProductCls);
        rowNode.setDataValue('crtfdDt', param.CrtfdDt);
        rowNode.setDataValue('crtfdKey', param.CrtfdKey);
        rowNode.setDataValue('crtfdStatus', param.CrtfdStatus);
        rowNode.setDataValue('crtfdCrtDt', param.CrtfdCrtDt);
        rowNode.setDataValue('email', param.Email);
        rowNode.setDataValue('instlCrtfdVersion', param.InstlCrtfdVersion);
        rowNode.setDataValue('macAddress', param.MacAddress);
        rowNode.setDataValue('diskVolume', param.DiskVolume);
        rowNode.setDataValue('biosSeq', param.BiosSeq);
        rowNode.setDataValue('localIp', param.LocalIp);
        rowNode.setDataValue('compName', param.CompName);
        rowNode.setDataValue('compId', param.CompId);
        rowNode.setDataValue('crtfdOnwer', param.CrtfdOnwer);

      }
    );
  }

  CrtfdKeyDataInsert(EncData, idx) {
    var param: CrtfdHstData = {
      ProductKey: "",
      ContractSeq: "",
      CrtfdDt: "",
      HwKey: "",
      CrtfdKey: EncData,
      CrtfdStatus: "",
      CrtfdCrtDt: "",
      Email: "",
      ProductCls: "",
      InstlCrtfdVersion: "",
      MacAddress: "",
      DiskVolume: "",
      BiosSeq: "",
      LocalIp: "",
      CompName: "",
      CompId: "",
      Cls: 1,
      SysFlag: "",
      ResultMsg: "",
      CrtfdOnwer: ""
    };

    var rowNode = this.agGrid.api.getDisplayedRowAtIndex(idx);

    this._crtfdHstService.getDescriptByCrtfdKey(param).subscribe(
      data => {
        if(data.toString() != "Error") {
          var decDT = data.toString().split("\n");

          for(var i = 0; i < decDT.length; i++) {
            var crtfd_key = decDT[i].split("=")[0];
            var crtfd_value = decDT[i].split("=")[1];

            switch(crtfd_key) {
              case "NSM" :
                // 인증생성시간
                rowNode.data.crtfdCrtDt = crtfd_value;
                  break;
              case "PDK" :
                // ProductKey
                rowNode.data.productKey = crtfd_value;
                  break;
              case "EML" :
                // 인증받을 이메일
                rowNode.data.email = crtfd_value;
                  break;
              case "PRC" :
                // 제품구분
                rowNode.data.productCls = (crtfd_value.trim() == "ROBOT" ? "02" : "01");
                  break;
              case "MAC" :
                // MAC Address
                rowNode.data.macAddress = crtfd_value;
                  break;
              case "VOL" :
                // 디스크 Volume
                rowNode.data.diskVolume = crtfd_value;
                  break;
              case "BOS" :
                // BIOS 시리얼번호
                rowNode.data.biosSeq = crtfd_value;
                  break;
              case "LIP" :
                // Local IP
                rowNode.data.localIp = crtfd_value;
                  break;
              case "COM" :
                // 컴퓨터 이름
                rowNode.data.compName = crtfd_value;
                  break;
              case "WAC" :
                // 컴퓨터 ID
                rowNode.data.compId = crtfd_value;
                  break;
              case "VER" :
                // 설치된 버젼
                rowNode.data.instlCrtfdVersion = crtfd_value;
                  break;
              default :
                  break;
            }
          }

          // 인증 상태 (0: 인증키 발송 대기, 1: 인증키 메일발송, 2: 인증 사용중, 3: 부정사용으로 인한 인증제외)
          rowNode.data.crtfdStatus = "1";

          var currentTime = new Date();
          var DateTime_Format = currentTime.getFullYear() + "-"
                                + (((currentTime.getMonth() + 1).toString().length == 1) ? "0" + (currentTime.getMonth() + 1) : (currentTime.getMonth() + 1)) + "-"
                                + ((currentTime.getDate().toString().length == 1) ? "0" + currentTime.getDate() : currentTime.getDate()) + " "
                                + ((currentTime.getHours().toString().length == 1) ? "0" + currentTime.getHours() : currentTime.getHours()) + ":"
                                + ((currentTime.getMinutes().toString().length == 1) ? "0" + currentTime.getMinutes() : currentTime.getMinutes()) + ":"
                                + ((currentTime.getSeconds().toString().length == 1) ? "0" + currentTime.getSeconds() : currentTime.getSeconds());

          // 인증발급시간 (현재시간)
          rowNode.data.crtfdDt = DateTime_Format;

          // PRODUCT_KEY 데이터가 없는 경우 자동 매핑
          if(isNullOrUndefined(rowNode.data.productKey) || rowNode.data.productKey == '') {
            rowNode.data.productKey = "0000-0000-0000-0000";
          }

          // 인증키 세팅 (AG-GRID Refresh 효과)
          rowNode.setDataValue('crtfdKey', EncData);
        }
      }
    );
  }

  dateFormat(dateParams) {
    if (dateParams.value == null) return "";

    var date = new Date(dateParams.value);
    var dateString = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0") + " " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + ":" + date.getSeconds().toString().padStart(2, "0")

    return dateString;
  }
} // end class
