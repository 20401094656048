import { Component, Inject, ViewChild, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { isNullOrUndefined } from 'util';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';
import {FormControl, Validators} from '@angular/forms';
import { EncryptService } from '../../services/encrypt.service';

@Component({
  templateUrl: './pop-up-dialog-userInfoChg.component.html',
  styles: [`
  mat-form-field {
    margin-right: 12px;
  }
  `],
  styleUrls: ['../common.component.css']
})
export class PopUpDialogUserInfoChgComponent{
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private path; // Log File path
  private fileText; // Textarea
  maxRows;

  static matDial: MatDialog = null;
  siteId: string;

  public userList: UserData[];
  public userId: string;
  public userName: string;
  public password: string;
  public password_db: string;
  public telno: string;
  public email: string;
  public notiType: string;
  public comment: string;
  public status: string;
  public userType: string;

  public strData: string;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogUserInfoChgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient, private ngZone: NgZone, public dialog: MatDialog, private _userService: UserService, private _encryptService: EncryptService) {
      PopUpDialogUserInfoChgComponent.matDial = dialog;
    }

  ngOnInit() {
    this.getUserData(this.data._loginUserId);
  }

  getUserData(strUserId) {
    var param: UserData = {
      UserId : strUserId,
      Status: "",
      UserType: "",
      UserName: "",
      Password: "",
      EncryptData: "",
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: sessionStorage.getItem("Token"),
      Cls: 0,
      SysFlag: "",
    };

    this._userService.getUserIdCheck(param).subscribe(data => {

      var strUserId = "";
      var strUserName = "";
      var strSiteId = "";
      var strTelno = "";
      var strEmail = "";
      var strComment = "";
      var strNotiType = "";
      var strStatus = "";
      var strUserType = "";
      var strPassword_db = "";

      data.forEach(function (rowData) {
        strUserId      = JSON.parse(JSON.stringify(rowData)).userId;
        strUserName    = JSON.parse(JSON.stringify(rowData)).userName;
        strSiteId      = JSON.parse(JSON.stringify(rowData)).siteId;
        strTelno       = JSON.parse(JSON.stringify(rowData)).telno;
        strEmail       = JSON.parse(JSON.stringify(rowData)).email;
        strComment     = JSON.parse(JSON.stringify(rowData)).comment;
        strNotiType    = JSON.parse(JSON.stringify(rowData)).notiType;
        strStatus      = JSON.parse(JSON.stringify(rowData)).status;
        strUserType    = JSON.parse(JSON.stringify(rowData)).userType;
        strPassword_db = JSON.parse(JSON.stringify(rowData)).password;
      })

      this.userId = strUserId.toString();
      this.userName = strUserName.toString();
      this.siteId = strSiteId.toString();
      this.telno = strTelno.toString();
      this.email = strEmail.toString();
      this.comment = strComment.toString();
      this.notiType = strNotiType.toString();
      this.status = strStatus.toString();
      this.userType = strUserType.toString();
      this.password_db = strPassword_db.toString(); // DB에 적재되어 있는 패스워드 (암호화상태)
    });
  }

  // TBL_USER 데이터 추가
  onSaveUser() {

    if(this.userName == '' || isNullOrUndefined(this.userName)) {
      document.getElementById("inputName").textContent = "이름을 입력해주세요.";
      document.getElementById("inputName").style.display = "block";
      document.getElementById("inputboxName").focus();
      return;
    } else {
      document.getElementById("inputName").style.display = "none";
    }

    if(this.password == '' || isNullOrUndefined(this.password)) {
      document.getElementById("inputPwd").textContent = "기존의 비밀번호를 입력해주세요.";
      document.getElementById("inputPwd").style.display = "block";
      document.getElementById("inputboxPwd").focus();
      return;
    }
    document.getElementById("inputPwd").style.display = "none";

    if(this.siteId == '' || isNullOrUndefined(this.siteId)) {
      document.getElementById("inputSiteid").textContent = "사이트 아이디를 입력해주세요.";
      document.getElementById("inputSiteid").style.display = "block";
      document.getElementById("inputboxSiteid").focus();
      return;
    } else {
      document.getElementById("inputSiteid").style.display = "none";
    }

    if(this.telno == '' || isNullOrUndefined(this.telno)) {
      document.getElementById("inputTel").textContent = "전화번호를 입력해주세요.";
      document.getElementById("inputTel").style.display = "block";
      document.getElementById("inputboxTel").focus();
      return;
    } else {
      if(isNaN(+this.telno)) {
        document.getElementById("inputTel").textContent = "전화번호에 문자가 포함되어 있습니다.";
        document.getElementById("inputTel").style.display = "block";
        document.getElementById("inputboxTel").focus();
        return;
      }
      document.getElementById("inputTel").style.display = "none";
    }

    if(this.email == '' || isNullOrUndefined(this.email)) {
      document.getElementById("inputEmail").textContent = "이메일을 입력해주세요.";
      document.getElementById("inputEmail").style.display = "block";
      document.getElementById("inputboxEmail").focus();
      return;
    } else {
      if(this.emailFormControl.invalid == true) {
        document.getElementById("inputEmail").textContent = "이메일을 올바른 양식으로 입력해주세요.";
        document.getElementById("inputEmail").style.display = "block";
        document.getElementById("inputboxEmail").focus();
        return;
      }
      document.getElementById("inputEmail").style.display = "none";
    }

    if(this.comment == '' || isNullOrUndefined(this.comment)) {
      document.getElementById("inputCompany").textContent = "조직명을 입력해주세요.";
      document.getElementById("inputCompany").style.display = "block";
      document.getElementById("inputboxCompany").focus();
      return;
    } else {
      document.getElementById("inputCompany").style.display = "none";
    }

    if(this.notiType == '' || isNullOrUndefined(this.notiType)) {
      document.getElementById("inputAlert").textContent = "알림수단을 입력해주세요.";
      document.getElementById("inputAlert").style.display = "block";
      document.getElementById("inputboxAlert").focus();
      return;
    } else {
      document.getElementById("inputAlert").style.display = "none";
    }

    // 패스워드 암호화 적용
    var encryptData = this._encryptService.getEncryptedData(this.password);

    var param: UserData = {
      UserId : this.userId,
      Status: "",
      UserType: "",
      UserName: "",
      Password: encryptData.AESKeyUsingRSA,
      EncryptData: encryptData.DataUsingAES,
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: "",
      Cls: 0,
      SysFlag: "",
    };

    this._userService.getValueByEncryptPw(param).subscribe(
      data => {
        if(data.length == 0) {
          //암호 불일치
          alert("비밀번호가 불일치합니다.");
          this.password = '';
        } else {
          //암호 일치
          var _userList: UserData[] = new Array<UserData>();
          //this.password = passwordEncrypt(this.password, pubkey);

          var param: UserData = {
            UserId: this.userId,
            Status: this.status,
            UserType: this.userType,
            UserName: this.userName,
            Password: this.password_db,
            EncryptData: "",
            SiteId: this.siteId,
            Telno: this.telno,
            SiteGroup: "G",
            Email: this.email,
            Comment: this.comment,
            SysUserId: sessionStorage.getItem("loginId"),
            LastLoginDt: "",
            NotiType: this.notiType,
            LoginFailCount: "",
            PwdUpdDt: "",
            Token: sessionStorage.getItem("Token"),
            Cls: 2,
            SysFlag: "A",
          };

        _userList.push(param);

          this._userService.saveValuesByParams(_userList).subscribe(
            data => {
              //QUERY 에러 때문에 저장 안되는 현상 NOTI를 위한 로직
              if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
                //SAVE Query Fail
                // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
                alert("저장 처리 중 오류가 발생했습니다.");
              } else {
                //SAVE Query Success
                data;
                this.refresh();
              }
            }
          );
        }
      }
    );
    //this.dialogRef.close();
  }

  refresh(): void {
    window.location.reload();
  }

  onCancel() {
    this.dialogRef.close();
  }

  public checkName(event) {
    if(this.userName == '' || isNullOrUndefined(this.userName)) {
      document.getElementById("inputName").textContent = "이름을 입력해주세요.";
      document.getElementById("inputName").style.display = "block";
      return;
    }
    document.getElementById("inputName").style.display = "none";
  }

  public checkPwd(event)
  {
    if(this.password == '' || isNullOrUndefined(this.password)) {
      document.getElementById("inputPwd").textContent = "기존의 비밀번호를 입력해주세요.";
      document.getElementById("inputPwd").style.display = "block";
      return;
    } else {
      document.getElementById("inputPwd").style.display = "none";
    }
  }

  public checkNumber(event)
  {
    if(this.telno == '' || isNullOrUndefined(this.telno)) {
      document.getElementById("inputTel").textContent = "전화번호를 입력해주세요.";
      document.getElementById("inputTel").style.display = "block";
      return;
    } else if(isNaN(+this.telno)) {
      document.getElementById("inputTel").textContent = "전화번호에 문자가 포함되어 있습니다.";
      document.getElementById("inputTel").style.display = "block";
      return;
    } else {
      document.getElementById("inputTel").style.display = "none";
    }
  }

  public checkEmail(event) {
    if(this.email == '' || isNullOrUndefined(this.email)) {
      document.getElementById("inputEmail").textContent = "이메일을 입력해주세요.";
      document.getElementById("inputEmail").style.display = "block";
      return;
    } else {
      if(this.emailFormControl.invalid == true) {
        document.getElementById("inputEmail").textContent = "이메일을 올바른 양식으로 입력해주세요.";
        document.getElementById("inputEmail").style.display = "block";
        return;
      }
      document.getElementById("inputEmail").style.display = "none";
    }
  }

  public checkCompany(event) {
    if(this.comment == '' || isNullOrUndefined(this.comment)) {
      document.getElementById("inputCompany").textContent = "조직명을 입력해주세요.";
      document.getElementById("inputCompany").style.display = "block";
      return;
    }
    document.getElementById("inputCompany").style.display = "none";
  }
}
