import { Component, Inject, ViewChild, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';
import { isNullOrUndefined } from 'util';
import { EncryptService } from '../../services/encrypt.service';

@Component({
  templateUrl: './pop-up-dialog-pwdReset.component.html',  
  styles: [`mat-form-field {
    margin-right: 12px;
  }`],
  styleUrls: ['../common.component.css']
})
export class PopUpDialogPwdResetComponent{
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private path; // Log File path
  private fileText; // Textarea
  maxRows;

  static matDial: MatDialog = null;
  public userList: UserData[];

  public newPassword: string;
  public confirmPassword: string;

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogPwdResetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient, private ngZone: NgZone, public dialog: MatDialog, private _userService: UserService, private _encryptService: EncryptService) {
      PopUpDialogPwdResetComponent.matDial = dialog;
    }

  ngOnInit() {
  }

  // TBL_USER 데이터 추가
  onSavePwd() {
    if(this.newPassword == '' || isNullOrUndefined(this.newPassword)) {
      document.getElementById("inputNewPwd").textContent = "비밀번호를 입력해주세요.";
      document.getElementById("inputNewPwd").style.display = "block";
      document.getElementById("inputboxNewPwd").focus();
      return;
    } else {
      if(!validatePassword(this.newPassword)) {
      // if(this.newPassword.length < 8 || this.newPassword.length > 12) {
        document.getElementById("inputNewPwd").textContent = "특수문자, 숫자, 문자를 포함한 8~15자리 값을 입력해주세요.";
        // document.getElementById("inputNewPwd").textContent = "비밀번호를 8~12자리로 입력해주세요.";
        document.getElementById("inputNewPwd").style.display = "block";
        document.getElementById("inputboxNewPwd").focus();
        return;
      }
      document.getElementById("inputNewPwd").style.display = "none";
    }

    if(this.confirmPassword == '' || isNullOrUndefined(this.confirmPassword)) {
      document.getElementById("inputNewPwdConfirm").textContent = "비밀번호를 다시 입력해주세요.";
      document.getElementById("inputNewPwdConfirm").style.display = "block";
      document.getElementById("inputboxNewPwdConfirm").focus();
      return;
    } else {
      if(this.newPassword != this.confirmPassword) {
        document.getElementById("inputNewPwdConfirm").textContent = "비밀번호가 불일치합니다.";
        document.getElementById("inputNewPwdConfirm").style.display = "block";
        document.getElementById("inputboxNewPwdConfirm").focus();
        return;
      }
      document.getElementById("inputNewPwdConfirm").style.display = "none";
    }

    // 패스워드 암호화 적용
    var encryptData = this._encryptService.getEncryptedData(this.newPassword);

    var _userList: UserData[] = new Array<UserData>();
    var param: UserData = {
      UserId: this.data._loginUserId,
      Status: "",
      UserType: "",
      UserName: "",
      Password: encryptData.AESKeyUsingRSA,
      EncryptData: encryptData.DataUsingAES,
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: sessionStorage.getItem("Token"),
      Cls: 2,
      SysFlag: "A",
    };

    _userList.push(param);

    this._userService.saveValueByPw(_userList).subscribe(
      data => {
        //QUERY 에러 때문에 저장 안되는 현상 NOTI를 위한 로직
        if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
          //SAVE Query Fail
          // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
          alert("저장 처리 중 오류가 발생했습니다.");
        } else {
          //SAVE Query Success
          data;

          /* ********************************* 
          * 로그인실패횟수 초기화 로직 START
          * *********************************/
          var _userList: UserData[] = new Array<UserData>();
          var param: UserData = {
            UserId : this.data._loginUserId,
            Status: "",
            UserType: "",
            UserName: "",
            Password: "",
            EncryptData: "",
            SiteId: "",
            Telno: "",
            SiteGroup: "",
            Email: "",
            Comment: "",
            SysUserId: "",
            LastLoginDt: "",
            NotiType: "",
            LoginFailCount: "0",
            PwdUpdDt: "",
            Token: "",
            Cls: 2,
            SysFlag: "",
          };

          _userList.push(param);

          //로그인실패횟수 업데이트(LOGIN_FAIL_COUNT)
          this._userService.updateByLoginFailCount(_userList).subscribe(data => {
            data;

            //로그아웃 처리
            sessionStorage.setItem("loginId", '');
            alert("비밀번호가 재설정 되었습니다." + "\n재로그인 해주세요.");
            this.refresh();
          });  
         /* ********************************* 
          * 로그인실패횟수 초기화 로직 END
          * *********************************/
        }
    });
  }  

  refresh(): void {
    window.location.reload();
  } 

  onCancel() {
    this.dialogRef.close();
  }

  public checkNewPwd(event) 
  {
    if(this.newPassword == '' || isNullOrUndefined(this.newPassword)) {
      document.getElementById("inputNewPwd").textContent = "비밀번호를 입력해주세요.";
      document.getElementById("inputNewPwd").style.display = "block";
      return;
    } else {
      if(!validatePassword(this.newPassword)) {
      // if(this.newPassword.length < 8 || this.newPassword.length > 12) {
        document.getElementById("inputNewPwd").textContent = "특수문자, 숫자, 문자를 포함한 8~15자리 값을 입력해주세요.";
        // document.getElementById("inputNewPwd").textContent = "비밀번호를 8~12자리로 입력해주세요.";
        document.getElementById("inputNewPwd").style.display = "block";
        return;
      }
      document.getElementById("inputNewPwd").style.display = "none";
    }
  }

  public checkNewPwdConfirm(event) 
  {
    if(this.confirmPassword == '' || isNullOrUndefined(this.confirmPassword)) {
      document.getElementById("inputNewPwdConfirm").textContent = "비밀번호를 다시 입력해주세요.";
      document.getElementById("inputNewPwdConfirm").style.color = "red";
      document.getElementById("inputNewPwdConfirm").style.display = "block";
    } else {
      if(this.newPassword != this.confirmPassword) {
        document.getElementById("inputNewPwdConfirm").textContent = "비밀번호가 불일치합니다.";
        document.getElementById("inputNewPwdConfirm").style.color = "red";
        document.getElementById("inputNewPwdConfirm").style.display = "block";
      } else {
        document.getElementById("inputNewPwdConfirm").textContent = "비밀번호가 일치합니다.";
        document.getElementById("inputNewPwdConfirm").style.color = "green";
        document.getElementById("inputNewPwdConfirm").style.display = "block";
      }
    }
  }

}

// 비밀번호 정규식 적용 (특수문자, 숫자, 문자 최소 1자리 입력되어야 하며 최소 8자리 입력)
function validatePassword(character) {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/.test(character)
}