import { Component, Input } from '@angular/core';
import { ILoadingOverlayAngularComp } from "ag-grid-angular";

@Component({
    selector: 'app-loading-overlay',
    template: `<div align="center"><mat-spinner color="warn"></mat-spinner></div>`
              // `<div class="ag-overlay-loading-center" style="background-color: lightsteelblue; height: 9%">` +    
              // `   <i class="fas fa-hourglass-half"> {{this.params.loadingMessage}} </i>` +
              // `</div>`
})
export class CustomLoadingOverlayComponent implements ILoadingOverlayAngularComp {

  private params: any;

  agInit(params): void {
      this.params = params;
  }
}
