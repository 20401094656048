import { Component, Inject, NgModule,OnInit, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {GridOptions} from "ag-grid-community";
import { AgGridNg2 } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';

@Component({
  selector: 'app-pop-up-dialog-user',
  templateUrl: './pop-up-dialog-user.html',  
  styleUrls: ['./pop-up-dialog.css']
})
export class PopUpDialogUserComponent{

  @ViewChild('agGrid') agGrid: AgGridNg2;

  private params: any;
  public gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;

  public userId: string;
  public userName: string;
  public comment: string;
  rowData : any;
  
  columnDefs = [
    { headerName: '사용자ID', field: 'userId', sortable: true, filter: true, checkboxSelection: true },
    { headerName: '사용자이름', field: 'userName', sortable: true, filter: true },
    { headerName: 'COMMENT', field: 'comment', sortable: true, filter: true },
  ];

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient, private _userService: UserService) {
      this.gridOptions = <GridOptions>{
        context: {
            componentParent: this
                  }
        };
        this.gridOptions.rowData = createRowData();        
        this.gridOptions.columnDefs = this.columnDefs; 
        
       console.log('constructor data:', data);   
    }

  ngOnInit() {
    this.userId = this.data._userId;
    console.log(' ngOnInit data:', this.data._userId);
    this.getAllValues();
  }

  // 전체 조회   
  getAllValues() {
    this._userService.getAllValues().subscribe(
      data => this.rowData = data.body
    )
  }

  //pop up 창 닫기
  onNoClick(): void {
    this.dialogRef.close();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  // pop up 확인 버튼 클릭
  onClick() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);   
    //const selectedDataStringPresentation = selectedData.map(node => node.userId + ' ' + node.userName).join(', ');
    
    this.data._userId   = selectedData.map(node => node.userId).toString();
    //this.data._userName = selectedData.map(node => node.userName).toString();

    //let result: string[] = [this.data._userId, this.data._userName];
    let result: string[] = [this.data._userId];

    console.log('Selected nodes:', this.data._userId);   
    this.dialogRef.close(result);
  }  

    // pop up 조회 버튼 클릭
  public onSearch() : void{
    console.log('user name :', this.userName);    

    var param: UserData = {
      UserId: "",
      SysUserId: "",
      SysFlag: "",
      Status: "",
      UserType:"",
      UserName: this.userName,
      Password: "",
      EncryptData: "",
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: "",
      Cls: 0
    };

    this._userService.getValuesByParam(param).subscribe(
      data => this.rowData = data
    )
  } 
}

// 화면 open 시 신규 row 1개 추가 - 너무 없어 보여서
function createRowData() {
  var rowData = [];
  for (var i = 0; i < 1; i++) {
    rowData.push({
      row: "Row " + i,
      value: i,
      currency: i + Number(Math.random().toFixed(2))
    });
  }
  return rowData;
}
