import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// window.onload = () => {
//   var div = document.getElementsByClassName("mat-sidenav-container");
//   div.style.height = window.innerHeight - 54 + 'px';
//   document.getElementsByClassName("mat-sidenav-container").setAttribute("","");
// }