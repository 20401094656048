import { Component } from '@angular/core';
import { FabAnimations } from '../style/fab/fab.animations';

import { CertificationComponent } from './certification/certification.component';

// import { ExecuteComponent } from './execute/execute.component';
// import { RobotComponent } from './robot/robot.component';
// import { PolicymapComponent } from './policymap/policymap.component';

import { Router, NavigationEnd, Event  } from '@angular/router';

@Component({
    selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
  animations: FabAnimations
})
/** config component*/
export class ConfigComponent {
  endUrl: string;
  endUrl_kr: string;

  constructor(private router: Router
    , private _certComp: CertificationComponent) {
      this.router.events.subscribe((event: Event) => {
        if(event instanceof NavigationEnd ) {
          var url = event.url + '';
          var urlName = url.substring(url.lastIndexOf('/')+1)
          this.endUrl = urlName;
          // console.log(urlName);

          switch(this.endUrl) {
            case "meta" :
              this.endUrl_kr = '　기준정보 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "audit" :
              this.endUrl_kr = '　처리이력 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "notification" :
              this.endUrl_kr = '　공지/전파 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "certification" :
              this.endUrl_kr = '　승인 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "administrator" :
              this.endUrl_kr = '　관리자 설정　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            case "opensource" :
              this.endUrl_kr = '　Open Source　';
              document.getElementById("headerSearchBtn").hidden = true;
              break;
            case "log" :
              this.endUrl_kr = '　로그 관리　';
              document.getElementById("headerSearchBtn").hidden = false;
              break;
            default :
              this.endUrl_kr = this.endUrl;
              break;

          }

        }
      });
    }

    btnSearch = { icon: 'search', title: '조회', func: 'search()' }
    btnAdd = { icon: 'playlist_add', title: '추가', func: 'add()' }
    btnSave = { icon: 'save', title: '저장', func: 'save()' }
    btnDelete = { icon: 'delete_forever', title: '삭제', func: 'delete()' }

    buttons = [];
    fabTogglerState = 'inactive';

    showItems() {
      this.fabTogglerState = 'active';

      if(this.endUrl === 'meta') {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        this.buttons.push(this.btnDelete);
      } else if (this.endUrl == 'audit'){
        this.buttons.push(this.btnSearch);
        // this.buttons.push(this.btnAdd);
        // this.buttons.push(this.btnSave);
        // this.buttons.push(this.btnDelete);
      } else if (this.endUrl == 'notification'){
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        this.buttons.push(this.btnDelete);
      } else if (this.endUrl == 'certification'){
        this.buttons.push(this.btnSearch);
        //this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        //this.buttons.push(this.btnDelete);
      } else if (this.endUrl == 'administrator'){
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        this.buttons.push(this.btnDelete);
      } else if (this.endUrl == 'opensource') {
      } else if (this.endUrl == 'log') {
        this.buttons.push(this.btnSearch);
        //this.buttons.push(this.btnAdd);
        //this.buttons.push(this.btnSave);
        //this.buttons.push(this.btnDelete);
      } else {
        this.buttons.push(this.btnSearch);
        this.buttons.push(this.btnAdd);
        this.buttons.push(this.btnSave);
        this.buttons.push(this.btnDelete);
      }
    }

    hideItems() {
      this.fabTogglerState = 'inactive';
      this.buttons = [];
    }

    onToggleFab() {
      this.buttons.length ? this.hideItems() : this.showItems();
    }

    execFunc(name) {
      if(name === 'add()') {
        this.add();
      } else if(name === 'search()') {
        this.search(name);
      } else if(name === 'save()') {
        this.save(name);
      } else if(name === 'delete()') {
        this.delete(name);
      } else {

      }
    }

    add() {
      if(this.endUrl === 'meta') {
      } else if (this.endUrl == 'audit') {
        //미사용
      }
      else if (this.endUrl == 'notification') {
      }
      else if (this.endUrl == 'certification') {

      }
      else if (this.endUrl == 'administrator')  {

      }
    }

    search(name) {
      //alert('search : '+name);
      if (this.endUrl == 'notification') {
      }
      else if (this.endUrl == 'certification') {
        this._certComp.onSearchBoxVisible();
      }
      else if (this.endUrl == 'administrator')  {

      }
      else if (this.endUrl == 'log') {
      } else {

      }
    }

    save(name) {
      if(this.endUrl === 'meta') {
      } else if (this.endUrl == 'audit') {
        //미사용
      }
      else if (this.endUrl == 'notification') {
      }
      else if (this.endUrl == 'certification') {
        this._certComp.saveValues();
      }
      else if (this.endUrl == 'administrator')  {

      }
    }

    delete(name) {
      if(this.endUrl === 'meta') {
      } else if (this.endUrl == 'audit') {
        //미사용
      }
      else if (this.endUrl == 'notification') {
      }
      else if (this.endUrl == 'certification') {

      }
      else if (this.endUrl == 'administrator')  {

      }
    }
}
