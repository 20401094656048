import { Component } from '@angular/core';

@Component({
    selector: 'app-config-opensource',
    templateUrl: './opensource.component.html',
  styleUrls: ['./opensource.component.scss']
})
/** mdm component*/
export class OpensourceComponent {
    /** mdm ctor */
    constructor() {

    }
}
