import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material';
import { PopUpDialogLoginComponent } from '../../common/popup/pop-up-dialog-login.component';
import { AppComponent } from '../../app.component';
import { HttpClient } from '@angular/common/http';
import { EncryptService } from 'src/app/services/encrypt.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  @Output() sidenavSize = new EventEmitter();

  public static sidenavStatus: boolean = true;
  public loginUserId : string;
  public sidenavWidth = (mobileAccess() == true) ? 15 : 15;
  // public dashBoardView  : string = (mobileAccess() == true) ? "DashBoard" : "DashBoard";
  public GovernorView    : string = (mobileAccess() == true) ? "Governor" : "Governor";
  public configView      : string = (mobileAccess() == true) ? "Configuration" : "Configuration";
  public advancedView    : string = (mobileAccess() == true) ? "Advanced" : "Advanced";
  public isExpanded = true; //메뉴 리스트 view 제어변수
  public siteGroupChk = false; // SITE_GROUP 값으로 view 제어하는 변수 (운영자 : 불법사용자 등록, 인증예외 등록, 인증내역만 조회 가능)
  public step = -1; // 메뉴 확장 제어변수 (0: Governor, 1: Configuration)

  static matDial: MatDialog = null;

   visuableDashBoard: boolean;
   visuableGovernor: boolean;
   visuableConfig: boolean;
   visuableAdvanced: boolean;

  constructor(private _router: Router, public dialog: MatDialog, public http: HttpClient, private _encryptService: EncryptService) {
    SidenavListComponent.matDial = dialog;
  }

  ngOnInit() {
    this.visuableDashBoard = true;
    this.visuableGovernor = true;
    this.visuableAdvanced = true;

    //로그인 이후 새로고침을 할 경우, 로그인 데이터 유지를 위한 로직
    if(sessionStorage.getItem("loginId") != '' && !isNullOrUndefined(sessionStorage.getItem("loginId"))) {
      this.loginUserId = sessionStorage.getItem("loginId");
    } else {
      this.loginUserId = "";
    }

    //SITE_GROUP 으로 구분, 관리자일 경우에만 화면에 보여짐 (A:관리자, G:운영자)
    if(sessionStorage.getItem("SiteGroup") == "G") {
      this.siteGroupChk = false;
      this.visuableConfig = false;
    } else if(sessionStorage.getItem("SiteGroup") == "A") {
      this.siteGroupChk = true;
      this.visuableConfig = true;
    }
    else {
      this.siteGroupChk = false;
      this.visuableConfig = false;
    }
  }

  public onSidenavClose_home() {
    AppComponent.appComp.clearSession(); //로그인 세션만료 초기화
    this.sidenavClose.emit();
    SidenavListComponent.sidenavStatus = false;
    // console.log('sidenavStatus => ', SidenavListComponent.sidenavStatus);
  }

  public onSidenavClose = () => {
    AppComponent.appComp.clearSession(); //로그인 세션만료 초기화
    this.sidenavClose.emit();
    SidenavListComponent.sidenavStatus = false;
    if(isNullOrUndefined(this.loginUserId) || this.loginUserId == '') {
      //alert("로그인이 필요합니다.");
      this._router.navigate(['/home']);

      //로그인 dialog 호출
      const dialogRef = SidenavListComponent.matDial.open(PopUpDialogLoginComponent, {
        width: '400px', height : '385px',
      });

      //로그인 성공
      dialogRef.afterClosed().subscribe(result => {
        if(!isNullOrUndefined(result)) {
          sessionStorage.setItem("loginId", result.split("|")[0]);
          sessionStorage.setItem("SiteId", result.split("|")[1]);
          sessionStorage.setItem("SiteGroup", result.split("|")[2]);
          sessionStorage.setItem("Token", result.split("|")[3]);
          this.refresh();
        }
      });

      return false;
    }

    // console.log('sidenavStatus => ', SidenavListComponent.sidenavStatus);
  }

  refresh(): void {
    window.location.reload();
  }

  sidenavWidthIncrease() {
    // if(mobileAccess() == true) {
    //   this.sidenavWidth = 15;
    //   this.dashBoardView = "DashBoard";
    //   this.managerView = "Manager";
    //   this.configView = "Configuration";
    //   this.isExpanded = true;
    //   this.sidenavSize.emit(15);
    //   return;
    // }

    // this.sidenavWidth = 15;
    // this.dashBoardView = "DashBoard";
    // this.managerView = "Manager";
    // this.configView = "Configuration";
    // this.isExpanded = true;
    // this.sidenavSize.emit(this.sidenavWidth);
  }

  sidenavWidthDecrease() {
    // if(mobileAccess() == true) {
    //   this.sidenavWidth = 15;
    //   this.dashBoardView = "DashBoard";
    //   this.managerView = "Manager";
    //   this.configView = "Configuration";
    //   this.isExpanded = true;
    //   this.sidenavSize.emit(15);
    //   return;
    // }

    // this.sidenavWidth = 6;
    // this.dashBoardView = "";
    // this.managerView = "";
    // this.configView = "";
    // this.isExpanded = false;
    // this.sidenavSize.emit(this.sidenavWidth);
  }

  showDeveloperSettingPw() {
    let encrypted = this.generateTodayPw();
    //alert(encrypted);
  }

  getTodayDateFormat(): string {
    var now = new Date(Date.now());

    var year = now.getFullYear();
    var month = ("0" + (1 + now.getMonth())).slice(-2);
    var day = ("0" + now.getDate()).slice(-2);

    var today = year + month + day;
    //console.log("today : " + today);

    if (today.length != 8) return null;

    return today;
  }

  generateTodayPw() {
    let today = this.getTodayDateFormat();
    this._encryptService.encrypt128(today).subscribe(
      data => {
        alert("관리자 패스워드 : " + data);
      }
    )
  }
}

function mobileAccess() {
  var userIntmType = window.navigator.userAgent.toLowerCase();
    if ( /iphone/.test(userIntmType) || /android/.test(userIntmType) || /opera/.test(userIntmType) || /bada/.test(userIntmType) ) {
    //모바일
    return true;
  } else {
    //PC
    return false;
  }
}
