import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  templateUrl: './pop-up-dialog-datepicker.component.html',  
  styles: []
})
export class PopUpDialogDatepickerComponent {
  @ViewChild(BsDatepickerDirective) datepicker: BsDatepickerDirective;

  _date: Date;
 
  constructor(
    public dialogRef: MatDialogRef<PopUpDialogDatepickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public datepipe: DatePipe) {
      // 초기값 설정은 dialog가 다 완성된 시점에 Set 해줌.(ngOnInit() 등)
  }

  ngOnInit() {
    if(!isNullOrUndefined(this.data)) {
      this._date = new Date(this.data);
    }
    console.log('PopUpDialogDatepickerComponent : '+this._date);
  }

  //pop up 창 닫기
  onNoClick(): void {
    this.dialogRef.close();
  }

  // pop up 확인 버튼 클릭
  onClick() {

    if(!isNullOrUndefined(this._date) && !isNaN(this._date.getTime())) {
      let str = this.datepipe.transform(this._date, 'yyyy-MM-dd');
      // console.log(str);
      
      let result : string[] = [str];

      this.dialogRef.close(result);
    }
  } 

  // Datepicker value값 change event
  onValueChange(value: Date): void {
    if(!isNullOrUndefined(value)) {
      this._date = value;
    }
  }
}