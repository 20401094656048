import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridNg2 } from 'ag-grid-angular';
import { Router, NavigationEnd } from '@angular/router';
import { UserData } from '../..//models/model.component';
import { UserService } from '../../services/user.service';

import { GridOptions, ValueService } from "ag-grid-community";
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { CustomLoadingOverlayComponent} from '../../common/custom-loading-overlay.component';
import { CustomNoRowsOverlayComponent} from '../../common/custom-no-rows-overlay.component';

import { PopUpRendererComponent } from '../../common/pop-up-renderer.component';
import { DropDownListRendererComponent } from '../../common/drop-down-list-renderer.component';

import { FormGroup, FormControl } from '@angular/forms';
import { Event } from '@angular/router';
import { AppComponent } from '../../app.component';
import { EncryptService } from '../../services/encrypt.service';

export interface Value {
  value: string;
  viewValue: string;
}

@Component({
  //selector: 'app-config-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss']
})
/** mdm component*/
export class CertificationComponent {
  @ViewChild('agGrid') agGrid: AgGridNg2;
  private gridApi;

  static certComp;

  public certList: UserData[];

  public gridOptions: GridOptions;
  private getRowHeight;

  values: Value[] = [
    {value: '승인대기', viewValue: '승인대기'},
    {value: '승인완료', viewValue: '승인완료'},
    {value: '사용정지', viewValue: '사용정지'}
  ];

  // loaing overlay
  loadingOverlayComponent = "CustomLoadingOverlayComponent";
  noRowsOverlayComponent = "CustomNoRowsOverlayComponent";

  errorMessage: string = "";
  search: any = {};
  urlName: string = "";

  _SearchItems: any;

  title = 'app';

  columnDefs = [
    { headerName: '', field: '', sortable: false, filter: false, editable: false, resizable: false, checkboxSelection: true, width: 48, headerCheckboxSelection: false },
    { headerName: '상태', field: 'status', sortable: true, filter:true, editable:true, width: 127, resizable: false,
      cellRenderer: "DropDownListRendererComponent",
      cellRendererParams: {values: this.values, popUpTarget: 'cert'}
    },
    { headerName: '사용자명', field: 'userName', sortable: true, filter: true, editable: false, width: 130, resizable: true },
    { headerName: '비밀번호', field: 'password', sortable: true, filter: true, editable: false, hide: true },
    { headerName: '사이트ID', field: 'siteId', hide: true },
    { headerName: '전화번호', field: 'telno', sortable: true, editable: false, filter: true, width: 150, resizable: true },
    { headerName: '이메일', field: 'email', sortable: true, editable: false, filter: true, width: 210, resizable: true },
    { headerName: '조직명', field: 'comment', sortable: true, filter: true, editable: false, width: 220, resizable: true },
    { headerName: '사용자ID', field: 'userId', sortable: true, filter: true, editable: false, width: 130, resizable: true },
    { headerName: '최근 접속일', field: 'lastLoginDt', sortable: true, filter: true, editable: false, width: 190, resizable: true, valueFormatter: this.dateFormat },
    { headerName: '잠금 해제', field: 'initPwd', sortable: true, filter: true, editable: false, width: 120, resizable: true,
      cellRenderer: function(params) {
        if(params.node.data.loginFailCount > 2) {
          return '&nbsp;&nbsp;&nbsp;<span style="cursor:pointer"><i class="material-icons">lock_open</i></span>'
        }
      }
    },
    { headerName: '유저 유형', field: 'userType', hide: true },
    { headerName: 'SITE GROUP', field: 'siteGroup', hide: true },
    { headerName: 'CRUD유형', field: 'cls', hide: true },
    { headerName: '활성화여부', field: 'sysFlag', hide: true },
    { headerName: 'Token', field: 'token', hide: true }
  ];

  frameworkComponents = {
    PopUpRendererComponent : PopUpRendererComponent,
    DropDownListRendererComponent : DropDownListRendererComponent,
    CustomLoadingOverlayComponent : CustomLoadingOverlayComponent,
    CustomNoRowsOverlayComponent : CustomNoRowsOverlayComponent
  };

  rowData: any;

  defaultColDef = {
    //editable: true,
    resizable: true
  };

  /** mdm ctor */
  constructor(public http: HttpClient, private _userService: UserService, public datepipe: DatePipe, private router: Router, private _encryptService: EncryptService) {
    CertificationComponent.certComp = this;

    this.gridOptions = <GridOptions>{
    context: {
      componentParent: this
      },
      pagination: true,
      paginationAutoPageSize: true
    };
    //this.onAddRow();
    this.gridOptions.columnDefs = this.columnDefs;
    // row height size setting
    this.getRowHeight = function(params) { return 46; };
  }
  ngOnInit() {
    // Grid 데이터 가져오기
    //this.getAllValues();

    //20190312_검색조건 초기화
    this.setDefaultSearchItems();

    //this.onSearchBoxVisible();
    document.getElementById('searchBtn').textContent = "search";
    document.getElementById('searchBtn').style.color = "#2a80b9";
    document.getElementById('searchBtn').style.display = "block";
    document.getElementById('searchSpot').innerHTML = "";
    document.getElementById('searchSpot').append(document.getElementById("_MFF_SearchText"));
  }

  public methodFromParent(cell) {
    //console.log(`methodFromParent: ${cell[0]}`);
    let cellAry : string[];
    cellAry = cell.split(";");
    // console.log("cellAry : " + cellAry + "\n cellAry[0] : " + cellAry[0] + "\n cellAry[2] : " + cellAry[2]);
    this.setSelectedRowForStatus(cellAry[0], cellAry[2]);
  }

  setSelectedRowForStatus(value, rowIndex) {
    var rowNode = this.agGrid.api.getRowNode(rowIndex);
    rowNode.setDataValue('status', value);
    rowNode.setSelected(true);
  }

  //#region ag-grid의 Event 함수
  onCellValueChanged(event) {
    if(!isNullOrUndefined(event.newValue) && event.newValue != event.oldValue) {
      var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
      rowNode.setSelected(true);

      // 1은 Insert를 의미하기 때문에 Insert가 아닌데 Cell의 Value가 변화하는 경우는 Update로 간주
      if(rowNode.data.cls != 1) {
        // console.log(rowNode.data.cls);
        rowNode.setDataValue('cls', 2); // Column Key와 Value임
      }
    }
  }

  onGridReady(params) {
    CertificationComponent.certComp.gridApi = params.api;
    CertificationComponent.certComp.gridApi.hideOverlay();

    this.onInitPage();
  }

  onInitPage() {
    var param: UserData = {
      UserId : "",
      Status: "",
      UserType: "",
      UserName: "",
      Password: "",
      EncryptData: "",
      SiteId: AppComponent.appComp.loginUserSiteId,
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: sessionStorage.getItem("loginId"),
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: sessionStorage.getItem("Token"),
      Cls: 0,
      SysFlag: ""
    };

    this._userService.getValuesByParam(param).subscribe(
      data => this.rowData = data
    )
  }

  onSearchBoxVisible() {
    if(document.getElementById('_MFF_SearchText').hidden == true) {
      document.getElementById('searchBtn').textContent = "clear";
      document.getElementById('searchBtn').style.color = "#717171";
      document.getElementById('searchSpot').hidden = false;
      document.getElementById('_MFF_SearchText').hidden = false;

      var elems = document.getElementsByClassName('_BlankDiv');
      Array.prototype.filter.call(elems, function(element) {
        element.hidden = false;
      });
    } else {
      document.getElementById('searchBtn').textContent = "search";
      document.getElementById('searchBtn').style.color = "#2a80b9";
      document.getElementById('searchSpot').hidden = true;
      document.getElementById('_MFF_SearchText').hidden = true;

      var elems = document.getElementsByClassName('_BlankDiv');
      Array.prototype.filter.call(elems, function(element) {
        element.hidden = true;
      });
    }
  }

  getSelectedRows(cls): UserData[] {
    var _certList: UserData[] = new Array<UserData>();
    var selectedData = CertificationComponent.certComp.agGrid.api.getSelectedRows();
    selectedData.forEach(function(row, index) {
      if(cls != 3) {
        var certData: UserData = {
          UserId: row.userId,
          SysUserId: sessionStorage.getItem("loginId"),
          SysFlag: row.sysFlag,
          Status: row.status,
          UserType: row.userType,
          UserName: row.userName,
          Password: row.password,
          EncryptData: "",
          SiteId: row.siteId,
          Telno: row.telno,
          SiteGroup: row.siteGroup,
          Email: row.email,
          Comment: row.comment,
          LastLoginDt: row.lastLoginDt,
          NotiType: row.notiType,
          LoginFailCount: row.loginFailCount,
          PwdUpdDt: row.pwdUpdDt,
          Token: sessionStorage.getItem("Token"),
          Cls: row.cls
        };
        // console.log("1->" + row.status);
        // console.log("2->" + certData.Status);
        _certList.push(certData);
      } else {
        var certData: UserData = {
            UserId: row.userId,
            SysUserId: sessionStorage.getItem("loginId"),
            SysFlag: row.sysFlag,
            Status: row.status,
            UserType: row.userType,
            UserName: row.userName,
            Password: row.password,
            EncryptData: "",
            SiteId: row.siteId,
            Telno: row.telno,
            SiteGroup: row.siteGroup,
            Email: row.email,
            Comment: row.comment,
            LastLoginDt: row.lastLoginDt,
            NotiType: row.notiType,
            LoginFailCount: row.loginFailCount,
            PwdUpdDt: row.pwdUpdDt,
            Token: sessionStorage.getItem("Token"),
            Cls: cls
        };
        _certList.push(certData);
      }
    });

    return _certList;
  }

  getAllValues() {
    this._userService.getAllValues().subscribe(
      data => this.rowData = data.body
    )
  }

  getValueByParam(search) {
    CertificationComponent.certComp.gridApi.showLoadingOverlay();
    // this.consolelogSearchItems();

    if(search.searchText === '' && search.searchExecuteType === '' && search.searchDateType === '' && isNullOrUndefined(search.searchDate)) {
      //this.getAllValues();
      this.onInitPage();
      return;
    }

    var startDate = "";
    var endDate = "";

    if(!isNullOrUndefined(search.searchLastLoginDt)) {
      startDate = this.datepipe.transform(search.searchLastLoginDt.begin, 'yyyy-MM-dd');
      endDate = this.datepipe.transform(search.searchLastLoginDt.end, 'yyyy-MM-dd');
    }

    var param: UserData;

    if(search.searchLastLoginDt != null) {
      param = {
        UserId: "",
        SysUserId: sessionStorage.getItem("loginId"),
        SysFlag: "",
        Status: this.search.searchStatus,
        UserType: "",
        UserName: this.search.searchText,
        Password: "",
        EncryptData: "",
        SiteId: AppComponent.appComp.loginUserSiteId,
        Telno: "",
        SiteGroup: "",
        Email: "",
        Comment: "",
        NotiType: "",
        LoginFailCount: "",
        PwdUpdDt: "",
        Token: sessionStorage.getItem("Token"),
        LastLoginDt: startDate+';'+endDate,
        Cls: 0
      };
    } else {
      param = {
        UserId: "",
        SysUserId: sessionStorage.getItem("loginId"),
        SysFlag: "",
        Status: this.search.searchStatus,
        UserType: "",
        UserName: this.search.searchText,
        Password: "",
        EncryptData: "",
        SiteId: AppComponent.appComp.loginUserSiteId,
        Telno: "",
        SiteGroup: "",
        Email: "",
        Comment: "",
        NotiType: "",
        LastLoginDt: "",
        LoginFailCount: "",
        PwdUpdDt: "",
        Token: sessionStorage.getItem("Token"),
        Cls: 0
      };
    }
    this._userService.getValuesByParam(param).subscribe(
      data => this.rowData = data
    )

    setTimeout(() => {
      CertificationComponent.certComp.gridApi.hideOverlay();
    }, 1000);
  }

  saveValues() {
    var paramList = CertificationComponent.certComp.getSelectedRows(2);
    CertificationComponent.certComp.gridApi.showLoadingOverlay();
    // console.log('paramList :', paramList);

    // paramList.forEach(function (data) {
    //   console.log('USER_ID : ' + data.UserId +
    //   '\n USER_NAME : ' + data.UserName +
    //   '\n PASSWORD : ' + data.Password +
    //   '\n TELNO : ' + data.Telno +
    //   '\n EMAIL : ' + data.Email +
    //   '\n COMMENT : ' + data.Comment +
    //   '\n STATUS : ' + data.Status);
    // });

    //로그인한 사용자의 SITE_GROUP 값이 'A'일 경우에만 저장 진행, 그 외의 값은 저장 못하도록 조치
    if(AppComponent.appComp.loginUserSiteGroup == "A") {
      this._userService.saveValuesByParams(paramList).subscribe(
        data => {
          //QUERY 에러 때문에 저장 안되는 현상 NOTI를 위한 로직
          if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
            //SAVE Query Fail
            //alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
            alert("저장 처리 중 오류가 발생했습니다.");
          } else {
            //SAVE Query Success
            data;
            //this.refresh();
          }
        }
      );
    } else {
      alert("저장 권한이 없습니다.");
    }

    setTimeout(() => {
      CertificationComponent.certComp.gridApi.hideOverlay();
      // console.log("save->searchStatus: " + this.search.searchStatus);
      CertificationComponent.certComp.getValueByParam(this.search);
    }, 1000);
  }

  setSelectedRowForValue(date, rowIndex, fieldNm) {
    var rowNode = this.agGrid.api.getRowNode(rowIndex);
    rowNode.setDataValue(fieldNm, date); // Column Key와 Value임
    rowNode.setSelected(true);
  }

  refresh(): void {
    window.location.reload();
  }

  //20190312_검색조건 초기화 하기
  setDefaultSearchItems() {
    this.search.searchText = "";
    this.search.searchStatus = "";
    this.search.searchLastLoginDt = null;
  }
  consolelogSearchItems() {
    // console.log("▶ this.search.searchText =" , this.search.searchText);
    // console.log("▶ this.search.searchStatus = " , this.search.searchStatus);
    // console.log("▶ this.search.searchLastLoginDt = " , this.search.searchLastLoginDt);
  }

  onClicked(event) {
    //Click Action -> 로그인 session 유지
    AppComponent.appComp.clearSession();

    //비밀번호 초기화 버튼 클릭이벤트
    if(event.column.colId == 'initPwd' && event.data.loginFailCount > 2) {
      if(window.confirm("[ID: " + event.data.userId + ", 사용자명: " + event.data.userName + "]" + "\n" + "잠금 해제 하시겠습니까?")) {
        // 패스워드 암호화 적용
        var encryptData = this._encryptService.getEncryptedData("new1234!");

        var param: UserData = {
          UserId : event.data.userId,
          Status: "",
          UserType: "",
          UserName: "",
          Password: encryptData.AESKeyUsingRSA,
          EncryptData: encryptData.DataUsingAES,
          SiteId: "",
          Telno: "",
          SiteGroup: "",
          Email: "",
          Comment: "",
          SysUserId: sessionStorage.getItem("loginId"),
          LastLoginDt: "",
          NotiType: "",
          LoginFailCount: "",
          PwdUpdDt: "",
          Token: sessionStorage.getItem("Token"),
          Cls: 2,
          SysFlag: ""
        };

        this._userService.initializationPassword(param).subscribe(
          data => {
            CertificationComponent.certComp.gridApi.showLoadingOverlay();
            if(JSON.parse(JSON.stringify(data)).Result.indexOf("success") != -1) {
              //성공
              alert("비밀번호 초기화가 완료되었습니다." + "\n초기화된 비밀번호는 new1234! 입니다.");
            } else {
              //실패
              alert(JSON.parse(JSON.stringify(data)).Result);
            }

            setTimeout(() => {
              CertificationComponent.certComp.gridApi.hideOverlay();
              this.onInitPage();
            }, 1000);
          }
        )
      }
    }
  }
  dateFormat(dateParams) {
    if (dateParams.value == null) return "";

    var date = new Date(dateParams.value);
    var dateString = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0") + " " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + ":" + date.getSeconds().toString().padStart(2, "0")

    return dateString;
  }
}
