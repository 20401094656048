import { Component, OnDestroy } from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-cell-edit-change-renderer',
  template: `{{valueChanged()}}`,
  styles: []
})
export class CellEditChangeRendererComponent implements ICellRendererAngularComp{
  private params: any;
  private changedValue: string;

  // called on init
  agInit(params: any): void {
      // this.params = params;
      // console.log('CellEditChangeRendererComponent params ', this.params.value);    
      // this.changedValue = this.params.value  + "_test";
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
      this.params = params;
      console.log('CellEditChangeRendererComponent res params ', this.params.value);          
      this.changedValue = this.params.value  + "_test";
      return true;
  }

  public valueChanged(): string {
      return this.changedValue;
  }
}
