import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatDialog, MatMenuTrigger } from '@angular/material';
import { SidenavListComponent } from '../sidenav-list/sidenav-list.component';
import { isNullOrUndefined } from 'util';

import { PopUpDialogLoginComponent } from '../../common/popup/pop-up-dialog-login.component';
import { PopUpDialogUserInfoChgComponent } from '../../common/popup/pop-up-dialog-userInfoChg.component';
import { PopUpDialogPwdChgComponent } from '../../common/popup/pop-up-dialog-pwdChg.component';

import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() public sidenavClose = new EventEmitter();
  @Output() public selectedTabChange: EventEmitter<MatTabChangeEvent>;
  @Output() loginSuccess = new EventEmitter<string>();
  @Output() loginUserSiteId = new EventEmitter<string>();
  @Output() loginUserSiteGroup = new EventEmitter<string>();

  //public sidenavListComponent: SidenavListComponent = new SidenavListComponent();
  private status: boolean;
  private mouseClick: boolean;
  private tabIndex: number = 0;
  private selectedTabIndex: number = 0;

  public loginUser : string = "로그인";
  public loginUserId : string;
  public setIcon : string = "";

  static matDial: MatDialog = null;
   
  constructor(public dialog: MatDialog, private _router: Router, private _userService: UserService) { 
    HeaderComponent.matDial = dialog;
  } 

  ngOnInit() { 
    //로그인 이후 새로고침 할 경우, 로그인 유지를 위한 로직
    if(this.loginUser == "로그인" && 
      !isNullOrUndefined(sessionStorage.getItem("loginId")) && 
      sessionStorage.getItem("loginId") != '') {
        //로그인한 사용자 ID 세팅
        this.loginUser = sessionStorage.getItem("loginId") +" 님";
        this.loginUserId = sessionStorage.getItem("loginId");
        
        //아이콘 세팅
        this.setIcon = "account_circle";

        //SITE_ID, SITE_GROUP 조회 및 AppComp 변수로 세팅
        this.getUserValueById(sessionStorage.getItem("loginId"));
    }

  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  tabClick(event) {
    switch(this.selectedTabIndex) {
      case 0: //DashBoard
      this.sidenavToggle.emit(this.selectedTabIndex);
      this._router.navigate(['/home']);
      break;
      case 1: //Governor
      case 2: //Configuration
      this.sidenavToggle.emit(this.selectedTabIndex);  
      break;
    }
  }

  selectedIndexChange(index: number) {
    this.selectedTabIndex = index;
  }

  openUserManageDialog(event) {
    if(this.loginUser == "로그인") {
      //로그인 전
      this.trigger.closeMenu();

      const dialogRef = HeaderComponent.matDial.open(PopUpDialogLoginComponent, {
        width: '400px', height : '385px',
        // data: this.params.value
      });
      
      //로그인 완료
      dialogRef.afterClosed().subscribe(result => {
        if(!isNullOrUndefined(result)) {
          sessionStorage.setItem("loginId", result.split("|")[0]);
          sessionStorage.setItem("SiteId", result.split("|")[1]);
          sessionStorage.setItem("SiteGroup", result.split("|")[2]);
          sessionStorage.setItem("Token", result.split("|")[3]);

          this.loginUserSiteId.emit(result.split("|")[1].toString());
          this.loginUserSiteGroup.emit(result.split("|")[2].toString());
          //SITE_ID, SITE_GROUP 조회 및 AppComp 변수로 세팅
          this.getUserValueById(result);

          this.refresh();
          // this.loginUser = result +" 님";
          // this.loginUserId = result;

          // this.loginSuccess.emit(this.loginUserId);
          // this.setIcon = "account_circle";
          // this._router.navigate(['/home']); //home 화면으로 이동
        }
      });
    } else {
      //로그인 후
      this.trigger.openMenu();
    }
  }

  getUserValueById(userId) {
    if(sessionStorage.getItem("SiteId") == '' || isNullOrUndefined(sessionStorage.getItem("SiteId"))) {
    var param: UserData = {
      UserId : userId,
      Status: "",
      UserType: "",
      UserName: "",
      Password: "",
      EncryptData: "",
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: "",
      Cls: 0,
      SysFlag: "",
    };

    this._userService.getValueById(param).subscribe(data => {
      var strSiteId;
      var strSiteGroup;
      data.forEach(function (rowData) {
        strSiteId = JSON.parse(JSON.stringify(rowData)).siteId;
        strSiteGroup = JSON.parse(JSON.stringify(rowData)).siteGroup;
      })

      sessionStorage.setItem("SiteId", strSiteId.toString());
      sessionStorage.setItem("SiteGroup", strSiteGroup.toString());

      this.loginUserSiteId.emit(strSiteId.toString());
      this.loginUserSiteGroup.emit(strSiteGroup.toString());
    });
   }
  }

  refresh(): void {
    window.location.reload();
  }

  //개인정보변경
  onUserInfoChangeDialog() {
    const dialogRef = HeaderComponent.matDial.open(PopUpDialogUserInfoChgComponent, {
      width: '430px', height : '716px',
       data: {
         _loginUserId: this.loginUserId
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!isNullOrUndefined(result)) {
        this._router.navigate(['/home']);  //home 화면으로 이동
      }
    }); 
    
  }

  //암호변경
  onPasswordChange() {
    const dialogRef = HeaderComponent.matDial.open(PopUpDialogPwdChgComponent, {
      width: '350px', height : '450px',
       data: {
         _loginUserId: this.loginUserId
       }
    });
  }

  //로그아웃
  onLogOut() {
    this.loginUser = "로그인";
    this.loginUserId = "";
    this.setIcon = "";
    this.loginSuccess.emit(); //로그아웃 시, 로그인 된 id값에서 null값으로 갱신
    sessionStorage.clear();
    this._router.navigate(['/home']); //home 화면으로 이동

    this.refresh();
  }
}
