import { Injectable, Inject } from '@angular/core';
import * as forge from 'node-forge';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class EncryptService {
  myAppUrl: string = "";

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
   }

  // 미리 만들어 놓은 Key
  private pubKey:string = "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt+gASwtS0r/TKWQIGrpw\n5MI3R1Fr7pXm8cdG/Ng8mSsYyPLvpShCgIia0kyXcEouXB54EFRyyQMh1s3lJ37/\n4Da5dWpdsAjJ0KcAdzTYOGd23crA6dYi8LYWnKaI0AkjBnnT/4JQEYEeCXFnaVWE\nfp6fXi2g/GJQAWHk2fasXycGADqXbqWuxqCO8pR+PAa27ZV9Kr62qQah6aH3dLjQ\nPjI2Ul5Zr3xP3zpAQcCHH8YkcykYypJQMzxLTokh0xbcyRDRoU27rgI2mKozcQpw\nD2CLta6VZ9/kh1W0QbSJQSYHxJEYCFn9gYskXgf12typImmzDcmFYx1L0nlLMMnA\n3QIDAQAB\n-----END PUBLIC KEY-----";

  public getEncryptedData(randomWordArray): EncryptedData {
    const pk = forge.pki.publicKeyFromPem(this.pubKey);
    
    // AES Key를 랜덤하게 만든 후에 RSA 방식으로 해당 Key를 Encrypt 해서 전송.
    // Data(ex : 패스워드)는 AES로만 Encrypt 해서 전송
    var CryptoJs = require("crypto-js");
    var randomKey = CryptoJs.lib.WordArray.random(128/8).toString().substring(0,16);
    //console.log('randomKey:', randomKey);
    
    var key = CryptoJs.enc.Utf8.parse(randomKey);
    var iv = CryptoJs.enc.Utf8.parse(randomKey);
    var encryptedData = CryptoJs.AES.encrypt(randomWordArray, key, { keySize: 128 / 8, iv: iv,
        mode: CryptoJs.mode.CBC, padding: CryptoJs.pad.Pkcs7
    });
    
    var encAESKeyUsingRSA = pk.encrypt(randomKey);
    var encAESKeyUsingRSAandEnc64 = forge.util.encode64(encAESKeyUsingRSA);

    var result:EncryptedData = {
      AESKeyUsingRSA: encAESKeyUsingRSAandEnc64.toString(),
      DataUsingAES: encryptedData.toString()
    };

    return result;
  }  

  encrypt128(param) {
    const body = JSON.stringify(param);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json'});

    let params = new HttpParams();
    params = params.append('textToEncrypt', param);
    
    return this._http.get(this.myAppUrl + 'api/Encrypt/Get/TodayNo', {
      responseType: 'text',
      params: params
    });
  }
}

export interface EncryptedData {
    AESKeyUsingRSA: string;
    DataUsingAES: string;
}