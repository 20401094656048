import { Component, Inject, ViewChild, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';
import { isNullOrUndefined } from 'util';
import { EncryptService } from '../../services/encrypt.service';

@Component({
  templateUrl: './pop-up-dialog-pwdChg.component.html',  
  styles: [`mat-form-field {
    margin-right: 12px;
  }`],
  styleUrls: ['../common.component.css']
})
export class PopUpDialogPwdChgComponent{
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private path; // Log File path
  private fileText; // Textarea
  maxRows;

  static matDial: MatDialog = null;

  public userList: UserData[];

  public password: string;
  public newPassword: string;
  public confirmPassword: string;



  constructor(
    public dialogRef: MatDialogRef<PopUpDialogPwdChgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient, private ngZone: NgZone, public dialog: MatDialog, private _userService: UserService, private _encryptService: EncryptService) {
      PopUpDialogPwdChgComponent.matDial = dialog;
    }

  ngOnInit() {
    setTimeout(() => {
      if(this.data.checkFlag != '' && !isNullOrUndefined(this.data.checkFlag)) {
        alert("비밀번호를 90일 이상 변경하지 않으셨습니다." + "\n비밀번호를 변경해주세요.");
      }
    }, 500);
  }

  // TBL_USER 데이터 추가
  onSavePwd() {

    if(this.password == '' || isNullOrUndefined(this.password)) {
      document.getElementById("inputPwd").textContent = "기존 비밀번호를 입력해주세요.";
      document.getElementById("inputPwd").style.display = "block";
      document.getElementById("inputboxPwd").focus();
      return;
    } 
    document.getElementById("inputPwd").style.display = "none";

    if(this.newPassword == '' || isNullOrUndefined(this.newPassword)) {
      document.getElementById("inputNewPwd").textContent = "신규 비밀번호를 입력해주세요.";
      document.getElementById("inputNewPwd").style.display = "block";
      document.getElementById("inputboxNewPwd").focus();
      return;
    } else {
      if(!validatePassword(this.newPassword)) {
      // if(this.newPassword.length < 8 || this.newPassword.length > 12) {
        document.getElementById("inputNewPwd").textContent = "특수문자, 숫자, 문자를 포함한 8~15자리 값을 입력해주세요.";
        // document.getElementById("inputNewPwd").textContent = "신규 비밀번호를 8~12자리로 입력해주세요.";
        document.getElementById("inputNewPwd").style.display = "block";
        document.getElementById("inputboxNewPwd").focus();
        return;
      }
      document.getElementById("inputNewPwd").style.display = "none";
    }

    if(this.confirmPassword == '' || isNullOrUndefined(this.confirmPassword)) {
      document.getElementById("inputNewPwdConfirm").textContent = "신규 비밀번호를 다시 입력해주세요.";
      document.getElementById("inputNewPwdConfirm").style.display = "block";
      document.getElementById("inputboxNewPwdConfirm").focus();
      return;
    } else {
      if(this.newPassword != this.confirmPassword) {
        document.getElementById("inputNewPwdConfirm").textContent = "비밀번호가 불일치합니다.";
        document.getElementById("inputNewPwdConfirm").style.display = "block";
        document.getElementById("inputboxNewPwdConfirm").focus();
        return;
      }
      document.getElementById("inputNewPwdConfirm").style.display = "none";
    }

    // 패스워드 암호화 적용
    var encryptData = this._encryptService.getEncryptedData(this.password);

    var param: UserData = {
      UserId : this.data._loginUserId,
      Status: "",
      UserType: "",
      UserName: "",
      Password: encryptData.AESKeyUsingRSA,
      EncryptData: encryptData.DataUsingAES,
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: "",
      Cls: 0,
      SysFlag: "",
    };

    this._userService.getValueByEncryptPw(param).subscribe(
      data => { 
        if(data.length == 0) {
          //암호 불일치
          document.getElementById("inputPwd").textContent = "입력하신 비밀번호와 기존 비밀번호가 불일치합니다.";
          document.getElementById("inputPwd").style.display = "block";
          document.getElementById("inputboxPwd").focus();
        } else {
          //암호 일치, 기존 비밀번호와 신규 비밀번호 동일 여부 체크
          
          // 패스워드 암호화 적용
          var newEncryptData = this._encryptService.getEncryptedData(this.newPassword);

          var param: UserData = {
            UserId : this.data._loginUserId,
            Status: "",
            UserType: "",
            UserName: "",
            Password: newEncryptData.AESKeyUsingRSA,
            EncryptData: newEncryptData.DataUsingAES,
            SiteId: "",
            Telno: "",
            SiteGroup: "",
            Email: "",
            Comment: "",
            SysUserId: "",
            LastLoginDt: "",
            NotiType: "",
            LoginFailCount: "",
            PwdUpdDt: "",
            Token: "",
            Cls: 0,
            SysFlag: "",
          };
      
          this._userService.getValueByEncryptPw(param).subscribe(data => {

            //쿼리 WHERE 조건이 ID와 PASSWORD 이므로, 동일한 데이터일 경우 결과 (1건) 호출되므로 length로 체크
            if(data.length == 1) {
              document.getElementById("inputNewPwd").textContent = "기존 비밀번호와 동일한 비밀번호를 사용하실 수 없습니다.";
              document.getElementById("inputNewPwd").style.display = "block";
              document.getElementById("inputboxNewPwd").focus();
            } else {
              // 패스워드 암호화 적용
              var newEncryptData2 = this._encryptService.getEncryptedData(this.newPassword);
              
              var _userList: UserData[] = new Array<UserData>();
                var param: UserData = {
                  UserId: this.data._loginUserId,
                  Status: "",
                  UserType: "",
                  UserName: "",
                  Password: newEncryptData2.AESKeyUsingRSA,
                  EncryptData: newEncryptData2.DataUsingAES,
                  SiteId: "",
                  Telno: "",
                  SiteGroup: "",
                  Email: "",
                  Comment: "",
                  SysUserId: "",
                  LastLoginDt: "",
                  NotiType: "",
                  LoginFailCount: "",
                  PwdUpdDt: "",
                  Token: sessionStorage.getItem("Token"),
                  Cls: 2,
                  SysFlag: "A",
                };

                _userList.push(param);

                this._userService.saveValueByPw(_userList).subscribe(
                  data => {
                    //QUERY 에러 때문에 저장 안되는 현상 NOTI를 위한 로직
                    if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
                      //SAVE Query Fail
                      // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
                      alert("저장 처리 중 오류가 발생했습니다.");
                    } else {
                      //SAVE Query Success
                      data;

                      //로그아웃 처리
                      sessionStorage.setItem("loginId", '');
                      alert("비밀번호가 변경되었습니다." + "\n재로그인 해주세요.");
                      this.refresh();
                    }
                  }
                );
            }
          });
        }
      }
    );      
  }  

  refresh(): void {
    window.location.reload();
  } 

  onCancel() {
    this.dialogRef.close();
  }

  public checkPwd(event) 
  {
    if(this.password == '' || isNullOrUndefined(this.password)) {
      document.getElementById("inputPwd").textContent = "기존 비밀번호를 입력해주세요.";
      document.getElementById("inputPwd").style.display = "block";
      return;
    } 
    document.getElementById("inputPwd").style.display = "none";
  }

  public checkNewPwd(event) 
  {
    if(this.newPassword == '' || isNullOrUndefined(this.newPassword)) {
      document.getElementById("inputNewPwd").textContent = "신규 비밀번호를 입력해주세요.";
      document.getElementById("inputNewPwd").style.display = "block";
      return;
    } else {
      if(!validatePassword(this.newPassword)) {
      // if(this.newPassword.length < 8 || this.newPassword.length > 12) {
        document.getElementById("inputNewPwd").textContent = "특수문자, 숫자, 문자를 포함한 8~15자리 값을 입력해주세요.";
        // document.getElementById("inputNewPwd").textContent = "신규 비밀번호를 8~12자리로 입력해주세요.";
        document.getElementById("inputNewPwd").style.display = "block";
        return;
      }
      document.getElementById("inputNewPwd").style.display = "none";
    }
  }

  public checkNewPwdConfirm(event) 
  {
    if(this.confirmPassword == '' || isNullOrUndefined(this.confirmPassword)) {
      document.getElementById("inputNewPwdConfirm").textContent = "신규 비밀번호를 다시 입력해주세요.";
      document.getElementById("inputNewPwdConfirm").style.color = "red";
      document.getElementById("inputNewPwdConfirm").style.display = "block";
    } else {
      if(this.newPassword != this.confirmPassword) {
        document.getElementById("inputNewPwdConfirm").textContent = "비밀번호가 불일치합니다.";
        document.getElementById("inputNewPwdConfirm").style.color = "red";
        document.getElementById("inputNewPwdConfirm").style.display = "block";
      } else {
        document.getElementById("inputNewPwdConfirm").textContent = "비밀번호가 일치합니다.";
        document.getElementById("inputNewPwdConfirm").style.color = "green";
        document.getElementById("inputNewPwdConfirm").style.display = "block";
      }
    }
  }

}

// 비밀번호 정규식 적용 (특수문자, 숫자, 문자 최소 1자리 입력되어야 하며 최소 8자리 입력)
function validatePassword(character) {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/.test(character)
}