import { Component, Inject, ViewChild, NgZone, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { PopUpDialogJoinComponent } from '../../common/popup/pop-up-dialog-join.component';
import { PopUpDialogPwdResetComponent } from '../../common/popup/pop-up-dialog-pwdReset.component';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';
import { isNullOrUndefined } from 'util';
import { EncryptService } from '../../services/encrypt.service';

@Component({
  templateUrl: './pop-up-dialog-login.component.html',
  styles: [`
  mat-form-field {
    margin-right: 12px;
  }
  .mat-raised-button {
    padding: 0px 20px !important;
  }
  `],
  styleUrls: ['../common.component.css']
})
export class PopUpDialogLoginComponent{
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private path; // Log File path
  private fileText; // Textarea
  maxRows;

  static matDial: MatDialog = null;
  public userId : string;
  public password : string;
  public stateMessage : string;

  public userId_db : string;
  public password_db : string;

  public capslockOn : boolean;

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient, private ngZone: NgZone, public dialog: MatDialog, private _userService: UserService, private _encryptService: EncryptService) {
      PopUpDialogLoginComponent.matDial = dialog;
    }

    @HostListener('window:keydown', ['$event']) onKeyDown(event){
      if (event.getModifierState && event.getModifierState('CapsLock')) {
        this.capslockOn = true;
        } else {
          this.capslockOn = false;
        }
      }
    
      @HostListener('window:keyup', ['$event']) onKeyUp(event){
      if (event.getModifierState && event.getModifierState('CapsLock')) {
        this.capslockOn = true;
        } else {
          this.capslockOn = false;
        }
      }

  ngOnInit() {
  }

  // 로그인 버튼
  onClick() {

    var loginFailCountDt; //로그인실패횟수

    //사이트 접속하는 Web Browser 체크 (IE)
    const agent = window.navigator.userAgent.toLowerCase();
    if(agent.indexOf('trident') > -1) {
      alert("Internet Explorer는 지원하지 않습니다.");
      return;
    }

    //ID, PW 입력 확인 로직
    if(this.userId == '' || isNullOrUndefined(this.userId)) {
      document.getElementById("inputId").textContent = "아이디를 입력해주세요.";
      document.getElementById("inputId").style.display = "block";
      document.getElementById("inputboxId").focus();
      return;
    } else {
      document.getElementById("inputId").style.display = "none";
    }
    if(this.password == '' || isNullOrUndefined(this.password)) {
      document.getElementById("inputPwd").textContent = "비밀번호를 입력해주세요.";
      document.getElementById("inputPwd").style.display = "block";
      document.getElementById("inputboxPwd").focus();
      return;
    } else {
      document.getElementById("inputPwd").style.display = "none";
    }

   /* ********************************* 
    * 로그인실패횟수 체크 로직 START
    *  - 시큐어 코딩 (보안: 계정잠금) 구현
    *  - LOGIN을 실패하면 LOGIN_FAIL_COUNT (TBL_USER) 의 값이 1씩 증가 (Default: 0)
    *  - LOGIN_FAIL_COUNT 가 3인 아이디로 접속 시도할 경우 사용자 계정 잠김 알림, 승인 관리에서 해제 가능
    *  - 비밀번호 재설정 처리하면서 LOGIN_FAIL_COUNT 0으로 초기화
    * *********************************/    
    var param: UserData = {
      UserId : this.userId,
      Status: "",
      UserType: "",
      UserName: "",
      Password: "",
      EncryptData: "",
      SiteId: "",
      Telno: "",
      SiteGroup: "",
      Email: "",
      Comment: "",
      SysUserId: "",
      LastLoginDt: "",
      NotiType: "",
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: "",
      Cls: 0,
      SysFlag: "",
    };

    this._userService.getValueById(param).subscribe(data => {
      var loginFailCountData ;
      if(data.length < 1) {
        document.getElementById("inputId").textContent = "아이디를 확인해주세요.";
        document.getElementById("inputId").style.display = "block";
        document.getElementById("inputboxId").focus();        
      } else {
        data.forEach(function (rowData) {
          loginFailCountData = JSON.parse(JSON.stringify(rowData)).loginFailCount;
        });

        loginFailCountDt = +loginFailCountData;
        if(loginFailCountDt > 2) {
          document.getElementById("inputId").textContent = "계정이 잠긴 사용자입니다. 관리자에게 문의해주세요.";
          document.getElementById("inputId").style.display = "block";
          document.getElementById("inputboxId").focus();

          // LOGIN 3회 실패 시, 비밀번호 초기화 팝업 호출
          //   --> 승인 관리 화면에서 비밀번호 초기화 버튼 추가하는 것으로 대체
          // const dialogRef = PopUpDialogLoginComponent.matDial.open(PopUpDialogPwdResetComponent, {
          //   width: '390px', height : '400px',
          //   data: {
          //     _loginUserId: this.userId
          //   }
          // });

        } else {
          /* ********************************* 
            * 로그인 체크 및 처리 로직 START
            * *********************************/    

          // 패스워드 암호화 적용
          var encryptData = this._encryptService.getEncryptedData(this.password);

          var param: UserData = {
            UserId : this.userId,
            Status: "",
            UserType: "",
            UserName: "",
            Password: encryptData.AESKeyUsingRSA,
            EncryptData: encryptData.DataUsingAES,
            SiteId: "",
            Telno: "",
            SiteGroup: "",
            Email: "",
            Comment: "",
            SysUserId: "",
            LastLoginDt: "",
            NotiType: "",
            LoginFailCount: "",
            PwdUpdDt: "",
            Token: "",
            Cls: 0,
            SysFlag: "",
          };

          this._userService.getValueByEncryptPw(param).subscribe(
            data => { 
              if(data.length == 0) {
                //로그인 실패
                //this.stateMessage = "ID 및 PASSWORD를 확인해주세요.";
                document.getElementById("inputId").textContent = "아이디를 확인해주세요.";
                document.getElementById("inputPwd").textContent = "비밀번호를 확인해주세요.";
                document.getElementById("inputId").style.display = "block";
                document.getElementById("inputPwd").style.display = "block";
                document.getElementById("inputboxId").focus();

                /* ********************************* 
                * 로그인실패횟수 추가 로직 START
                * *********************************/
                var _userList: UserData[] = new Array<UserData>();
                var param: UserData = {
                  UserId : this.userId,
                  Status: "",
                  UserType: "",
                  UserName: "",
                  Password: "",
                  EncryptData: "",
                  SiteId: "",
                  Telno: "",
                  SiteGroup: "",
                  Email: "",
                  Comment: "",
                  SysUserId: "",
                  LastLoginDt: "",
                  NotiType: "",
                  LoginFailCount: "",
                  PwdUpdDt: "",
                  Token: "",
                  Cls: 2,
                  SysFlag: "",
                };

                _userList.push(param);

                //로그인실패횟수 업데이트(LOGIN_FAIL_COUNT)
                this._userService.updateByLoginFailCount(_userList).subscribe(data => data);
                /* ********************************* 
                * 로그인실패횟수 추가 로직 END
                * *********************************/
              } else {
                var strStatus;
                var strSiteId;
                var strSiteGroup;
                data.forEach(function(loginData) {
                  strStatus = JSON.parse(JSON.stringify(loginData)).status;
                  strSiteId = JSON.parse(JSON.stringify(loginData)).siteId;
                  strSiteGroup = JSON.parse(JSON.stringify(loginData)).siteGroup;
                });

                //사용정지(STATUS: D) 사용자는 접속 불가
                if(strStatus == "D") {
                  document.getElementById("inputId").textContent = "사용정지 사용자입니다.";
                  document.getElementById("inputId").style.display = "block";
                  document.getElementById("inputboxId").focus();
                } else if (strStatus == "R") {
                  document.getElementById("inputId").textContent = "승인대기 사용자입니다.";
                  document.getElementById("inputId").style.display = "block";
                  document.getElementById("inputboxId").focus();
                } else {
                  //로그인 성공
                  var _userList: UserData[] = new Array<UserData>();
                  var param: UserData = {
                    UserId : this.userId,
                    Status: "",
                    UserType: "",
                    UserName: "",
                    Password: "",
                    EncryptData: "",
                    SiteId: "",
                    Telno: "",
                    SiteGroup: "",
                    Email: "",
                    Comment: "",
                    SysUserId: "",
                    LastLoginDt: "",
                    NotiType: "",
                    LoginFailCount: "0",
                    PwdUpdDt: "",
                    Token: "",
                    Cls: 2,
                    SysFlag: "",
                  };

                  _userList.push(param);

                  //로그인 최종시간 업데이트 (LAST_LOGIN_DT) + 로그인실패횟수 초기화(LOGIN_FAIL_COUNT) + TOKEN 업데이트(TOKEN)
                  var strToken;
                  this._userService.updateByLastLoginDt(_userList).subscribe(data => {
                    strToken = data["Token"];

                    this.dialogRef.close(this.userId + "|" + strSiteId + "|" + strSiteGroup + "|" + strToken);
                  });

                }
              }
            }
          );
        /* ********************************* 
          * 로그인 체크 및 처리 로직 END
          * *********************************/ 
        }
    }
  });
 /* ********************************* 
  * 로그인실패횟수 체크 로직 END
  * *********************************/    
}  

  onCancel() {
    this.dialogRef.close();
  }

  onJoin() {
    const dialogRef = PopUpDialogLoginComponent.matDial.open(PopUpDialogJoinComponent, {
      width: '430px', height : '716px',
      // data: this.params.value
    });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   if(!isNullOrUndefined(result)) {
    //     // this.setRobotResult(result);
    //     // this.params.context.componentParent.methodFromParent(`${result[0]};${this.params.fieldNm};${this.params.node.id};${result[1]};`)
    //   }
    // });              
  

    this.dialogRef.close();
  }
}