import { Component } from '@angular/core';

@Component({
    selector: 'app-governor-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss']
})
/** site component*/
export class ScheduleComponent {
    /** site ctor */
    constructor() {

    }
}
