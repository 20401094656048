import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from "@angular/platform-browser";

import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';

// Bootstrap Vesion
import { setTheme } from 'ngx-bootstrap/utils';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';

import { PopUpDialogPwdChgComponent } from './common/popup/pop-up-dialog-pwdChg.component';
import { MatDialog } from '@angular/material';
import { UserService } from './services/user.service';
import { UserData } from './models/model.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
  @ViewChild(HeaderComponent) _headerComp;
  @ViewChild(SidenavListComponent) _sidenavListComp;

  title = 'ClientApp';

  public loginUserId: string = "";
  public loginUserSiteId: string;
  public loginUserSiteGroup: string;
  public sidenavWidth = (mobileAccess() == true) ? 15 : 15;
  public sidenavSize = (mobileAccess() == true) ? 15 : 15;
  static appComp;
  public timeoutData;
  
  static matDial: MatDialog = null;

  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher, private _router: Router, public dialog: MatDialog, private _userService: UserService) {
    AppComponent.appComp = this;
    AppComponent.matDial = dialog;
    
    this.matIconRegistry.addSvgIcon("logo_white", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/logo_white.svg"));

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    setTheme('bs4');
  }

  ngOnInit() {
    const agent = window.navigator.userAgent.toLowerCase();
    if(agent.indexOf('trident') > -1) {
      alert("Internet Explorer는 지원하지 않습니다.");
      return;
    }

    //로그인 도중 새로고침 할 경우, 로그인 데이터(로그인ID, SITE_ID) 유지를 위한 로직
    if(!isNullOrUndefined(sessionStorage.getItem("loginId")) && sessionStorage.getItem("loginId") != '') {
      AppComponent.appComp.loginUserSiteId = sessionStorage.getItem("SiteId");
      AppComponent.appComp.loginUserSiteGroup = sessionStorage.getItem("SiteGroup");
    } else {
      AppComponent.appComp.loginUserSiteId = "";
      AppComponent.appComp.loginUserSiteGroup = "";
      this._router.navigate(['/home']);   
    }

    //로그인 세션 유지시간 설정
    this.clearSession();

    //비밀번호 변경주기 체크
    this.changePasswordChk();    
  }

  /*
   * 로그인 세션 유지시간 설정 (30분)
   * sidenav item 클릭할 경우 시간 리셋
   */
  clearSession() {
    if(sessionStorage.getItem("loginId") != '' && !isNullOrUndefined(sessionStorage.getItem("loginId"))) {
      clearTimeout(this.timeoutData);
      
      this.timeoutData = setTimeout(() => {
        //세션유지시간 사이에 로그아웃하는 경우 대비 (로그아웃 시, reload 기능 넣을 경우 없어도 되는 제어문)
        if(sessionStorage.getItem("loginId") != '' && !isNullOrUndefined(sessionStorage.getItem("loginId"))) { 
          alert("로그인 유지시간이 만료되었습니다.");
          sessionStorage.clear();
          window.location.reload();
        }
      }, 1800000); //1800000 : 30분
    }
    
  }

  /*
   * 비밀번호 변경주기 체크
   * 3개월(90일) 기준으로 변경 팝업 호출
   * 로그인 완료 시점에 호출
   */
  changePasswordChk() {
    //로그인
    if(sessionStorage.getItem("loginId") != '' && !isNullOrUndefined(sessionStorage.getItem("loginId"))) {

      /* *******************************
       *  날짜 비교 로직 START
       * *******************************/
      var param: UserData = {
        UserId : sessionStorage.getItem("loginId"),
        Status: "",
        UserType: "",
        UserName: "",
        Password: "",
        EncryptData: "",
        SiteId: "",
        Telno: "",
        SiteGroup: "",
        Email: "",
        Comment: "",
        SysUserId: "",
        LastLoginDt: "",
        NotiType: "",
        LoginFailCount: "",
        PwdUpdDt: "",
        Token: "",
        Cls: 0,
        SysFlag: "",
      };
  
      this._userService.getValueById(param).subscribe(data => {
        var strPwdChgDt;
        data.forEach(function (rowData) {
          strPwdChgDt = JSON.parse(JSON.stringify(rowData)).pwdUpdDt;
        })
        var todayDt = new Date();
        var diffDay = (todayDt.getTime() - new Date(strPwdChgDt.split(" ")[0]).getTime()) / (1000*60*60*24);
        var diffDay = Math.floor(diffDay); // 소수점 버림
        // console.log("diff : [" + diffDay + "]");

        if(+diffDay > 89) {
          /* *******************************
          *  90일 경과 로직 START 
          * *******************************/
          //alert("비밀번호를 90일 이상 변경하지 않으셨습니다." + "\n비밀번호를 변경해주세요.");
          const dialogRef = AppComponent.matDial.open(PopUpDialogPwdChgComponent, {
            width: '390px', height : '430px',
            data: {
              _loginUserId: sessionStorage.getItem("loginId"),
              checkFlag: "Y"
            }
          });
          /* *******************************
          *  90일 경과 로직 END 
          * *******************************/
        }
      });
      /* *******************************
       *  날짜 비교 로직 END
       * *******************************/
    }
  }

  loginIdValue(event) {
    /* 넘어오는 event값 = 로그인ID
     *   - null일 경우, 로그아웃 신호
     *   - null이 아닐 경우, 로그인 신호
     */
    if(event == '' || isNullOrUndefined(event)) {
      this.loginUserId = '';
    } else {
      this.loginUserId = event.toString();
    }

    sessionStorage.setItem("loginId", this.loginUserId); //새로고침 할 경우 대비, 로그인 ID 유지
    this._sidenavListComp.loginUserId = this.loginUserId;
  }

  //넘어오는 event값 = 로그인한 사용자의 SITE_ID
  loginUserSiteIdVal(event) {
    sessionStorage.setItem("SiteId", event.toString()); //새로고침 할 경우 대비, 로그인 유저의 SITE_ID 값 유지
    AppComponent.appComp.loginUserSiteId = event.toString();
  }

  //넘어오는 event값 = 로그인한 사용자의 SITE_GROUP
  loginUserSiteGroupVal(event) {
    sessionStorage.setItem("SiteGroup", event.toString()); //새로고침 할 경우 대비, 로그인 유저의 SITE_ID 값 유지
    AppComponent.appComp.loginUserSiteGroup = event.toString();
  }

  //SIDE NAVIGATION 조절 (마우스 접근 시, SIZE 증가 : 15)
  sidenavWidthIncrease() {
    if(this.sidenavSize != 0 && !isNullOrUndefined(this.sidenavSize)) {
      this.sidenavWidth = this.sidenavSize;
    } else {
      this.sidenavWidth = 15;
    }
    
  }

  //SIDE NAVIGATION 조절 (마우스가 SIDE BAR에서 나갈 경우, SIZE 감소 : 6, ICON만 표기)
  sidenavWidthDecrease() {
    if(this.sidenavSize != 0 && !isNullOrUndefined(this.sidenavSize)) {
      this.sidenavWidth = this.sidenavSize;
    } else {
      this.sidenavWidth = 6;
    }
  }
  
  //SIDE NAVIGATION 조절 (초기 세팅 및 화면 전환 시, SIZE 6(Icon만 표기) 으로 고정)
  sidenavSetting(sidenav) {
    if(mobileAccess() == true) {
      //모바일
      sidenav.close();
    } else {
      //PC
      this.sidenavWidth = 6; 
    }
    
  }

  //SIDE NAVIGATION 조절 (SIDENAV 에서 넘어오는 값을 받아서 SIDENAV WIDTH와 동일한 싱크 맞추도록 조치)
  sidenavSizeSetting(event) {
    // if(parseInt(event) != 0) {
    //   this.sidenavSize = parseInt(event);
    // }
    
  }

  setSidenavToggle(sidenav, idx) {
    if(idx == 0) { //DashBoard
      if(sidenav._opened == false) {
        sidenav.toggle();
      }

      if(mobileAccess() == true) {
        //모바일(태블릿), 메뉴 닫기
        this._sidenavListComp.step = -1;
        this._sidenavListComp.isExpanded = true;
        sidenav.close();
      } else {
        //PC, 메뉴 최소화
        this._sidenavListComp.step = -1;
        this._sidenavListComp.sidenavWidthDecrease();
        this.sidenavWidth = 6;
      }

    } else if(idx == 1) {  //Governor
      //menu가 close 상태일 경우, open
      if(sidenav._opened == false) {
        sidenav.toggle();
      }

      if(this.sidenavWidth == 15 && this._sidenavListComp.step == 0) {
        if(mobileAccess() == true) {
          //모바일(태블릿)
          this._sidenavListComp.step = -1;
          this._sidenavListComp.sidenavWidthDecrease();
          this.sidenavWidth = 6;
          sidenav.close();
        } else {
          //PC
          //이미 열려있는 경우, 다시 아이콘화
          this._sidenavListComp.step = -1;
          this._sidenavListComp.sidenavWidthDecrease();
          this.sidenavWidth = 6;
        }
        
      } else {
        //아이콘 상태에서 Governor 메뉴만 열기
        this._sidenavListComp.step = 0;
        this._sidenavListComp.sidenavWidthIncrease();
        this.sidenavWidth = 15;
      }

    } else if(idx == 2) {   //Configuration
      //menu가 close 상태일 경우, open
      if(sidenav._opened == false) {
        sidenav.toggle();
      }

      if(this.sidenavWidth == 15 && this._sidenavListComp.step == 1) {
        if(mobileAccess() == true) {
          //모바일(태블릿)
          this._sidenavListComp.step = -1;
          this._sidenavListComp.sidenavWidthDecrease();
          this.sidenavWidth = 6;
          sidenav.close();
        } else {
          //PC, 이미 열려있는 경우, 다시 아이콘화
          this._sidenavListComp.step = -1;
          this._sidenavListComp.sidenavWidthDecrease();
          this.sidenavWidth = 6;
        }

      } else {
        //아이콘 상태에서 Governor 메뉴만 열기
        this._sidenavListComp.step = 1;
        this._sidenavListComp.sidenavWidthIncrease();
        this.sidenavWidth = 15;
      }

    } else {
      if(mobileAccess() == true) {
        this._sidenavListComp.sidenavWidthIncrease();
        this.sidenavWidth = 15;
      } 

      sidenav.toggle();
    }
  }
}

//모바일 접근 여부 확인
function mobileAccess() {
  var userIntmType = window.navigator.userAgent.toLowerCase();
    if ( /iphone/.test(userIntmType) || /android/.test(userIntmType) || /opera/.test(userIntmType) || /bada/.test(userIntmType) ) {
    //모바일
    return true;
  } else {
    //PC
    return false;
  }
}