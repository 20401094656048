import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";

@Component({
    selector: 'app-no-rows-overlay',
    template: `<div align="center"><h1>No Data To Show</h1></div>`
              // `<div class="ag-overlay-loading-center" style="background-color: lightcoral; height: 9%">` +    
              // `   <i class="far fa-frown"> {{this.params.noRowsMessageFunc()}} </i>` +
              // `</div>`
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  private params: any;

  agInit(params): void {
      this.params = params;
  }
}
// assets\images
//               `<img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">`+

//template: `<div align="center"><img mat-card-image src="assets/images/NoDataAvailable.png" width="100" height="100"></div>`
// `<div class="ag-overlay-loading-center" style="background-color: lightcoral; height: 9%">` +    
// `   <i class="far fa-frown"> {{this.params.noRowsMessageFunc()}} </i>` +
// `</div>`