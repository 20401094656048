import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-params-renderer',
  template: `Field: {{params.colDef.field}}, Value: {{params.value}}`,
  styles: []
})
export class ParamsRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
      this.params = params;
  }

  refresh(): boolean {
      return false;
  }

}
