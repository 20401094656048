import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule, MatCardModule, MatTableModule, MatGridListModule, MatTabsModule, MatSidenavModule, MatToolbarModule, MatIconModule,
  MatButtonModule, MatListModule, MatMenuModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgGridModule } from 'ag-grid-angular';
import { GovernorRoutingModule } from './governor-routing.module';
import { GovernorComponent } from './governor.component';
import { CertificationComponent } from './certification/certification.component';
import { CertificationExceptComponent } from './certification-except/certificationExcept.component';
import { IllegalUserComponent } from './illegal-user/illegalUser.component';
import { ContractComponent } from './contract/contract.component';
import { SiteCrtfdRegComponent } from './sitecrtfdreg/sitecrtfdreg.component';
import { CrtfdHstComponent } from './crtfdhst/crtfdhst.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Datepicker
import { DatepickerComponent } from '../style/datepicker/datepicker.component';

import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

// ngx-bootstarp
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

//20190312_PROJECT화면 검색조건 추가
import { FormGroup, FormControl } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MatSelectModule } from '@angular/material';

@NgModule({
  imports: [
    GovernorRoutingModule,
    FlexLayoutModule,
    CommonModule,
    AgGridModule.withComponents([
      DatepickerComponent
    ]),
    MatExpansionModule, MatCardModule, MatTableModule, MatGridListModule,MatTabsModule, MatSidenavModule, MatToolbarModule,
    MatIconModule, MatButtonModule, MatListModule, MatMenuModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule
    , BrowserAnimationsModule
    , SatDatepickerModule, SatNativeDateModule
    , FormsModule, ReactiveFormsModule
    , BsDatepickerModule.forRoot()
    ,SelectDropDownModule //20190312_PROJECT화면 검색조건 추가
    ,MatSelectModule      //20190312_PROJECT화면 검색조건 추가
  ],
  declarations: [
    GovernorComponent,
    CertificationComponent,
    CertificationExceptComponent,
    IllegalUserComponent,
    ContractComponent,
    SiteCrtfdRegComponent,
    CrtfdHstComponent,
    ScheduleComponent,
    DatepickerComponent

  ],
  entryComponents: [

  ],
  providers: [CertificationComponent, CertificationExceptComponent, IllegalUserComponent, ContractComponent, SiteCrtfdRegComponent, CrtfdHstComponent, BsDatepickerConfig, DatePipe]
})
export class GovernorModule {

}
