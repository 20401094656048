import { Component, NgModule, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-work-cls-renderer',
  template: `
            <input matInput [(ngModel)]="pwd" class="pwd">
          `,
    styleUrls: ['./common.component.css']
})
export class PassWordRendererComponent implements INoRowsOverlayAngularComp {

  private params: any;
  private pwd: string;

  agInit(params: any): void {
      this.params = params;  
      this.pwd  = params.value;      
      //console.log(`params.value: ${params.value} :  ${this.params.fieldNm} : ${this.pwd}`);
      let popupTarget = this.params.popUpTarget;

      if(popupTarget == 'meta') {
        var strPwData = this.params.context.componentParent.methodFromParentForMetaData(`${this.params.node.id}`);
        var aryMetaData = new Array();

        //[0]: META_TYPE, [1]: META_VALUE
        aryMetaData = strPwData.split("/");
        
        //<input id="pwd" matInput type="password"  [(ngModel)]="pwd">
        if(aryMetaData[0] == "PASSWORD") {
          var inputTag = document.getElementsByClassName('pwd');
          var data1 = this.params.node.id;
          inputTag[data1].setAttribute('type', 'password');
        }
        
      } else {
        var inputTag = document.getElementsByClassName('pwd');
        inputTag[this.params.node.id].setAttribute('type', 'password');
      }

  }

  refresh(params: any): boolean {
    //console.log(`this.value: ${this.value}`);
    //this.params.context.componentParent.methodFromParent(`${this.params.fieldNm};${this.params.node.id};`) 
    
    //alert("click delete row"+ `${this.value};${this.params.fieldNm};${this.params.node.id};`);
 
    if(this.params.popUpTarget == 'meta') {
      var strPwData = this.params.context.componentParent.methodFromParentForMetaData(`${this.params.node.id}`);
      var aryMetaData = new Array();
      aryMetaData = strPwData.split("/");

      if(aryMetaData[0] == "PASSWORD") {
        this.pwd = "";
        for(var i = 0;i < aryMetaData[1].length; i++) {
          this.pwd += '*';
        }
      } else {
        this.pwd = aryMetaData[1];  
      }
      
    }

    return true;  
  }
}
