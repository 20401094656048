import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GovernorComponent } from "./governor.component";
import { CertificationComponent } from './certification/certification.component';
import { CertificationExceptComponent } from './certification-except/certificationExcept.component';
import { IllegalUserComponent } from './illegal-user/illegalUser.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ContractComponent } from './contract/contract.component';
import { SiteCrtfdRegComponent } from './sitecrtfdreg/sitecrtfdreg.component';
import { CrtfdHstComponent } from './crtfdhst/crtfdhst.component';

const adminRoutes: Routes = [
    {
        path: 'governor',
        component: GovernorComponent,
        children: [
          { path: 'contract', component: ContractComponent, data: { title: "Governor | contract" } },
          { path: 'certification', component: CertificationComponent, data: { title: "Governor | Certification" } },
          { path: 'certification-except', component: CertificationExceptComponent, data: { title: "Governor | CertificationExcept" } },
          { path: 'illegal-user', component: IllegalUserComponent, data: { title: "Governor | IllegalUser" } },
          { path: 'schedule', component: ScheduleComponent, data: { title: "Governor | Schedule" } },
          { path: 'sitecrtfdreg', component: SiteCrtfdRegComponent, data: { title: "Governor | Sitecrtfdreg" } },
          { path: 'crtfdhst', component: CrtfdHstComponent, data: { title: "Governor | CrtfdHst" } }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(adminRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
    ]
})
export class GovernorRoutingModule { }
