import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridOptions} from "ag-grid-community";
import { FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ChildMessageRendererComponent} from '../../common/child-message-renderer.component';
import { ParamsRendererComponent} from '../../common/params-renderer.component';
import { CellEditChangeRendererComponent} from '../../common/cell-edit-change-renderer.component';
import { PopUpRendererComponent} from '../../common/pop-up-renderer.component';
import { DropDownListRendererComponent} from '../../common/drop-down-list-renderer.component';
import { WorkClsRendererComponent } from '../../common/work-cls-renderer.component';
import { SlideToggleRendererComponent } from '../../common/slide-toggle-renderer.component';
import { CheckBoxRendererComponent } from '../../common/check-box-renderer.component';
import { PassWordRendererComponent } from '../../common/pass-word-renderer.component';
import { CustomLoadingOverlayComponent} from '../../common/custom-loading-overlay.component';
import { CustomNoRowsOverlayComponent} from '../../common/custom-no-rows-overlay.component';


import { CertificationService} from '../../services/Certification.service';
import { DatePipe, formatDate } from '@angular/common'
import { isNullOrUndefined } from 'util';

import { MatInput, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../common/date.adapter';
import { CertificationData } from '../../models/model.component';

import {AppComponent} from '../../app.component';

export interface stat {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-governor-certification',
    templateUrl: './certification.component.html',
    styleUrls: ['./certification.component.scss'],
    // datapicker date format setting
    providers: [ {provide: DateAdapter, useClass: AppDateAdapter},
                 {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
               ]    
})

export class CertificationComponent {
  @ViewChild('agGrid') agGrid: AgGridNg2;
  @ViewChild('selectedFromPicker', { read: MatInput }) selectedFromPicker: MatInput;
  @ViewChild('selectedToPicker', { read: MatInput }) selectedToPicker: MatInput;  

  //검색 조건 영역 변수
  public rowHeight : any;

  status: stat[] = [
    {value: '*', viewValue: '모두'},
    {value: 'E', viewValue: '활성화'},
    {value: 'D', viewValue: '비활성화'}
  ];

  // [app.component.ts] 로그인 사용자의 SITE_ID 데이터
  public loginUserSiteId: String;

    // 저장 및 삭제 영역
  static certificationComp;  // 전역 변수로 선언하여 외부 Component에서도 동일한 Object를 사용하도록 함.
  public execList: CertificationData[];

  public gridOptions: GridOptions;
  private getRowHeight;

  private gridApi;
  private gridColumnApi;

  title = 'app';

  search: any = {};

  columnDefs = [
    { headerName: '', field: '', sortable: true, filter: false, editable:false, checkboxSelection: true, width: 48, headerCheckboxSelection: true, resizable: false },
    { headerName: '계약번호', field: 'contractId', sortable: true, filter: true, editable: true, width: 130, resizable: false },
    { headerName: '인증주체', field: 'crtfdOnwer', sortable: true, filter: true, editable: true, width: 230, resizable: false },
    { headerName: '인증주체구분', field: 'crtfdOnwerDiv', sortable: true, filter: true, editable: true, width: 130, resizable: false,
      cellRenderer: "DropDownListRendererComponent",
      cellRendererParams : {values: this.status, popUpTarget: 'certification'}
    },

    { headerName: '인증주체 담당자', field: 'crtfdOnwerInfo', sortable: true, filter: true, editable: true, width: 230, resizable: false },
    { headerName: '인증만료일', field: 'crtfdExpDt', sortable: true, filter: true, editable: true, width: 130, resizable: false },
    { headerName: '허가버전', field: 'crtfdVersion', sortable: true, filter: true, editable: true, width: 230, resizable: false },        
    { headerName: '인증댓수정책', field: 'crtfdPolicy', sortable: true, filter: true, editable: true, width: 230, resizable: false },   
    { headerName: '', field: 'cls', sortable: true, filter: true, editable:true, width: 68, resizable: false,
        cellRenderer: "WorkClsRendererComponent",
        cellEditor : 'agSelectCellEditor', 
        cellRendererParams : {
                fieldNm: 'cls',
                values: ['조회','신규','수정','삭제'],
                cellRenderer: "WorkClsRendererComponent"
                }
    }          
  ];
  
  frameworkComponents = {
    CellEditChangeRendererComponent:CellEditChangeRendererComponent,
    PopUpRendererComponent : PopUpRendererComponent,
    DropDownListRendererComponent:DropDownListRendererComponent,
    ChildMessageRendererComponent: ChildMessageRendererComponent,
    WorkClsRendererComponent : WorkClsRendererComponent,
    SlideToggleRendererComponent: SlideToggleRendererComponent,
    CheckBoxRendererComponent : CheckBoxRendererComponent,
    PassWordRendererComponent : PassWordRendererComponent,
    CustomLoadingOverlayComponent : CustomLoadingOverlayComponent,
    CustomNoRowsOverlayComponent : CustomNoRowsOverlayComponent
  };

  // loaing overlay
  loadingOverlayComponent = "CustomLoadingOverlayComponent";
  noRowsOverlayComponent = "CustomNoRowsOverlayComponent";

  defaultColDef = {
    editable: true,
    resizable: true,
    cellClass: "align-center"    
  };

  rowSelection = "multiple";
  rowData: any;

  // overlayNoRowsTemplate =
  // "<span style=\"padding: 100px; border: 4px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>";

  constructor(public http: HttpClient, private _certificationService: CertificationService, public datepipe: DatePipe) {
    CertificationComponent.certificationComp = this;  // governorComponent 의 AddRow, delete, save 제어를 위해

    this.gridOptions = <GridOptions>{
      context: {componentParent: this},
      pagination: true,
      paginationAutoPageSize: true
      };
    //this.gridOptions.rowData = createRowData();
    //this.onAddRow();
    this.gridOptions.columnDefs = this.columnDefs;
    // row height size setting
    this.getRowHeight = function(params) { return 46; };
  
  }
  
  ngOnInit() {
    // Grid 데이터 가져오기
    this.getAllValues();

    this.setDefaultSearchItems();
    //this.onSearchBoxVisible();        
    document.getElementById('searchBtn').textContent = "search";
    document.getElementById('searchBtn').style.color = "#2a80b9";
    document.getElementById('searchBtn').style.display = "block"; 
    document.getElementById('searchSpot').innerHTML = "";
    document.getElementById('searchSpot').append(document.getElementById("_MFF_SearchBox"));
  }

  /************************************************************
   *   버튼 및 grid event 영역
   ************************************************************/
  // 검색조건 초기화
  onClear(){
    this.search.searchContractId = '';

  }

  // grid load event
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    //params.api.sizeColumnsToFit();
    this.gridApi.hideOverlay();

    // console.log('================this.onGridReady : ');
    this.onInitPage();
  }

  onInitPage() {
    // var param: CertificationData = {
    //   SiteId : AppComponent.appComp.loginUserSiteId,
    //   SiteName: "",
    //   Cls: 0,
    //   SysFlag: "",
    // };

    // this._certificationService.getValuesByParam(param).subscribe(
    //   data => this.rowData = data
    // ) 
  }


  // grid edit 발생 시 check box = true 를 하기위한 evnet 감지 methods
  onCellValueChanged(event) {
    if(!isNullOrUndefined(event.newValue) && event.newValue != event.oldValue) {
      var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
      rowNode.setSelected(true);

      // console.log(rowNode.data.cls);
      // 1은 Insert를 의미하기 때문에 Insert가 아닌데 Cell의 Value가 변화하는 경우는 Update로 간주
      if(rowNode.data.cls != 1) {
        rowNode.setDataValue('cls', 2); // Column Key와 Value임
      }
    }
  }

  //Grid 최하단에 신규 row 추가
  onAddRow() {
    CertificationComponent.certificationComp.gridApi.showLoadingOverlay();    
    /*
     * service를 통한 http 호출 함수는 async로 동작함. (일반 http.get(), post() 등은 확인 못해봄)
     * 따라서 순차처리가 필요하면 아래와 같이 subscribe 영역에 Logic을 구현해야함.
     */
    let id : string;

    this._certificationService.getSequenceValue().subscribe(
      //data => id
      data => {
        var today = new Date();
        var newItem = { 
           siteId: data
          ,siteName: '{필수값} '
          ,cls: 1 };          
        var res = CertificationComponent.certificationComp.gridApi.updateRowData({ add: [newItem], addIndex: 0 });


      }
    );

    setTimeout(() => {
      CertificationComponent.certificationComp.gridApi.hideOverlay();    
     }, 500);
    //console.log("data : " + id);
  }
   

  // cell renderer 에서 발생한 event 처리 결과를 받는 call back methods
  public methodFromParent(cell) {    
    var cellValue = `${cell}`;
    let popUpRtnValue        = cellValue.split(';')[0];
    let fieldNm       = cellValue.split(';')[1];
    let rowIndex  = cellValue.split(';')[2];        

    // console.log('methodFromParent bef : '+  popUpRtnValue+' '+fieldNm + ' ' + rowIndex);
    
    if(!isNullOrUndefined(popUpRtnValue) && fieldNm == "cls") {
      var rowNode = this.agGrid.api.getRowNode(rowIndex);
      rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
      rowNode.setSelected(true);

      var res = this.gridApi.updateRowData({ remove:[rowNode.data] });
      return;
    }

    if(!isNullOrUndefined(popUpRtnValue)) {
        this.setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue);
    }
  }

  // cell renderer 에서 받은 data 를 현재 grid 에 set 
  setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue) {
    var rowNode = this.agGrid.api.getRowNode(rowIndex);
    rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
    rowNode.setSelected(true);

    if(fieldNm == "cls"){
      var selectedData = this.gridApi.getSelectedRows();
      // console.log('methodFromParent selectedData : '+  selectedData);
      var res = CertificationComponent.certificationComp.gridApi.updateRowData({ remove:selectedData });
    }    
  }

  // Web Browser의 F5(새로고침) 기능과 동일
  refresh(): void {
    window.location.reload();
  }

  /************************************************************
   *   조회, 저장, 삭제 영역
   ************************************************************/
  // 전체 조회
  getAllValues() {
    this._certificationService.getAllValues().subscribe(
      data => this.rowData = data.body
    )
  }

    // pop up 조회 버튼 클릭
  public onSearch(search) : void{
    CertificationComponent.certificationComp.gridApi.showLoadingOverlay();
    if(isNullOrUndefined(search)) {
      var param: CertificationData = {
        ContractId: "",
        CrtfdOnwer: "",
        CrtfdOnwerDiv: "",
        CrtfdOnwerInfo: "",
        CrtfdExpDt: "",
        CrtfdVersion: "",
        CrtfdPolicy: "",
        Cls: 0
      };
    } else {
      var param: CertificationData = {
        ContractId: this.search.searchContractId,
        CrtfdOnwer: "",
        CrtfdOnwerDiv: "",
        CrtfdOnwerInfo: "",
        CrtfdExpDt: "",
        CrtfdVersion: "",
        CrtfdPolicy: "",
        Cls: 0
      };
    }

    this._certificationService.getValuesByParam(param).subscribe(
      data => this.rowData = data
    )

    // console.log('getSelectedRow : '+ this.rowData.contractId + ' '+ this.rowData.crtfdOnwer + ' ' +this.rowData.crtfdOnwerDiv + ' '+ this.rowData.crtfdOnwerInfo);      

   setTimeout(() => {
    CertificationComponent.certificationComp.gridApi.hideOverlay(); 
     }, 1000);

  } 

  getSelectedRows(_cls): CertificationData[] {
    var _certificationDataList: CertificationData[] = new Array<CertificationData>();
    var selectedData = CertificationComponent.certificationComp.gridApi.getSelectedRows();
    selectedData.forEach(function(row, index) {
      
      if(_cls != 3) {
        var certificationData: CertificationData = {
          ContractId: row.ContractId,
          CrtfdOnwer: row.CrtfdOnwer,
          CrtfdOnwerDiv: row.CrtfdOnwerDiv,
          CrtfdOnwerInfo: row.CrtfdOnwerInfo,
          CrtfdExpDt: row.CrtfdExpDt,
          CrtfdVersion: row.CrtfdVersion,
          CrtfdPolicy: row.CrtfdPolicy,
          Cls: row.cls
        };

        _certificationDataList.push(certificationData);
      } else {
        var certificationData: CertificationData = {
          ContractId: row.ContractId,
          CrtfdOnwer: row.CrtfdOnwer,
          CrtfdOnwerDiv: row.CrtfdOnwerDiv,
          CrtfdOnwerInfo: row.CrtfdOnwerInfo,
          CrtfdExpDt: row.CrtfdExpDt,
          CrtfdVersion: row.CrtfdVersion,
          CrtfdPolicy: row.CrtfdPolicy,
          Cls: _cls
        };

        _certificationDataList.push(certificationData);
      }
      
    //   _siteList.forEach(function(data) {
    //   console.log('getSelectedRow : '+data.siteId + ' '+ data.ProjectName + ' ' +data.StrtDate + ' '+ data.Cls + ' '+data.Flag);
    // });

    });

    return _certificationDataList;
  }

  saveValues() {
    var paramList = CertificationComponent.certificationComp.getSelectedRows(1);
    CertificationComponent.certificationComp.gridApi.showLoadingOverlay();

    this._certificationService.saveValuesByParams(paramList).subscribe(
      data => {
        if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
          //SAVE Query Fail
          // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
          alert("저장 처리 중 오류가 발생했습니다.");
        } else {
          //SAVE Query Success
          data;
          //this.refresh();
        }
      }
    );
    setTimeout(() => {
      CertificationComponent.certificationComp.gridApi.hideOverlay();
      // console.log("save->siteName: " + this.search.searchContractId,);
      CertificationComponent.certificationComp.onSearch(this.search)  
    }, 1000);
  }

  deleteValues() {
    var paramList = CertificationComponent.certificationComp.getSelectedRows(3);

    this._certificationService.deleteValuesByParams(paramList).subscribe(
      data => {
        data; // 미사용
        // row data refresh
        //this.refresh();
      }
    );
    setTimeout(() => {
      CertificationComponent.certificationComp.gridApi.hideOverlay();
      // console.log("save-> ContractId: " + this.search.searchContractId);
      CertificationComponent.certificationComp.onSearch(this.search)  
    }, 1000);
  }

  setDefaultSearchItems() {
    this.search.searchContractId = "";
    // this.getAllValues();
  }
  
  onSearchBoxVisible() {
    if (document.getElementById('_MFF_SearchBox').hidden == true) {
      document.getElementById('searchBtn').textContent = "clear";
      document.getElementById('searchBtn').style.color = "#717171";
      document.getElementById('searchSpot').hidden = false;
      document.getElementById('_MFF_SearchBox').hidden = false;
    } else {
      document.getElementById('searchBtn').textContent = "search";
      document.getElementById('searchBtn').style.color = "#2a80b9";
      document.getElementById('searchSpot').hidden = true;
      document.getElementById('_MFF_SearchBox').hidden = true;
    }
  }
  
} // end class

function createRowData() {
  var rowData = [];
  for (var i = 0; i < 1; i++) {
    rowData.push({
      row: "Row " + i,
      value: i,
      currency: i + Number(Math.random().toFixed(2))
    });
  }
  return rowData;
}

function numberValueParser(params) {
  return Number(params.newValue);
}

function formatNumber(number) {

  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}




