import { Component, Inject, ViewChild, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { isNullOrUndefined } from 'util';

@Component({
  templateUrl: './pop-up-dialog-passWdChg.component.html',  
  styles: [`mat-form-field {
    margin-right: 12px;
  }`]
})
export class PopUpDialogPassWdChgComponent{
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private path; // Log File path
  private fileText; // Textarea
  maxRows;

  static matDial: MatDialog = null;
  public newPassword: string;
  public metaValueId: string;

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogPassWdChgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient, private ngZone: NgZone, public dialog: MatDialog) {
      PopUpDialogPassWdChgComponent.matDial = dialog;
    }

  ngOnInit() {
  }

  // TBL_USER 데이터 추가
  onSavePwd() {

    if(this.newPassword == '' || isNullOrUndefined(this.newPassword)) {
        alert("비밀번호를 입력해주세요.");
        return;
    }
    
    if(this.metaValueId == '' || isNullOrUndefined(this.metaValueId)) {
      this.metaValueId = '';
    }

    this.dialogRef.close(this.metaValueId + "/" + this.newPassword);
  }  

  refresh(): void {
    window.location.reload();
  } 

  onCancel() {
    this.dialogRef.close();
  }

}