import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridOptions} from "ag-grid-community";
import { FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ChildMessageRendererComponent} from '../../common/child-message-renderer.component';
import { ParamsRendererComponent} from '../../common/params-renderer.component';
import { CellEditChangeRendererComponent} from '../../common/cell-edit-change-renderer.component';
import { PopUpRendererComponent} from '../../common/pop-up-renderer.component';
import { DropDownListRendererComponent} from '../../common/drop-down-list-renderer.component';
import { WorkClsRendererComponent } from '../../common/work-cls-renderer.component';
import { SlideToggleRendererComponent } from '../../common/slide-toggle-renderer.component';
import { CheckBoxRendererComponent } from '../../common/check-box-renderer.component';
import { PassWordRendererComponent } from '../../common/pass-word-renderer.component';
import { CustomLoadingOverlayComponent} from '../../common/custom-loading-overlay.component';
import { CustomNoRowsOverlayComponent} from '../../common/custom-no-rows-overlay.component';

import { MatDialog } from '@angular/material';

import { CertificationExceptService } from '../../services/CertificationExcept.service';
import { DatePipe, formatDate } from '@angular/common'
import { isNullOrUndefined } from 'util';

import { MatInput, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../common/date.adapter';
import { CertificationExceptData } from '../../models/model.component';
import { PopUpDialogContractComponent } from '../../common/popup/pop-up-dialog-contract.component';

import {AppComponent} from '../../app.component';

export interface stat {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-governor-certification',
    templateUrl: './certificationExcept.component.html',
    styleUrls: ['./certificationExcept.component.scss'],
    // datapicker date format setting
    providers: [ {provide: DateAdapter, useClass: AppDateAdapter},
                 {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
               ]    
})

export class CertificationExceptComponent {
  @ViewChild('agGrid') agGrid: AgGridNg2;
  @ViewChild('selectedFromPicker', { read: MatInput }) selectedFromPicker: MatInput;
  @ViewChild('selectedToPicker', { read: MatInput }) selectedToPicker: MatInput;  

  //검색 조건 영역 변수
  public rowHeight : any;

  // [app.component.ts] 로그인 사용자의 SITE_ID 데이터
  public loginUserSiteId: String;

    // 저장 및 삭제 영역
  static certificationExceptComp;  // 전역 변수로 선언하여 외부 Component에서도 동일한 Object를 사용하도록 함.
  static matDial: MatDialog = null;    
  public execList: CertificationExceptData[];

  public gridOptions: GridOptions;
  private getRowHeight;

  private gridApi;
  private gridColumnApi;

  title = 'app';

  search: any = {};

  columnDefs = [
    { headerName: '', field: '', sortable: true, filter: false, editable:false, checkboxSelection: true, width: 48, headerCheckboxSelection: false, resizable: false },
    { headerName: '계약번호', field: 'contractId', sortable: true, filter: true, editable: true, width: 100, resizable: true },
    { headerName: '제품키', field: 'productKey', sortable: true, filter: true, editable: true, width: 200, resizable: true },
    { headerName: '일련번호', field: 'contractSeq', sortable: true, filter: true, editable: false, width: 110, resizable: true },
    { headerName: '이메일', field: 'email', sortable: true, filter: true, editable: true, width: 210, resizable: true },
    { headerName: 'MAC주소', field: 'macAddress', sortable: true, filter: true, editable: true, width: 135, resizable: true },
    { headerName: '디스크 Volume', field: 'diskVolume', sortable: true, filter: true, editable: true, width: 350, resizable: true },
    { headerName: 'BIOS 시리얼번호', field: 'biosSeq', sortable: true, filter: true, editable: true, width: 155, resizable: true },
    { headerName: '중복허용', field: 'allowDplctCnt', sortable: true, filter: true, editable: true, width: 100, resizable: true },
    { headerName: '', field: 'cls', sortable: true, filter: true, editable:true, width: 68, resizable: false,
        cellRenderer: "WorkClsRendererComponent",
        cellEditor : 'agSelectCellEditor', 
        cellRendererParams : {
                fieldNm: 'cls',
                values: ['조회','신규','수정','삭제'],
                cellRenderer: "WorkClsRendererComponent"
                }
    }          
  ];
  
  frameworkComponents = {
    CellEditChangeRendererComponent:CellEditChangeRendererComponent,
    PopUpRendererComponent : PopUpRendererComponent,
    DropDownListRendererComponent:DropDownListRendererComponent,
    ChildMessageRendererComponent: ChildMessageRendererComponent,
    WorkClsRendererComponent : WorkClsRendererComponent,
    SlideToggleRendererComponent: SlideToggleRendererComponent,
    CheckBoxRendererComponent : CheckBoxRendererComponent,
    PassWordRendererComponent : PassWordRendererComponent,
    CustomLoadingOverlayComponent : CustomLoadingOverlayComponent,
    CustomNoRowsOverlayComponent : CustomNoRowsOverlayComponent
  };

  // loaing overlay
  loadingOverlayComponent = "CustomLoadingOverlayComponent";
  noRowsOverlayComponent = "CustomNoRowsOverlayComponent";

  defaultColDef = {
    editable: true,
    resizable: true,
    cellClass: "align-center"    
  };

  rowSelection = "multiple";
  rowData: any;

  // overlayNoRowsTemplate =
  // "<span style=\"padding: 100px; border: 4px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>";

  constructor(public http: HttpClient, private _certificationExceptService: CertificationExceptService, public datepipe: DatePipe, public dialog: MatDialog) {
    CertificationExceptComponent.certificationExceptComp = this;  // governorComponent 의 AddRow, delete, save 제어를 위해
    CertificationExceptComponent.matDial = dialog;

    this.gridOptions = <GridOptions>{
      context: {componentParent: this},
      pagination: true,
      paginationAutoPageSize: true
      };
    //this.gridOptions.rowData = createRowData();
    //this.onAddRow();
    this.gridOptions.columnDefs = this.columnDefs;
    // row height size setting
    this.getRowHeight = function(params) { return 46; };
  
  }
  
  ngOnInit() {
    // Grid 데이터 가져오기
    this.getAllValues();

    this.setDefaultSearchItems();
    //this.onSearchBoxVisible();        
    document.getElementById('searchBtn').textContent = "search";
    document.getElementById('searchBtn').style.color = "#2a80b9";
    document.getElementById('searchBtn').style.display = "block"; 
    document.getElementById('searchSpot').innerHTML = "";
    document.getElementById('searchSpot').append(document.getElementById("_MFF_SearchBox"));
  }

  /************************************************************
   *   버튼 및 grid event 영역
   ************************************************************/
  // 검색조건 초기화
  onClear(){
    this.search.searchContractId = '';

  }

  // grid load event
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    //params.api.sizeColumnsToFit();
    this.gridApi.hideOverlay();

    // console.log('================this.onGridReady : ');
    this.onInitPage();
  }

  onInitPage() {
    // var param: CertificationData = {
    //   SiteId : AppComponent.appComp.loginUserSiteId,
    //   SiteName: "",
    //   Cls: 0,
    //   SysFlag: "",
    // };

    // this._certificationService.getValuesByParam(param).subscribe(
    //   data => this.rowData = data
    // ) 
  }


  // grid edit 발생 시 check box = true 를 하기위한 evnet 감지 methods
  onCellValueChanged(event) {
    if(!isNullOrUndefined(event.newValue) && event.newValue != event.oldValue) {
      var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
      rowNode.setSelected(true);

      // 화면에서 제품키 INSERT 하는 경우 계약ID 자동 매핑 기능 구현
      if(event.colDef.field == 'productKey') {
        this.GetContractIdByProductKey(rowNode.data.productKey, event.rowIndex);
      }

      // console.log(rowNode.data.cls);
      // 1은 Insert를 의미하기 때문에 Insert가 아닌데 Cell의 Value가 변화하는 경우는 Update로 간주
      if(rowNode.data.cls != 1) {
        rowNode.setDataValue('cls', 2); // Column Key와 Value임
      }
    }
  }

  GetContractIdByProductKey(productKey, idx) {

    var rowNode = this.agGrid.api.getDisplayedRowAtIndex(idx);

    // this._crtfdHstService.getCrtfdKeyByHwKey(param).subscribe(
    //   //data => this.rowData = data
    // )
    this._certificationExceptService.getContractIdByProductKey(productKey).subscribe(
      data => {
        rowNode.setDataValue('contractId', data);
      }
    );
  }

  //Grid 최하단에 신규 row 추가
  onAddRow() {
    CertificationExceptComponent.certificationExceptComp.gridApi.showLoadingOverlay();    
    /*
     * service를 통한 http 호출 함수는 async로 동작함. (일반 http.get(), post() 등은 확인 못해봄)
     * 따라서 순차처리가 필요하면 아래와 같이 subscribe 영역에 Logic을 구현해야함.
     */
    let id : string;

    this._certificationExceptService.getSequenceValue().subscribe(
      //data => id
      data => {
        var newItem = { 
          contractId: ''
          ,productKey: ''
          ,contractSeq: data
          ,cls: 1};        
        var res = CertificationExceptComponent.certificationExceptComp.gridApi.updateRowData({ add: [newItem], addIndex: 0 });


      }
    );

    setTimeout(() => {
      CertificationExceptComponent.certificationExceptComp.gridApi.hideOverlay();    
     }, 500);
    //console.log("data : " + id);
  }
   

  // cell renderer 에서 발생한 event 처리 결과를 받는 call back methods
  public methodFromParent(cell) {    
    var cellValue = `${cell}`;
    let popUpRtnValue        = cellValue.split(';')[0];
    let fieldNm       = cellValue.split(';')[1];
    let rowIndex  = cellValue.split(';')[2];        

    // console.log('methodFromParent bef : '+  popUpRtnValue+' '+fieldNm + ' ' + rowIndex);
    
    if(!isNullOrUndefined(popUpRtnValue) && fieldNm == "cls") {
      var rowNode = this.agGrid.api.getRowNode(rowIndex);
      rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
      rowNode.setSelected(true);

      var res = this.gridApi.updateRowData({ remove:[rowNode.data] });
      return;
    }

    if(!isNullOrUndefined(popUpRtnValue)) {
        this.setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue);
    }
  }

  // cell renderer 에서 받은 data 를 현재 grid 에 set 
  setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue) {
    var rowNode = this.agGrid.api.getRowNode(rowIndex);
    rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
    rowNode.setSelected(true);

    if(fieldNm == "cls"){
      var selectedData = this.gridApi.getSelectedRows();
      // console.log('methodFromParent selectedData : '+  selectedData);
      var res = CertificationExceptComponent.certificationExceptComp.gridApi.updateRowData({ remove:selectedData });
    }    
  }

  // Web Browser의 F5(새로고침) 기능과 동일
  refresh(): void {
    window.location.reload();
  }

  /************************************************************
   *   조회, 저장, 삭제 영역
   ************************************************************/
  // 전체 조회
  getAllValues() {
    this._certificationExceptService.getAllValues().subscribe(
      data => this.rowData = data.body
    )
  }

    // pop up 조회 버튼 클릭
  public onSearch(search) : void{
    CertificationExceptComponent.certificationExceptComp.gridApi.showLoadingOverlay();
    if(isNullOrUndefined(search)) {
      var param: CertificationExceptData = {
        ContractId: "",
        ProductKey: "",
        ContractSeq: "",
        Email: "",
        MacAddress: "",
        DiskVolume: "",
        BiosSeq: "",
        AllowDplctCnt: "",
        Cls: 0
      };
    } else {
      var param: CertificationExceptData = {
        ContractId: this.search.searchContractId,
        ProductKey: "",
        ContractSeq: "",
        Email: "",
        MacAddress: "",
        DiskVolume: "",
        BiosSeq: "",
        AllowDplctCnt: "",
        Cls: 0
      };
    }

    this._certificationExceptService.getValuesByParam(param).subscribe(
      data => this.rowData = data
    )

    // console.log('getSelectedRow : '+ this.rowData.contractId + ' '+ this.rowData.crtfdOnwer + ' ' +this.rowData.crtfdOnwerDiv + ' '+ this.rowData.crtfdOnwerInfo);      

   setTimeout(() => {
    CertificationExceptComponent.certificationExceptComp.gridApi.hideOverlay(); 
     }, 1000);

  } 

  getSelectedRows(_cls): CertificationExceptData[] {
    var _certificationExceptDataList: CertificationExceptData[] = new Array<CertificationExceptData>();
    var selectedData = CertificationExceptComponent.certificationExceptComp.gridApi.getSelectedRows();
    selectedData.forEach(function(row, index) {
      if(_cls != 3) {
        var certificationExceptData: CertificationExceptData = {
          ContractId: row.contractId,
          ProductKey: row.productKey,
          ContractSeq: row.contractSeq,
          Email: row.email,
          MacAddress: row.macAddress,
          DiskVolume: row.diskVolume,
          BiosSeq: row.biosSeq,
          AllowDplctCnt: row.allowDplctCnt,
          Cls: row.cls
        };

        _certificationExceptDataList.push(certificationExceptData);
      } else {
        var certificationExceptData: CertificationExceptData = {
          ContractId: row.contractId,
          ProductKey: row.productKey,
          ContractSeq: row.contractSeq,
          Email: row.email,
          MacAddress: row.macAddress,
          DiskVolume: row.diskVolume,
          BiosSeq: row.biosSeq,
          AllowDplctCnt: row.allowDplctCnt,
          Cls: _cls
        };

        _certificationExceptDataList.push(certificationExceptData);
      }
      
    //   _siteList.forEach(function(data) {
    //   console.log('getSelectedRow : '+data.siteId + ' '+ data.ProjectName + ' ' +data.StrtDate + ' '+ data.Cls + ' '+data.Flag);
    // });

    });

    return _certificationExceptDataList;
  }

  saveValues() {
    var paramList = CertificationExceptComponent.certificationExceptComp.getSelectedRows(1);
    CertificationExceptComponent.certificationExceptComp.gridApi.showLoadingOverlay();

    this._certificationExceptService.saveValuesByParams(paramList).subscribe(
      data => {
        if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
          //SAVE Query Fail
          // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
          alert("저장 처리 중 오류가 발생했습니다.");
        } else {
          //SAVE Query Success
          data;
          //this.refresh();
        }
      }
    );
    setTimeout(() => {
      CertificationExceptComponent.certificationExceptComp.gridApi.hideOverlay();
      // console.log("save->siteName: " + this.search.searchContractId,);
      CertificationExceptComponent.certificationExceptComp.onSearch(this.search)  
    }, 1000);
  }

  deleteValues() {
    var paramList = CertificationExceptComponent.certificationExceptComp.getSelectedRows(3);

    this._certificationExceptService.deleteValuesByParams(paramList).subscribe(
      data => {
        data; // 미사용
        // row data refresh
        //this.refresh();
      }
    );
    setTimeout(() => {
      CertificationExceptComponent.certificationExceptComp.gridApi.hideOverlay();
      // console.log("save-> ContractId: " + this.search.searchContractId);
      CertificationExceptComponent.certificationExceptComp.onSearch(this.search)  
    }, 1000);
  }

  setDefaultSearchItems() {
    this.search.searchContractId = "";
    // this.getAllValues();
  }
  
  onSearchBoxVisible() {
    if (document.getElementById('_MFF_SearchBox').hidden == true) {
      document.getElementById('searchBtn').textContent = "clear";
      document.getElementById('searchBtn').style.color = "#717171";
      document.getElementById('searchSpot').hidden = false;
      document.getElementById('_MFF_SearchBox').hidden = false;
    } else {
      document.getElementById('searchBtn').textContent = "search";
      document.getElementById('searchBtn').style.color = "#2a80b9";
      document.getElementById('searchSpot').hidden = true;
      document.getElementById('_MFF_SearchBox').hidden = true;
    }
  }
  
} // end class

function createRowData() {
  var rowData = [];
  for (var i = 0; i < 1; i++) {
    rowData.push({
      row: "Row " + i,
      value: i,
      currency: i + Number(Math.random().toFixed(2))
    });
  }
  return rowData;
}

function numberValueParser(params) {
  return Number(params.newValue);
}

function formatNumber(number) {

  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}




