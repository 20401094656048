import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { CommonService } from './common.service';

@Injectable()
export class IllegalUserService implements CommonService {
  myAppUrl: string = "";

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }

  getAllValues() : Observable<HttpResponse<Object[]>> {
    let params = new HttpParams();
    params = null
    
    return this._http.get<Object[]>(this.myAppUrl + 'api/TblIllegalUser/Get/all',{
       params, observe: "response"
    });
  }

  /* getValueById(ContractId : string) : Observable<HttpResponse<Object[]>>{
    let params = new HttpParams();
    params = null;

    return this._http.get<Object[]>(this.myAppUrl + 'api/TblIllegalUser/Get/'+ContractId, {
      params, observe: "response"
    });
  } */

  getValuesByParam(param) : Observable<Object[]> {

    let params = new HttpParams();
    params = params.append('param', param);  

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblIllegalUser/Get', param);
  }

  /* getValuesByContractId(param) : Observable<Object[]> {

    let params = new HttpParams();
    params = params.append('param', param);  

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblIllegalUser/ContractId', param);
  } */  

  saveValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblIllegalUser/Save', body, {
      headers: headerOptions
    });
  }

  deleteValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/TblIllegalUser/Delete', body, {
      headers: headerOptions
    });
  }

  getSequenceValue() {
    let params = new HttpParams();
    params = null
    
    return this._http.get(this.myAppUrl + 'api/TblIllegalUser/Get/Seq',{
       responseType: 'text'
    });
  }
}
