import { Component, Inject, ViewChild, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take, isEmpty, find} from 'rxjs/operators';
import { PopUpDialogIdchkComponent } from '../../common/popup/pop-up-dialog-idchk.component';
import { PopUpDialogJoinCompletedComponent } from '../../common/popup/pop-up-dialog-join-completed.component';
import { isNullOrUndefined } from 'util';
import { UserService } from '../../services/user.service';
import { UserData } from '../../models/model.component';
import { STRING_TYPE } from '@angular/compiler/src/output/output_ast';
import {FormControl, Validators} from '@angular/forms';
import { EncryptService } from '../../services/encrypt.service';

@Component({
  templateUrl: './pop-up-dialog-join.component.html',
  styles: [`
  mat-form-field {
    margin-right: 12px;
  }
  mat-dialog-container {
    padding: 0 !important;
  }
  `],
  styleUrls: ['../common.component.css']
})
export class PopUpDialogJoinComponent{
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private path; // Log File path
  private fileText; // Textarea
  maxRows;

  static matDial: MatDialog = null;
  siteId: string;

  public userList: UserData[];
  public userId: string;
  public userName: string;
  public password: string;
  public telno: string;
  public email: string;
  public notiType: string;
  public comment: string;

  public strData: string;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogJoinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient, private ngZone: NgZone, public dialog: MatDialog, private _userService: UserService, private _encryptService: EncryptService) {
      PopUpDialogJoinComponent.matDial = dialog;
    }

  ngOnInit() {
    this._userService.getAllValues().subscribe(
      data => {
        this.strData = JSON.stringify(data.body);
        // console.log("### test ### : " + this.strData); // JSON 전체 데이터 STRING화 {"userId" : "qwerty", "userName" : "..."}
        // console.log("### test 2 ### : " + data.body); //데이터 갯수에 맞춰져서 Object 생성 [object Object]
      }
    );
  }

  // TBL_USER 데이터 추가
  onSaveUser() {



    if(this.userId == '' || isNullOrUndefined(this.userId)) {
      document.getElementById("inputId").textContent = "아이디를 입력해주세요.";
      document.getElementById("inputId").style.display = "block";
      document.getElementById("inputboxId").focus();
      return;
    } else {
      document.getElementById("inputId").style.display = "none";
    }

    if(this.userName == '' || isNullOrUndefined(this.userName)) {
      document.getElementById("inputName").textContent = "이름을 입력해주세요.";
      document.getElementById("inputName").style.display = "block";
      document.getElementById("inputboxName").focus();
      return;
    } else {
      document.getElementById("inputName").style.display = "none";
    }

    if(this.password == '' || isNullOrUndefined(this.password)) {
      document.getElementById("inputPwd").textContent = "비밀번호를 입력해주세요.";
      document.getElementById("inputPwd").style.display = "block";
      document.getElementById("inputboxPwd").focus();
      return;
    } else {
      // if(this.password.length < 8 || this.password.length > 12) {
      if(!validatePassword(this.password)) {
        // document.getElementById("inputPwd").textContent = "비밀번호를 8~12자리로 입력해주세요.";
        document.getElementById("inputPwd").textContent = "특수문자, 숫자, 문자를 포함한 8~15자리 값을 입력해주세요.";
        document.getElementById("inputPwd").style.display = "block";
        document.getElementById("inputboxPwd").focus();
        return;
      }
      document.getElementById("inputPwd").style.display = "none";
    }

    if(this.siteId == '' || isNullOrUndefined(this.siteId)) {
      document.getElementById("inputSiteid").textContent = "사이트 아이디를 입력해주세요.";
      document.getElementById("inputSiteid").style.display = "block";
      document.getElementById("inputboxSiteid").focus();
      return;
    } else {
      document.getElementById("inputSiteid").style.display = "none";
    }

    if(this.telno == '' || isNullOrUndefined(this.telno)) {
      document.getElementById("inputTel").textContent = "전화번호를 입력해주세요.";
      document.getElementById("inputTel").style.display = "block";
      document.getElementById("inputboxTel").focus();
      return;
    } else {
      if(isNaN(+this.telno)) {
        document.getElementById("inputTel").textContent = "전화번호에 문자가 포함되어 있습니다.";
        document.getElementById("inputTel").style.display = "block";
        document.getElementById("inputboxTel").focus();
        return;
      }
      document.getElementById("inputTel").style.display = "none";
    }

    if(this.email == '' || isNullOrUndefined(this.email)) {
      document.getElementById("inputEmail").textContent = "이메일을 입력해주세요.";
      document.getElementById("inputEmail").style.display = "block";
      document.getElementById("inputboxEmail").focus();
      return;
    } else {
      if(this.emailFormControl.invalid == true) {
        document.getElementById("inputEmail").textContent = "이메일을 올바른 양식으로 입력해주세요.";
        document.getElementById("inputEmail").style.display = "block";
        document.getElementById("inputboxEmail").focus();
        return;
      }
      document.getElementById("inputEmail").style.display = "none";
    }

    if(this.comment == '' || isNullOrUndefined(this.comment)) {
      document.getElementById("inputCompany").textContent = "조직명을 입력해주세요.";
      document.getElementById("inputCompany").style.display = "block";
      document.getElementById("inputboxCompany").focus();
      return;
    } else {
      document.getElementById("inputCompany").style.display = "none";
    }

    if(this.notiType == '' || isNullOrUndefined(this.notiType)) {
      document.getElementById("inputAlert").textContent = "알림수단을 입력해주세요.";
      document.getElementById("inputAlert").style.display = "block";
      document.getElementById("inputboxAlert").focus();
      return;
    } else {
      document.getElementById("inputAlert").style.display = "none";
    }

    // 패스워드 암호화 적용
    var encryptData = this._encryptService.getEncryptedData(this.password);

    var _userList: UserData[] = new Array<UserData>();

    var param: UserData = {
      UserId: this.userId,
      Status: "R",
      UserType: "L",
      UserName: this.userName,
      Password: encryptData.AESKeyUsingRSA,
      EncryptData: encryptData.DataUsingAES,
      SiteId: this.siteId,
      Telno: this.telno,
      SiteGroup: "G",
      Email: this.email,
      Comment: this.comment,
      SysUserId: "",
      LastLoginDt: "",
      NotiType: this.notiType,
      LoginFailCount: "",
      PwdUpdDt: "",
      Token: "",
      Cls: 1,
      SysFlag: "A",
    };

  _userList.push(param);

    this._userService.saveValuesByParams(_userList).subscribe(
      data => {
        //data;
         // 미사용
        // row data refresh
        //this.refresh();



        //QUERY 에러 때문에 저장 안되는 현상 NOTI를 위한 로직
        if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
          //SAVE Query Fail
          // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
          alert("저장 처리 중 오류가 발생했습니다.");
        } else {
          //SAVE Query Success
          data;
          //this.refresh();
          const dialogRef = PopUpDialogJoinComponent.matDial.open(PopUpDialogJoinCompletedComponent, {
            width: '500px', height : '314px'
          });
          this.dialogRef.close();
        }

      }
    );

    //this.dialogRef.close();
  }

  refresh(): void {
    window.location.reload();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onUserIdChk() {
    const dialogRef = PopUpDialogJoinComponent.matDial.open(PopUpDialogIdchkComponent, {
      width: '410px', height : '309px',
      // data: this.params.value
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!isNullOrUndefined(result)) {
        this.userId = result;
      }
    });
  }

  public checkNumber(event)
  {
    if(isNaN(+this.telno)) {
      document.getElementById("inputTel").textContent = "전화번호에 문자가 포함되어 있습니다.";
      document.getElementById("inputTel").style.display = "block";
      return;
    } else if(this.telno == '' || isNullOrUndefined(this.telno)) {
      document.getElementById("inputTel").textContent = "전화번호를 입력해주세요.";
      document.getElementById("inputTel").style.display = "block";
      return;
    } else {
      document.getElementById("inputTel").style.display = "none";
    }
  }

  public checkPwd(event)
  {
    // if(this.password.length < 8 || this.password.length > 12) {
      if(!validatePassword(this.password)) {
      // document.getElementById("inputPwd").textContent = "비밀번호를 8~12자리로 입력해주세요.";
      document.getElementById("inputPwd").textContent = "특수문자, 숫자, 문자를 포함한 8~20자리 값을 입력해주세요.";
      document.getElementById("inputPwd").style.display = "block";
      return;
    } else {
      document.getElementById("inputPwd").style.display = "none";
    }
  }

  public checkEmail(event) {
    if(this.email == '' || isNullOrUndefined(this.email)) {
      document.getElementById("inputEmail").textContent = "이메일을 입력해주세요.";
      document.getElementById("inputEmail").style.display = "block";
      return;
    } else {
      if(this.emailFormControl.invalid == true) {
        document.getElementById("inputEmail").textContent = "이메일을 올바른 양식으로 입력해주세요.";
        document.getElementById("inputEmail").style.display = "block";
        return;
      }
      document.getElementById("inputEmail").style.display = "none";
    }
  }

  public checkName(event) {
    if(this.userName == '' || isNullOrUndefined(this.userName)) {
      document.getElementById("inputName").textContent = "이름을 입력해주세요.";
      document.getElementById("inputName").style.display = "block";
      return;
    }
    document.getElementById("inputName").style.display = "none";
  }

  public checkCompany(event) {
    if(this.comment == '' || isNullOrUndefined(this.comment)) {
      document.getElementById("inputCompany").textContent = "조직명을 입력해주세요.";
      document.getElementById("inputCompany").style.display = "block";
      return;
    }
    document.getElementById("inputCompany").style.display = "none";
  }

}

// 비밀번호 정규식 적용 (특수문자, 숫자, 문자 최소 1자리 입력되어야 하며 최소 8자리 입력)
function validatePassword(character) {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/.test(character)
}
