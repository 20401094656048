import { Component } from '@angular/core';
import { FabAnimations } from '../style/fab/fab.animations';
import { ChromedriverComponent } from './chromedriver/chromedriver.component';
import { EdgedriverComponent } from './edgedriver/edgedriver.component';
// import { ExecuteComponent } from './execute/execute.component';
// import { RobotComponent } from './robot/robot.component';
// import { PolicymapComponent } from './policymap/policymap.component';
import { ChromedriverService } from '../services/Chromedriver.service';
import { EdgedriverService } from '../services/Edgedriver.service';
import { Router, NavigationEnd, Event  } from '@angular/router';

@Component({
    selector: 'app-config',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss'],
  animations: FabAnimations
})

/** config component*/
export class AdvancedComponent {
  endUrl: string;
  endUrl_kr: string;

  constructor(private router: Router
  , private _chromedriverComp: ChromedriverComponent
  , private _edgedriverComp: EdgedriverComponent) {
    this.router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd ) {
        var url = event.url + '';
        var urlName = url.substring(url.lastIndexOf('/')+1)
        this.endUrl = urlName;
        // console.log(urlName);

        switch(this.endUrl) {
          case "chromedriver" :
            this.endUrl_kr = '　크롬드라이버 관리　';
            document.getElementById("headerSearchBtn").hidden = false;
            break;
          case "edgedriver" :
            this.endUrl_kr = '　엣지드라이버 관리　';
            document.getElementById("headerSearchBtn").hidden = false;
            break;
          default :
            this.endUrl_kr = this.endUrl;
            break;
        }
      }
    });
  }

  btnSearch = { icon: 'search', title: '조회', func: 'search()' }
  btnAdd = { icon: 'playlist_add', title: '추가', func: 'add()' }
  btnSave = { icon: 'save', title: '저장', func: 'save()' }
  btnDelete = { icon: 'delete_forever', title: '삭제', func: 'delete()' }

  buttons = [];
  fabTogglerState = 'inactive';

  showItems() {
    this.fabTogglerState = 'active';

    if(this.endUrl === 'chromedriver') {
      this.buttons.push(this.btnSearch);
      // this.buttons.push(this.btnAdd);
      // this.buttons.push(this.btnSave);
      this.buttons.push(this.btnDelete);
    } 
    else if(this.endUrl === 'edgedriver') {
      this.buttons.push(this.btnSearch);
      // this.buttons.push(this.btnAdd);
      // this.buttons.push(this.btnSave);
      this.buttons.push(this.btnDelete);
    } 
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  uploadDriver() {
    if (this.endUrl == 'chromedriver')
      this._chromedriverComp.uploadChromedriver(); 
    else if (this.endUrl == 'edgedriver')
      this._edgedriverComp.uploadEdgedriver();
  }

  execFunc(name) {
    if(name === 'add()') {
      this.add();
    } else if(name === 'search()') {
      this.search(name);
    } else if(name === 'save()') {
      this.save(name);
    } else if(name === 'delete()') {
      this.delete(name);
    } else {
      
    }
  }

  add() {
    
  }

  search(name) {
    //alert('search : '+name);
    if(this.endUrl === 'chromedriver') {
      this._chromedriverComp.onSearchBoxVisible();
    }
    else if(this.endUrl === 'edgedriver') {
      this._edgedriverComp.onSearchBoxVisible();
    }
  }

  save(name) {
    
  }

  delete(name) {
    if(this.endUrl === 'chromedriver') {
      this._chromedriverComp.deleteValues();
    } else if (this.endUrl === 'edgedriver') {
      this._edgedriverComp.deleteValues();
    }
  }
}
