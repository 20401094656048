import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-child-message-renderer',
  template: `<i class="material-icons" style="heigh: 23px">play_arrow</i>`,
    styleUrls: ['./common.component.css']
})
export class ChildMessageRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private workCls: string;
  private value: any;

  constructor() { 
  }

  agInit(params: any): void {
      this.params = params;
      this.value = params.value;
      console.log("this.value" + this.value);
      this.workCls = "event_available";
  }

  getColor(){
    //console.log("get");
    return  "'red'";
  }  

  public invokeParentMethod() {
    console.log('ChildMessageRenderer row ', this.params.node.rowIndex, 'ChildMessageRenderer Col ', this.params.colDef.headerName);    
      this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`)      

      
  }

  refresh(params: any): boolean {
      this.value = params.value;
      this.workCls = "event_available";
      return true;
  }

  public valueLoaded(): string {
    return this.value;
  }    
}