import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { CommonService } from './common.service';

@Injectable()
export class ChromedriverService implements CommonService {
  myAppUrl: string = "";

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }

  getAllValues() : Observable<HttpResponse<Object[]>> {
    let params = new HttpParams();
    params = null

    return this._http.get<Object[]>(this.myAppUrl + 'api/Chromedriver/Get/all',{
       params, observe: "response"
    });
  }

  uploadChromedriver(param) : Observable<Object[]> {
    let formData = new FormData();
    for(var i = 0; i < param.length; i++) {
      formData.append("fileupload", param[i]);
    }

    const headers = new HttpHeaders({ 'enctype' : 'multipart/form-data' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/Chromedriver/Upload', formData, {
      headers: headers
    });
  }

  downloadChromedriver(param) : void {
    window.location.href = encodeURI(this.myAppUrl + 'api/Chromedriver/Download/' + param);
    //this._http.get<Object[]>(this.myAppUrl + 'api/Chromedriver/Download/' + param);
  }

  linkChromedriver(param) : void {
    // 클립보드 복사를 위해서 temp용 input 생성 -> 클립보드 복사 -> input 삭제
    let input = document.createElement('input');
    document.getElementsByClassName("ag-center-cols-viewport")[0].appendChild(input);
    input.type = 'text';
    input.value = encodeURI(this.myAppUrl + 'api/Chromedriver/Download/' + param);
    input.select();
    document.execCommand('copy');
    input.remove();
  }

  getValuesByParam(param) : Observable<Object[]> {
    return this._http.post<Object[]>(this.myAppUrl + 'api/Chromedriver/Get', param);
  }

  saveValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/Chromedriver/Save', body, {
      headers: headerOptions
    });
  }

  deleteValuesByParams(paramList) {
    const body = JSON.stringify(paramList);
    // console.log(body);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<Object[]>(this.myAppUrl + 'api/Chromedriver/Delete', body, {
      headers: headerOptions
    });
  }

  getSequenceValue() {
    let params = new HttpParams();
    params = null
    
    return this._http.get(this.myAppUrl + 'api/Chromedriver/Get/Seq',{
       responseType: 'text'
    });
  }
}
