import { Component, OnDestroy } from '@angular/core';
import {FormControl} from '@angular/forms';
import {ICellRendererAngularComp} from "ag-grid-angular";
import * as _moment from 'moment';
import { isNullOrUndefined } from 'util';
// tslint:disable-next-line:no-duplicate-imports

const moment = _moment;

@Component({
  selector: 'app-cell-edit-datepicker-renderer',
  template: `
  <mat-form-field class="no-line">
    <input matInput [matDatepicker]="picker" [value]="date.value" (dateChange)="valueChanged($event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
            `,
  styles: []
})
export class CellEditDatepickerRendererComponent implements ICellRendererAngularComp{
  private params: any;
  private changedValue: string;
  public date : any;

  // called on init
  agInit(params: any): void {
      this.params = params;
      // console.log('CellEditDatepickerRendererComponent params ', this.params.value);
      
      if(this.params.value == '' || isNullOrUndefined(this.params.value)) {
        //INSERT 할때 default 설정
        this.date = new FormControl();
      } else {
        //기존 데이터 세팅
        var splitDt = "";
        splitDt = this.params.value.split(" ")[0];
  
        // this.date = new FormControl(moment([+yearData, +monthData, +dayData]));
        this.date = new FormControl(new Date(splitDt));
      }

      // this.changedValue = this.params.value  + "_test";
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
      // this.params = params;
      // console.log('CellEditDatepickerRendererComponent res params ', this.params.value);          
      // this.changedValue = this.params.value  + "_test";
      return true;
  }


  public valueChanged(event): string {
    // console.log("event.value : " + event.value);
    // console.log("this.params.node.id : "                                    + this.params.node.id);
    // console.log("this.params.popUpTarget : " + this.params.popUpTarget);
    this.params.context.componentParent.setDatepickerRowForValue(event.value, this.params.node.id, this.getPopUpTarget(this.params.popUpTarget));
      return "";
      // return this.changedValue;
  }

  getPopUpTarget(target: any): string {
    var result = "";
    switch(target) {
      case "contract":
        result = "crtfdExpDt";
        break;
      case "contract1":
        result = "crtfdStDt";
        break;
      default:
        result = "no";
        break;
    }
    return result;
  }  
}
