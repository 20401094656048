
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfigComponent } from "./config.component";
import { CertificationComponent } from './certification/certification.component';
import { OpensourceComponent } from './opensource/opensource.component';

const ConfigRoutes: Routes = [
    {
    path: 'config',
    component: ConfigComponent,
        children: [
          { path: 'certification', component: CertificationComponent, data: { title: "Config | Certification" } },
          { path: 'opensource', component: OpensourceComponent, data: { title: "Config | Opensource" } },
        ]
    }
];

@NgModule({
    imports: [
      RouterModule.forChild(ConfigRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
    ]
})
export class ConfigRoutingModule { }
